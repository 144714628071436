<template>
  <section
    class="bt"
    id="entity-users-list"
    v-loading="loading"
    :element-loading-text="loadingText"
  >
    <span style="display: none">{{ showError }}</span>
    <div v-if="isShowError" class="error-container">
      {{ showErrorMessage }}
    </div>
    <div v-else>
      <div v-if="!getIsMobile">
        <div class="inner-navbar">
          <el-row>
            <el-col :xl="4" :lg="4" :md="10" :sm="16" :xs="24">
              <div class="icon-block d-flex align-center">
                <div
                  class="icon"
                  v-if="
                    !this.$route.query.routeType &&
                    !this.isApplicationUserSide &&
                    displayBackButton
                  "
                >
                  <el-button class="go-back-button" @click="backToEntities">
                    <i class="fa fa-long-arrow-left"></i>
                  </el-button>
                </div>

                <div class="icon-text pl-1">
                  <h3 class="table-head-text" v-if="!menuTitleLoading">
                    {{ getEntityName() | getMenuTitle }}
                  </h3>
                </div>
              </div>
            </el-col>
            <el-col
              :xl="20"
              :lg="20"
              :md="14"
              :sm="24"
              :xs="24"
              class="column-height"
            >
              <div
                class="right-block float-right"
                v-if="
                  checkPerimission('LAYOUT OPTIONS') &&
                  IframePermissions('LAYOUT OPTIONS', 'ViewEntityData') &&
                  IframePermissions('LAYOUT OPTIONS', 'EntityGalleryView')
                "
              >
                <el-link :underline="false" @click="openViewConfigPage">
                  <i class="fa fa-ellipsis-v fa-fade"></i>
                </el-link>
              </div>
              <div
                class="right-block float-right"
                v-if="
                  getIframeUrlInfo == null &&
                  !isApplicationUserSide &&
                  (activeEntityView == 'TABLE' || activeEntityView == 'CARDS')
                "
              >
                <el-link
                  icon="el-icon-link"
                  style="font-size: 24px; margin-top: 1px"
                  :underline="false"
                  @click="ViewConfigPage"
                ></el-link>
              </div>
              <div
                class="right-block float-right mlr-1"
                v-if="
                  checkPerimission('ADD') &&
                  IframePermissions('ADD', 'ViewEntityData') &&
                  IframePermissions('ADD', 'EntityGalleryView')
                "
              >
                <el-tooltip
                  :content="
                    currentEntity && currentEntity.name
                      ? 'Add new ' + currentEntity.name
                      : 'Add Entity Data'
                  "
                >
                  <button
                    class="btn btn-outline-success btn-sm m-lr-1"
                    type="primary"
                    @click="addEntityData"
                  >
                    &nbsp;<i class="fa fa-plus-circle fa-lg"></i>&nbsp;
                  </button>
                </el-tooltip>
              </div>

              <div
                class="right-block float-right"
                v-if="
                  !this.$route.query.routeType &&
                  (activeEntityView == 'TABLE' ||
                    activeEntityView == 'CARDS') &&
                  !isApplicationUserSide &&
                  IframePermissions('FILTERS', 'ViewEntityData') &&
                  IframePermissions('FILTERS', 'EntityGalleryView')
                "
              >
                <el-dropdown>
                  <span class="el-dropdown-link">
                    <el-badge
                      :value="getCurrentFilters.length"
                      class="item"
                      type="primary"
                    >
                      <el-tooltip content="Add Filters">
                        <button
                          @click="openFilterModel"
                          class="btn btn-sm btn-outline-secondary m-lr-1"
                          type="button"
                        >
                          <i class="fa fa-filter"></i>
                        </button>
                      </el-tooltip>
                    </el-badge>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-for="(filter, index) in getCurrentFilters"
                      :key="index"
                    >
                      <div class="d-flex" style="padding: 7px">
                        <el-tag effect="dark" size="mini" class="mr-1">{{
                          index + 1
                        }}</el-tag>
                        <p style="font-weight: 600">
                          {{ getfilterLabel(filter) }}
                        </p>
                      </div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              &nbsp;&nbsp;
              <div
                class="right-block float-right"
                style="margin-left: 11px"
                v-if="
                  activeEntityView != 'CHARTS' && activeEntityView != 'BOARD'
                "
              >
                <el-input
                  placeholder="Search"
                  size="small"
                  v-model="search_string"
                  @input="searchEntityData"
                  clearable
                  prefix-icon="el-icon-search"
                ></el-input>
              </div>
              &nbsp;&nbsp;
              <div
                class="right-block float-right"
                v-if="
                  !this.$route.query.routeType &&
                  activeEntityView == 'TABLE' &&
                  !isApplicationUserSide &&
                  IframePermissions('FILTERS', 'ViewEntityData') &&
                  IframePermissions('FILTERS', 'EntityGalleryView')
                "
              >
                <el-select
                  v-model="currentFilter"
                  @change="updateCurrentFilter"
                  clearable
                  size="small"
                  placeholder="Select saved filter"
                >
                  <el-option
                    v-for="filter in entityAllFilters"
                    :key="filter._id"
                    :label="filter.filter_name"
                    :value="filter._id"
                    >{{ filter.filter_name }}</el-option
                  >
                </el-select>
              </div>
              &nbsp;&nbsp;
              <div
                class="right-block float-right import-buttton"
                v-if="
                  activeEntityView == 'TABLE' &&
                  checkPerimission('IMPORT') &&
                  IframePermissions('IMPORT', 'ViewEntityData')
                "
              >
                <el-tooltip content="Import" placement="bottom">
                  <button
                    class="btn btn-outline-secondary btn-sm m-lr-1 fa-lg"
                    type="button"
                    @click="displayimportdialog"
                  >
                    <i class="fa fa-cloud-download"></i>
                  </button>
                </el-tooltip>
              </div>
              <div
                class="right-block float-right export-button"
                v-if="
                  activeEntityView == 'TABLE' &&
                  checkPerimission('EXPORT') &&
                  IframePermissions('EXPORT', 'ViewEntityData')
                "
              >
                <el-dropdown placement="top">
                  <button
                    class="btn btn-outline-secondary btn-sm m-lr-1 fa-lg"
                    type="button"
                  >
                    <i class="fa fa-cloud-upload"></i>
                  </button>
                  <el-dropdown-menu>
                    <a @click="exportCSV">
                      <el-dropdown-item>
                        <i class="fa fa-file-excel-o"></i>
                        Export as Excel
                      </el-dropdown-item>
                    </a>
                    <a v-if="pdfExport" @click="exportPDF">
                      <el-dropdown-item>
                        <i class="fa fa-file-pdf-o"></i>
                        Export as PDF
                      </el-dropdown-item>
                    </a>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div
                class="right-block float-right"
                style="margin-bottom: 5px; display: flex"
              >
                <!-- icon="pi pi-spin pi-cog" -->
                <button
                  class="btn btn-outline-secondary btn-sm m-lr-1"
                  type="button"
                  @click="openSettingsModal"
                  v-if="
                    !isApplicationUserSide &&
                    checkPerimission('SETTINGS') &&
                    IframePermissions('SETTINGS', 'ViewEntityData') &&
                    IframePermissions('SETTINGS', 'EntityGalleryView')
                  "
                >
                  <i class="fa fa-cog fa-spin"></i>
                </button>
              </div>
            </el-col>
          </el-row>
          <el-row class="display-actions">
            <div
              v-if="
                IframePermissions('DELETE', 'ViewEntityData') ||
                IframePermissions('BULK UPDATE', 'ViewEntityData') ||
                IframePermissions('SEND NOTIFICATION', 'ViewEntityData')
              "
              class="right-block float-right"
              style="margin-bottom: 5px; display: flex"
            >
              <ul
                class="action-buttons hover-buttons unordered"
                v-if="this.selectedRowsIds.length > 0"
                style="margin-left: 10px"
              >
                <li>
                  <el-dropdown>
                    <button class="btn btn-primary btn-sm m-lr-1" type="button">
                      <i class="fa fa-warning"></i> Bulk Actions
                    </button>
                    <el-dropdown-menu slot="dropdown">
                      <a
                        v-if="
                          !isApplicationUserSide &&
                          IframePermissions('DELETE', 'ViewEntityData')
                        "
                        @click="deleteOncheck"
                      >
                        <el-dropdown-item command="view"
                          ><i class="fa fa-trash-o"></i>Delete</el-dropdown-item
                        >
                      </a>
                      <a
                        v-if="
                          checkPerimission('BULK UPDATE') &&
                          IframePermissions('BULK UPDATE', 'ViewEntityData')
                        "
                        @click="getFieldsForBulkUpdate"
                      >
                        <el-dropdown-item command="view"
                          ><i class="fa fa-pencil"></i>Update</el-dropdown-item
                        >
                      </a>
                      <a
                        v-if="
                          checkPerimission('SEND NOTIFICATION') &&
                          IframePermissions(
                            'SEND NOTIFICATION',
                            'ViewEntityData'
                          )
                        "
                        @click="openBulkSendNotification()"
                      >
                        <el-dropdown-item command="view">
                          <i class="fa fa-envelope-o"></i>Send
                          Notifications</el-dropdown-item
                        >
                      </a>
                    </el-dropdown-menu>
                  </el-dropdown>
                </li>
              </ul>
            </div>
          </el-row>
        </div>
        <div class="quick-filters" v-if="activeEntityView != 'CARDS'">
          <button
            class="btn btn-outline-info btn-sm last-element m-lr-1"
            type="button"
            v-if="
              (entityFiltersData &&
                entityFiltersData.quick_filters &&
                entityFiltersData.quick_filters.length &&
                entityFiltersData.quick_filters.length) ||
              (relationQuickFilters && relationQuickFilters.length)
            "
            @click="openFiltersCollapse"
          >
            <i v-if="!isFilterOpen" class="fa fa-angle-down"></i>
            <i v-else class="fa fa-angle-up"></i>
            {{ !isFilterOpen ? "Show filters" : "Hide filters" }}
          </button>
          <div>
            <QuickEntityFilters
              class="ml-1"
              v-if="showQuickFilters && !quickFilterVisible"
              :filterFields="
                [...entityFiltersData.quick_filters, ...relationQuickFilters] ||
                []
              "
              :allFields="selectedEntityFields"
              :selectedFileds="selectedFileds"
              @quick-filter-updated="quickFilterUpdated"
            ></QuickEntityFilters>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="inner-navbar">
          <el-row>
            <div class="icon-block d-flex align-center">
              <div
                class="icon"
                v-if="
                  !this.$route.query.routeType && !this.isApplicationUserSide
                "
              >
                <el-button class="go-back-button" @click="backToEntities">
                  <i class="fa fa-long-arrow-left"></i>
                </el-button>
              </div>

              <div class="icon-text pl-1">
                <h3 class="table-head-text" v-if="!menuTitleLoading">
                  {{ getEntityName() | getMenuTitle }}
                </h3>
              </div>
            </div>
            <!-- <div class="right-block float-right">
                  <el-link
                    v-if="isApplicationUserSide || checkPerimission('LAYOUT')"
                    :underline="false"
                    @click="openViewConfigPage"
                  >
                    <i class="fa fa-ellipsis-v fa-fade"></i>
                  </el-link>
                </div> -->
            <div
              class="right-block float-right"
              v-if="
                getIframeUrlInfo == null &&
                !isApplicationUserSide &&
                (activeEntityView == 'TABLE' || activeEntityView == 'CARDS')
              "
            >
              <el-tooltip content="Create Iframe link" placement="top">
                <el-link
                  icon="el-icon-link"
                  style="font-size: 24px; margin-top: 1px"
                  :underline="false"
                  @click="ViewConfigPage"
                ></el-link>
              </el-tooltip>
            </div>
            <div class="buttons-list">
              <div
                class="right-block float-right mlr-1"
                v-if="
                  checkPerimission('ADD') &&
                  IframePermissions('ADD', 'ViewEntityData') &&
                  IframePermissions('ADD', 'EntityGalleryView')
                "
              >
                <el-tooltip
                  :content="
                    currentEntity && currentEntity.name
                      ? 'Add new ' + currentEntity.name
                      : 'Add Entity Data'
                  "
                >
                  <button
                    class="btn btn-outline-success btn-sm m-lr-1"
                    type="button"
                    @click="addEntityData"
                  >
                    &nbsp;<i class="fa fa-plus-circle fa-lg"></i>&nbsp;
                  </button>
                </el-tooltip>
              </div>
              <div
                class="right-block float-right"
                v-if="
                  !this.$route.query.routeType &&
                  (activeEntityView == 'TABLE' ||
                    activeEntityView == 'CARDS') &&
                  !isApplicationUserSide
                "
              >
                <el-dropdown>
                  <span class="el-dropdown-link">
                    <el-badge
                      :value="getCurrentFilters.length"
                      class="item"
                      type="primary"
                    >
                      <el-tooltip content="Add Filters">
                        <button
                          @click="openFilterModel"
                          class="btn btn-sm btn-outline-secondary m-lr-1"
                          type="button"
                        >
                          <i class="fa fa-filter"></i>
                        </button>
                      </el-tooltip>
                    </el-badge>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-for="(filter, index) in getCurrentFilters"
                      :key="index"
                    >
                      <div class="d-flex" style="padding: 7px">
                        <el-tag effect="dark" size="mini" class="mr-1">{{
                          index + 1
                        }}</el-tag>
                        <p style="font-weight: 600">
                          {{ getfilterLabel(filter) }}
                        </p>
                      </div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div
                class="right-block float-right import-buttton"
                v-if="activeEntityView == 'TABLE' && checkPerimission('IMPORT')"
              >
                <el-tooltip content="Import" placement="bottom">
                  <button
                    v-if="checkUrlType() == 'SignIn'"
                    class="btn btn-outline-secondary btn-sm m-lr-1 fa-lg"
                    type="button"
                    @click="displayimportdialog"
                  >
                    <i class="fa fa-cloud-download"></i>
                  </button>
                </el-tooltip>
              </div>
              <div
                class="right-block float-right export-button"
                v-if="activeEntityView == 'TABLE' && checkPerimission('EXPORT')"
              >
                <el-dropdown placement="top">
                  <button
                    v-if="checkUrlType() == 'SignIn'"
                    class="btn btn-outline-secondary btn-sm m-lr-1 fa-lg"
                    type="button"
                  >
                    <i class="fa fa-cloud-upload"></i>
                  </button>
                  <el-dropdown-menu>
                    <a @click="exportCSV">
                      <el-dropdown-item>
                        <i class="fa fa-file-excel-o"></i>
                        Export as Excel
                      </el-dropdown-item>
                    </a>
                    <a v-if="pdfExport" @click="exportPDF">
                      <el-dropdown-item>
                        <i class="fa fa-file-pdf-o"></i>
                        Export as PDF
                      </el-dropdown-item>
                    </a>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div
                class="right-block float-right"
                style="margin-bottom: 5px; display: flex"
              >
                <!-- icon="pi pi-spin pi-cog" -->
                <button
                  class="btn btn-outline-secondary btn-sm m-lr-1"
                  type="button"
                  @click="openSettingsModal"
                  v-if="!isApplicationUserSide && checkPerimission('SETTINGS')"
                >
                  <i class="fa fa-cog fa-spin"></i>
                </button>
              </div>
            </div>
            <div
              v-if="activeEntityView != 'CHARTS' && activeEntityView != 'BOARD'"
            >
              <el-input
                placeholder="Search"
                size="small"
                v-model="search_string"
                @input="searchEntityData"
                clearable
                prefix-icon="el-icon-search"
              ></el-input>
            </div>
            &nbsp;
            <div
              v-if="
                !this.$route.query.routeType &&
                activeEntityView == 'TABLE' &&
                !isApplicationUserSide
              "
            >
              <el-select
                style="width: 100%"
                v-model="currentFilter"
                @change="updateCurrentFilter"
                clearable
                size="small"
                placeholder="Select saved filter"
              >
                <el-option
                  v-for="filter in entityAllFilters"
                  :key="filter._id"
                  :label="filter.filter_name"
                  :value="filter._id"
                  >{{ filter.filter_name }}</el-option
                >
              </el-select>
            </div>
          </el-row>
          <el-row class="display-actions">
            <div
              class="right-block float-right"
              style="margin-bottom: 5px; display: flex"
            >
              <ul
                class="action-buttons hover-buttons unordered"
                v-if="
                  this.selectedRowsIds.length > 0 && checkUrlType() == 'SignIn'
                "
                style="margin-left: 10px"
              >
                <li>
                  <el-dropdown>
                    <button class="btn btn-primary btn-sm m-lr-1" type="button">
                      <i class="fa fa-warning"></i> Bulk Actions
                    </button>
                    <el-dropdown-menu slot="dropdown">
                      <a v-if="!isApplicationUserSide" @click="deleteOncheck">
                        <el-dropdown-item command="view"
                          ><i class="fa fa-trash-o"></i>Delete</el-dropdown-item
                        >
                      </a>
                      <a
                        v-if="checkPerimission('BULK UPDATE')"
                        @click="getFieldsForBulkUpdate"
                      >
                        <el-dropdown-item command="view"
                          ><i class="fa fa-pencil"></i>Update</el-dropdown-item
                        >
                      </a>
                      <a
                        v-if="
                          checkPerimission('SEND NOTIFICATION') &&
                          IframePermissions(
                            'SEND NOTIFICATION',
                            'ViewEntityData'
                          )
                        "
                        @click="openBulkSendNotification()"
                      >
                        <el-dropdown-item command="view">
                          <i class="fa fa-envelope-o"></i>Send
                          Notifications</el-dropdown-item
                        >
                      </a>
                    </el-dropdown-menu>
                  </el-dropdown>
                </li>
              </ul>
            </div>
          </el-row>
        </div>
        <div class="quick-filters">
          <button
            class="btn btn-outline-info btn-sm last-element m-lr-1"
            type="button"
            v-if="
              (entityFiltersData &&
                entityFiltersData.quick_filters &&
                entityFiltersData.quick_filters.length) ||
              (relationQuickFilters && relationQuickFilters.length)
            "
            @click="openFiltersCollapse"
          >
            <i v-if="!isFilterOpen" class="fa fa-angle-down"></i>
            <i v-else class="fa fa-angle-up"></i>
            {{ !isFilterOpen ? "Show filters" : "Hide filters" }}
          </button>
          <div>
            <QuickEntityFilters
              class="ml-1"
              v-if="showQuickFilters && !quickFilterVisible"
              :filterFields="
                [...entityFiltersData.quick_filters, ...relationQuickFilters] ||
                []
              "
              :allFields="selectedEntityFields"
              :selectedFileds="selectedFileds"
              @quick-filter-updated="quickFilterUpdated"
            ></QuickEntityFilters>
          </div>
        </div>
      </div>
      <div class="main-table-holder" v-if="activeEntityView == 'TABLE'">
        <div>
          <dialog-component
            :title="'View' + ' ' + 'Fields'"
            :visible="dialogTableVisible"
            @before-close="resetDialogModal"
            :containerWidth="'900px'"
          >
            <div v-loading="modalLoading" class="add-contact-type-popup">
              Select filter to add columns
              <el-select
                v-model="currentFilter"
                clearable
                style="width: 100%"
                class="mb-1"
                @change="updateSelectedColumn"
              >
                <el-option
                  v-for="filter in entityAllFilters"
                  :key="filter._id"
                  :label="filter.filter_name"
                  :value="filter._id"
                  >{{ filter.filter_name }}</el-option
                >
              </el-select>
              <div
                v-if="getCurrentFilter && getCurrentFilter.tableFieldGroupBy"
              >
                <el-checkbox-group v-model="filterColumns" :min="1">
                  <ol class="v_list">
                    <li><el-checkbox label="count">Count</el-checkbox></li>

                    <li>
                      <el-checkbox
                        v-for="(field, index) of getTotalFields"
                        :label="field.key"
                        :key="field.key + 'field_count' + index"
                        >{{
                          `${field.template_name} - ${field.label} `
                        }}</el-checkbox
                      >
                    </li>

                    <li style="margin-top: 10px">
                      <el-checkbox
                        label="sub_group/count"
                        v-if="
                          getCurrentFilter &&
                          getCurrentFilter.tableFieldSubGroupBy
                        "
                        >Sub group Count</el-checkbox
                      >
                    </li>

                    <li>
                      <template
                        v-if="
                          getCurrentFilter &&
                          getCurrentFilter.tableFieldSubGroupBy
                        "
                      >
                        <el-checkbox
                          v-for="(field, index) of getTotalFields"
                          :label="'sub_group/' + field.key"
                          :key="field.key + index + 'fieldcheck'"
                          >Sub group
                          {{
                            `${field.template_name} - ${field.label} `
                          }}</el-checkbox
                        >
                      </template>
                    </li>
                  </ol>
                </el-checkbox-group>
                <!-- <div
                  v-if="
                    this.getField(getCurrentFilter.tableFieldGroupBy) &&
                    this.getField(getCurrentFilter.tableFieldGroupBy)
                      .input_type == 'ENTITY' &&
                    !getCurrentFilter.tableFieldSubGroupBy
                  "
                >
                  Include{{
                    this.getField(this.getCurrentFilter.tableFieldGroupBy).label
                  }}
                  variables:
                  <el-select
                    placeholder="Select variable"
                    v-model="filterVariableColumns"
                    multiple
                    allow-create
                  >
                    <el-option
                      v-for="(op, i) in allOtherEntityFields"
                      :key="op.key + '_' + i"
                      :value="op.template_id + '#' + op.key"
                      :label="op.label"
                    >
                    </el-option>
                  </el-select>
                </div> -->
              </div>
              <div v-else>
                <el-input
                  placeholder="Search Field"
                  v-model="filterText"
                ></el-input>
                <div class="tree-container">
                  <el-tree
                    class="filter-tree"
                    :data="fieldsListData"
                    :props="props"
                    default-expand-all
                    show-checkbox
                    highlight-current
                    :filter-node-method="filterNode"
                    ref="tree"
                    node-key="id"
                    :default-checked-keys="selectedTableFields"
                    @check-change="checkedNodes"
                    v-if="!refresh"
                  ></el-tree>
                </div>
                <el-collapse>
                  <el-collapse-item>
                    <template slot="title"
                      >Drag fields to change the column order</template
                    >
                    <div>
                      <el-scrollbar class="elementsScroll">
                        <table
                          class="table table-striped"
                          style="z-index: 1000; border: 10px; margin: 5px 0px"
                        >
                          <tr>
                            <draggable v-model="treeCheckedNodes" tag="tr">
                              <th
                                v-for="(column, index) in treeCheckedNodes"
                                :key="index"
                              >
                                <el-tag type="info">{{ column.label }}</el-tag>
                              </th>
                            </draggable>
                          </tr>
                        </table>
                      </el-scrollbar>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </div>
            <span slot="footer" class="dialog-footer">
              <span v-if="modalLoading">
                <button
                  class="btn btn-outline-primary btn-sm m-lr-1"
                  type="button"
                >
                  <span class="spinner-border spinner-border-sm"></span>
                  Loading..
                </button>
              </span>
              <span v-else>
                <button
                  class="btn btn-outline-primary btn-sm m-lr-1"
                  type="button"
                  @click="updateFilterColumn"
                  v-if="currentFilter"
                >
                  <i class="fa fa-rocket fa-fade"></i> &nbsp; Save & apply
                </button>
                <button
                  class="btn btn-outline-primary btn-sm m-lr-1"
                  type="button"
                  @click="update"
                  v-else
                >
                  <i class="fa fa-rocket fa-fade"></i> &nbsp; Apply
                </button>
              </span>
            </span>
          </dialog-component>
        </div>

        <div class="vue-data-table-default">
          <div v-if="entityFiltersData && entityFiltersData.tableFieldGroupBy">
            <el-table
              :data="groupedData"
              @header-dragend="headerDragged"
              :total="total"
              :resizable="true"
              border
              :current-page.sync="currentPage"
              :page-size="pageSize"
              :pagination-props="paginationProps"
              :page-sizes="paginationProps"
              @query-change="loadData"
              class="sidemenu"
              @sort-change="sortChange"
              style="overflow-wrap: normal"
              size="mini"
              v-if="!refresh"
            >
              <el-table-column
                type="selection"
                width="55"
                v-if="
                  checkPerimission('BULK UPDATE') ||
                  checkPerimission('BULK DELETE')
                "
              >
              </el-table-column>
              <el-table-column
                min-width="200"
                sortable="custom"
                prop="_id"
                :label="getMainLabel"
              >
                <template slot-scope="scope">
                  <div v-if="scope.row['entity_label']">
                    {{ scope.row["entity_label"] }}
                  </div>
                  <div v-else>
                    {{ scope.row["_id"] }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                min-width="250"
                v-for="(
                  step, index
                ) of entityFiltersData.tableFieldGroupByFields.filter(
                  (e) => !e.sub_group
                )"
                :label="step.name"
                :key="step.key + index"
                sortable="custom"
                :prop="step.key"
              >
                <template slot-scope="scope">
                  <div>
                    <el-link
                      :underline="false"
                      type="primary"
                      @click="openCountDialog(scope.row, null)"
                      >{{
                        getCurrencyFormat(scope.row[step.key], step.key)
                      }}</el-link
                    >
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                v-for="(step, index) of custom_columns"
                :key="index + '_' + step.value"
                :label="step.label"
                min-width="250"
                :prop="step.value"
              >
                <template slot-scope="scope">
                  <div>
                    <el-link
                      :underline="false"
                      type="primary"
                      @click="openCountDialog(scope.row, step.value)"
                      >{{ getSubField(scope.row, step.value) }}</el-link
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              v-if="!refreshPage"
              :class="'custom-pagination'"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-sizes="paginationProps"
              :page-size="pageSize"
              :pager-count="5"
              layout="prev, pager, next, jumper,sizes, total"
              :total="total"
              small
            ></el-pagination>
          </div>
          <div v-else>
            <el-table
              :data="filteredData"
              :total="total"
              :resizable="true"
              @header-dragend="headerDragged"
              @cell-dblclick="cellDoubleClicked"
              :current-page.sync="currentPage"
              :page-size="pageSize"
              :pagination-props="paginationProps"
              :page-sizes="paginationProps"
              @query-change="loadData"
              @sort-change="sortChange"
              :default-sort="getSort()"
              @selection-change="handleSelectionChange"
              style="width: 100%"
              class="sidemenu"
              border
              size="mini"
              v-if="!refresh"
            >
              <el-table-column
                type="selection"
                width="55"
                v-if="
                  checkPerimission('BULK UPDATE') ||
                  checkPerimission('BULK DELETE')
                "
              >
              </el-table-column>
              <el-table-column
                class="tableView"
                v-for="step of getFilterColumList"
                :label="step.label"
                :key="step.template_id + '-' + step.id + '-' + step.label"
                :prop="getPropValue(step)"
                :width="step.width ? step.width : 250"
                sortable="custom"
              >
                <template slot-scope="scope">
                  <div :style="getFieldStyle(step, scope.row)">
                    <div v-if="step && isSystemColumn(step)">
                      <div>
                        <span
                          class="black-coral sm-100"
                          v-if="scope.row.updated_at && step.id == 'updated_at'"
                          >{{
                            scope.row.updated_at | globalDateTimeFormat
                          }}</span
                        >
                        <span
                          class="black-coral sm-100"
                          v-if="scope.row.created_at && step.id == 'created_at'"
                          >{{
                            scope.row.created_at | globalDateTimeFormat
                          }}</span
                        >
                        <span
                          class="black-coral sm-100"
                          v-if="
                            scope.row.created_by &&
                            step.id == 'created_by' &&
                            entityUserInfoData?.[scope.row.created_by]
                          "
                        >
                          {{
                            entityUserInfoData?.[scope.row.created_by]
                              .first_name +
                            " " +
                            entityUserInfoData?.[scope.row.created_by].last_name
                          }}
                        </span>
                        <span
                          class="black-coral sm-100"
                          v-if="
                            scope.row.updated_by &&
                            step.id == 'updated_by' &&
                            entityUserInfoData?.[scope.row.updated_by]
                          "
                        >
                          {{
                            entityUserInfoData?.[scope.row.updated_by]
                              .first_name +
                            " " +
                            entityUserInfoData?.[scope.row.updated_by].last_name
                          }}
                        </span>

                        <!-- profile picture with name in entity data table -->
                        <div
                          v-if="
                            currentEntity.entity_type == 'INDIVIDUAL' &&
                            step.id == 'profile_picture_with_name'
                          "
                        >
                          <div class="icon-container">
                            <div class="icon-block initial">
                              <div
                                v-if="
                                  scope.row &&
                                  scope.row.contact_id &&
                                  getProfilePicture(scope.row)
                                "
                              >
                                <img
                                  :src="getProfilePicture(scope.row)"
                                  alt="IMG"
                                  width="35"
                                  height="35"
                                  style="border-radius: 50%; margin-top: 2px"
                                />
                              </div>
                              <div class="icon" v-else>
                                <div class="initial-text">
                                  <span
                                    v-if="
                                      scope.row &&
                                      getStandardTemplateInfo(scope.row)
                                    "
                                  >
                                    {{
                                      getInitials(
                                        getStandardTemplateInfo(scope.row)
                                      )
                                    }}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="pl-2">
                              <span v-if="scope.row">
                                {{
                                  getStandardTemplateInfo(scope.row).replace(
                                    ",",
                                    " "
                                  )
                                }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="d-flex"
                      v-else-if="step && step.type == 'ENTITY'"
                    >
                      <div
                        v-if="
                          checkIsEntityCount(step.template_id + '#' + step.key)
                        "
                      >
                        <el-link
                          type="primary"
                          @click="
                            openDetailedCount(
                              step.template_id + '#' + step.key,

                              scope.row
                            )
                          "
                        >
                          {{ getFieldValue(scope.row, step) }}</el-link
                        >
                      </div>
                      <div
                        v-else-if="
                          step &&
                          step.type == 'ENTITY' &&
                          getFieldValue(scope.row, step) &&
                          typeof getFieldValue(scope.row, step) == 'string'
                        "
                      >
                        <div
                          v-if="
                            scope.$index == quickUpdateIndex &&
                            checkPermissions(step)
                          "
                        >
                          <EntityExecute
                            :data="getFieldForQuickUpdate(step)"
                            :form="quickUpdateRowData[step.template_id]"
                            :is-view="false"
                            :hasLabel="false"
                            :entityDataExists="true"
                            :fieldsData="[]"
                          >
                          </EntityExecute>
                        </div>
                        <div v-else>
                          <el-link
                            type="primary"
                            :underline="false"
                            @click="
                              checkApplicationUserPermission(scope.row, step)
                            "
                          >
                            <p style="font-size: 13px">
                              {{ getFieldValue(scope.row, step) }}
                            </p>
                          </el-link>
                        </div>
                      </div>
                      <div v-else>
                        <p style="font-size: 13px">
                          {{ getFieldValue(scope.row, step) }}
                        </p>
                      </div>
                    </div>
                    <div v-else-if="step && step.type == 'ACTION_BUTTON'">
                      <ActionButtonExecute
                        :data="{
                          ...getFieldForQuickUpdate(step),
                          ...{
                            action_button_type: 'HYPERLINK',
                            parent_entityId: entity_id,
                            parent_entityDataId: scope.row._id,
                          },
                        }"
                        :form="scope.row.entityData[step.template_id]"
                        :is-view="false"
                        :hide-styles="true"
                      >
                      </ActionButtonExecute>
                    </div>
                    <div v-else-if="step && step.type == 'FILE'">
                      <div
                        v-if="
                          getFieldValue(scope.row, step) &&
                          getFieldValue(scope.row, step).length &&
                          getFieldValue(scope.row, step).length <= 1
                        "
                      >
                        <el-button
                          type="text"
                          style="margin-top: -14px"
                          @click="
                            downloadFile(getFieldValue(scope.row, step)[0])
                          "
                          class="mb-1"
                        >
                          <p style="font-size: 13px">
                            {{
                              getFieldValue(scope.row, step)[0].name
                                | truncate(20, "...")
                            }}
                            <i class="el-icon-download"></i>
                          </p>
                        </el-button>
                      </div>
                      <div
                        class="d-flex"
                        v-else-if="
                          getFieldValue(scope.row, step) &&
                          getFieldValue(scope.row, step).length &&
                          getFieldValue(scope.row, step).length >= 1
                        "
                      >
                        <p
                          v-for="(file, index) of getFieldValue(
                            scope.row,
                            step
                          )"
                          :key="index"
                        >
                          <el-button
                            type="text"
                            style="margin-top: -10px"
                            v-if="file && file.name && index < limit"
                            @click="
                              downloadFile(getFieldValue(scope.row, step)[0])
                            "
                          >
                            {{ file.name | truncate(10, "...") }}
                            <i class="el-icon-download"></i>
                          </el-button>
                        </p>
                        <el-tag
                          style="cursor: pointer; width: 40px"
                          class="ml-1"
                          :value="getFieldValue(scope.row, step).length"
                          v-if="getFieldValue(scope.row, step).length > 1"
                          @click="downloadDocumentsPopup(scope.row, step)"
                        >
                          +{{ getFieldValue(scope.row, step).length - 1 }}
                        </el-tag>
                      </div>
                    </div>

                    <div
                      class="d-flex"
                      v-else-if="step && step.type === 'IMAGE'"
                    >
                      <img
                        v-if="getFieldValue(scope.row, step)"
                        :src="getFieldValue(scope.row, step)"
                        alt="IMG"
                        width="80"
                        height="60"
                      />
                    </div>
                    <div class="d-flex" v-else-if="step && step.type === 'QR'">
                      <img
                        v-if="getFieldValue(scope.row, step)"
                        :src="getFieldValue(scope.row, step)"
                        alt="IMG"
                        width="80"
                        height="80"
                      />
                    </div>
                    <div
                      class="d-flex"
                      v-else-if="step && step.type === 'QUESTION'"
                      style="word-break: break-word"
                    >
                      {{ getFieldValue(scope.row, step) }}
                    </div>
                    <div class="d-flex" v-else>
                      <div
                        v-if="
                          scope.row.entityData &&
                          scope.row.entityData[step.template_id] &&
                          scope.row.entityData[step.template_id][step.id] &&
                          scope.row.entityData[step.template_id][
                            `${step.id}_code`
                          ] &&
                          scope.row.entityData[step.template_id][
                            `${step.id}_code`
                          ].formatInternational &&
                          scope.row.entityData[step.template_id][
                            `${step.id}_code`
                          ].countryCallingCode
                        "
                      >
                        <p style="font-size: 13px">
                          {{
                            "+" +
                            scope.row.entityData[step.template_id][
                              `${step.id}_code`
                            ].countryCallingCode +
                            " " +
                            scope.row.entityData[step.template_id][step.id]
                          }}
                        </p>
                      </div>

                      <div v-else-if="step && step.type == 'CURRENCY'">
                        <p
                          style="font-size: 13px"
                          v-if="
                            (getFieldValue(scope.row, step) != null &&
                              getFieldValue(scope.row, step) != undefined) ||
                            typeof getFieldValue(scope.row, step) == 'number'
                          "
                        >
                          {{
                            Intl.NumberFormat(getCurrencyFormet(step).code, {
                              style: "currency",
                              currency: getCurrencyFormet(step).currency,
                            }).format(getFieldValue(scope.row, step))
                          }}
                        </p>
                      </div>
                      <div v-else-if="step && step.type == 'FORMULA'">
                        <p
                          style="font-size: 13px"
                          v-if="
                            (getFieldValue(scope.row, step) ||
                              typeof getFieldValue(scope.row, step) ==
                                'number') &&
                            step.currency_code &&
                            step.currency_code.currency
                          "
                        >
                          {{
                            Intl.NumberFormat(getCurrencyFormet(step).code, {
                              style: "currency",
                              currency: getCurrencyFormet(step).currency,
                            }).format(getFieldValue(scope.row, step))
                          }}
                        </p>
                        <p v-else-if="isDate(getFieldValue(scope.row, step))">
                          {{
                            getFieldValue(scope.row, step) | globalDateFormat
                          }}
                        </p>
                        <p v-else>
                          {{
                            getCurrencyFormat(
                              getFieldValue(scope.row, step),
                              step.template_id + "#" + step.id
                            )
                          }}
                        </p>
                      </div>
                      <div v-else-if="step && step.type == 'DATE'">
                        <div
                          v-if="
                            scope.$index == quickUpdateIndex &&
                            checkPermissions(step)
                          "
                        >
                          <el-date-picker
                            prefix-icon="el-icon-date"
                            v-model="
                              quickUpdateRowData[step.template_id][step.id]
                            "
                            type="date"
                            :format="
                              getDefaultDateFormat &&
                              getDefaultDateFormat.includes('Do') == false
                                ? getDefaultDateFormat
                                    .replace('YYYY', 'yyyy')
                                    .replace('DD', 'dd')
                                : 'MM-dd-yyyy'
                            "
                            :disabled="
                              quickUpdateFieldDisabled(
                                step.template_id,
                                step.id
                              )
                            "
                            :style="getStyleWidth(step)"
                          >
                          </el-date-picker>
                        </div>
                        <div v-else-if="step.date_view_type">
                          {{
                            getFieldValue(scope.row, step)
                              | moment(
                                step.date_view_type == "year"
                                  ? "YYYY"
                                  : "MMMM YYYY"
                              )
                          }}
                        </div>
                        <div v-else>
                          <p style="font-size: 13px">
                            {{
                              getFieldValue(scope.row, step) | globalDateFormat
                            }}
                          </p>
                        </div>
                      </div>
                      <div v-else-if="step && step.type == 'DATE_TIME'">
                        <div
                          v-if="
                            scope.$index == quickUpdateIndex &&
                            checkPermissions(step)
                          "
                        >
                          <el-date-picker
                            prefix-icon="el-icon-date"
                            v-model="
                              quickUpdateRowData[step.template_id][step.id]
                            "
                            type="datetime"
                            :format="'MM-dd-yyyy HH:mm:ss'"
                            placeholder="Select Date & Time"
                            :disabled="
                              quickUpdateFieldDisabled(
                                step.template_id,
                                step.id
                              )
                            "
                            :style="getStyleWidth(step)"
                          >
                          </el-date-picker>
                        </div>
                        <div v-else>
                          <p style="font-size: 13px">
                            {{ formatDate(getFieldValue(scope.row, step)) }}
                          </p>
                        </div>
                      </div>

                      <div
                        v-else-if="
                          step &&
                          step.type == 'DATE_TIME_RANGE' &&
                          scope.row.entityData[step.template_id] &&
                          scope.row.entityData[step.template_id][step.id]
                        "
                      >
                        <p style="font-size: 13px">
                          {{ getFieldValue(scope.row, step) | toDateTimeRange }}
                          <!-- | toDateTimeRange -->
                        </p>
                      </div>
                      <div v-else-if="step && step.type == 'TIME_RANGE'">
                        <p style="font-size: 13px">
                          {{ getFieldValue(scope.row, step) | toTimeRange }}
                        </p>
                      </div>
                      <div v-else-if="step && step.type == 'LOCATION'">
                        <el-link
                          :underline="false"
                          type="primary"
                          style="font-size: 13px"
                          @click="showMap(getFieldValue(scope.row, step))"
                        >
                          {{ getFieldValue(scope.row, step) }}
                        </el-link>
                      </div>
                      <div v-else-if="step && step.type == 'WEEKDAYS'">
                        <p style="font-size: 13px; word-break: normal">
                          {{ getFieldValue(scope.row, step) | toWeekDays }}
                        </p>
                      </div>
                      <div
                        v-else-if="
                          step &&
                          step.type == 'DATE_RANGE' &&
                          scope.row.entityData[step.template_id] &&
                          scope.row.entityData[step.template_id][step.id]
                        "
                      >
                        <p style="font-size: 13px">
                          <!-- {{getFieldValue(scope.row, step)}}<br /> -->
                          {{ getFieldValue(scope.row, step) | toDateRange }}
                          <!-- | toDateRange -->
                        </p>
                      </div>
                      <div v-else-if="step && step.type == 'TIME'">
                        <p style="font-size: 13px">
                          {{
                            getFieldValue(scope.row, step) | moment("HH:mm:ss")
                          }}
                        </p>
                      </div>
                      <div
                        v-else-if="
                          step &&
                          step.type == 'YES_OR_NO' &&
                          getFieldValue(scope.row, step)
                        "
                      >
                        <p style="font-size: 13px">
                          {{ getFieldValue(scope.row, step) }}
                        </p>
                      </div>
                      <div
                        v-else-if="
                          step &&
                          step.type == 'HTML_CONTENT' &&
                          getFieldValue(scope.row, step)
                        "
                      >
                        {{ getFieldValue(scope.row, step) }}
                      </div>
                      <div v-else-if="step && step.type == 'SIGNATURE'">
                        <img
                          v-if="getFieldValue(scope.row, step)"
                          :src="getFieldValue(scope.row, step)"
                          alt="IMG"
                          width="100"
                          height="60"
                        />
                        <p v-else>-</p>
                      </div>

                      <div v-else-if="step && step.type == 'MULTI_LINE_TEXT'">
                        <span
                          v-if="
                            step &&
                            step.type == 'MULTI_LINE_TEXT' &&
                            step.currency_code &&
                            step.currency_code.data_type
                          "
                        >
                          <span v-if="getFieldValue(scope.row, step)">
                            <span
                              v-if="getFieldValue(scope.row, step).length > 100"
                            >
                              {{ truncateText(getFieldValue(scope.row, step)) }}
                              <a
                                href="#"
                                @click.prevent="
                                  openMultiLineData(
                                    getFieldValue(scope.row, step),
                                    step.label
                                  )
                                "
                                style="color: #409eff"
                                >Read more</a
                              >
                            </span>
                            <span v-else v-html="getFieldValue(scope.row, step)">
                            </span>
                          </span>
                        </span>
                        <span v-else>
                          <span v-if="getFieldValue(scope.row, step)">
                            <span
                              v-if="getFieldValue(scope.row, step).length > 100"
                            >
                              {{
                                getFieldValue(scope.row, step)
                                  | truncateHTML(100)
                              }}
                              <a
                                href="#"
                                @click.prevent="
                                  openMultiLineData(
                                    getFieldValue(scope.row, step),
                                    step.label,
                                    step
                                  )
                                "
                                style="color: #409eff"
                                >Read more</a
                              >
                            </span>
                            <span v-else v-html="getFieldValue(scope.row, step)">
                            </span>
                          </span>
                        </span>
                      </div>
                      <div
                        class="d-flex"
                        v-else-if="step && step.type == 'NUMBER'"
                      >
                        <p
                          style="font-size: 13px"
                          v-if="
                            getFieldValue(scope.row, step) ||
                            typeof getFieldValue(scope.row, step) == 'number'
                          "
                        >
                          <!-- {{
                            scope.row.entityData[step.template_id]
                              ? typeof scope.row.entityData[step.template_id][
                                  step.id
                                ] == "number"
                                ? scope.row.entityData[step.template_id][
                                    step.id
                                  ]
                                : !isNaN(scope.row.entityData[step.template_id][step.id]) 
                                ? parseInt(scope.row.entityData[step.template_id][step.id])
                                : ""
                              : ""
                          }} -->
                          {{ getFieldValue(scope.row, step) }}
                        </p>
                      </div>
                      <div
                        v-else-if="step && step.type == 'AGGREGATE_FUNCTION'"
                      >
                        <p style="font-size: 13px">
                          {{
                            getCurrencyFormat(
                              getFieldValue(scope.row, step),
                              step.template_id + "#" + step.id
                            )
                          }}
                        </p>
                      </div>
                      <div v-else-if="step && step.type == 'MULTI_SELECT'">
                        <div
                          v-if="
                            scope.$index == quickUpdateIndex &&
                            checkPermissions(step)
                          "
                        >
                          <el-select
                            v-model="
                              quickUpdateRowData[step.template_id][step.id]
                            "
                            multiple
                            collapse-tags
                            :disabled="
                              quickUpdateFieldDisabled(
                                step.template_id,
                                step.id
                              )
                            "
                            :style="getStyleWidth(step)"
                          >
                            <el-option
                              v-for="(option, index) of getFieldOptions(
                                step.template_id,
                                step.id
                              )"
                              :label="option"
                              :value="option"
                              :key="index"
                            >
                            </el-option>
                          </el-select>
                        </div>
                        <div v-else>
                          <p style="font-size: 13px">
                            {{ getFieldValue(scope.row, step) | toMultiSelect }}
                          </p>
                        </div>
                      </div>
                      <div v-else-if="step && step.type == 'SINGLE_LINE_TEXT'">
                        <p v-if="data.input_type == 'MASKED'">xxxx</p>
                        <p
                          v-else-if="
                            step &&
                            step.currency_code &&
                            step.currency_code.type == 'HYPER_LINK'
                          "
                        >
                          <el-link
                            @click="checkNavigationAction(scope.row, step)"
                            style="color: rgb(69 188 237)"
                            >{{ getFieldValue(scope.row, step) }}</el-link
                          >
                        </p>
                        <div v-else style="font-size: 13px">
                          <div
                            v-if="
                              scope.$index == quickUpdateIndex &&
                              checkPermissions(step)
                            "
                          >
                            <el-input
                              v-model="
                                quickUpdateRowData[step.template_id][step.id]
                              "
                              :disabled="
                                quickUpdateFieldDisabled(
                                  step.template_id,
                                  step.id
                                )
                              "
                              :style="getStyleWidth(step)"
                            ></el-input>
                          </div>
                          <div v-else>
                            <p>{{ getFieldValue(scope.row, step) }}</p>
                          </div>
                        </div>
                      </div>
                      <div v-else-if="step && step.type == 'AUDIO'">
                        <p>
                          <el-link
                            @click="checkNavigationAction(scope.row, step)"
                            >{{ getFieldValue(scope.row, step) }}</el-link
                          >
                        </p>
                      </div>
                      <div v-else-if="step && step.type == 'CAPTCHA'">
                        <p>{{ getFieldValue(scope.row, step) }}</p>
                      </div>
                      <div v-else-if="step && step.type == 'SELECT'">
                        <div
                          v-if="
                            scope.$index == quickUpdateIndex &&
                            checkPermissions(step)
                          "
                        >
                          <el-select
                            v-model="
                              quickUpdateRowData[step.template_id][step.id]
                            "
                            :disabled="
                              quickUpdateFieldDisabled(
                                step.template_id,
                                step.id
                              )
                            "
                            :style="getStyleWidth(step)"
                          >
                            <el-option
                              v-for="(option, index) of getFieldOptions(
                                step.template_id,
                                step.id
                              )"
                              :label="option"
                              :value="option"
                              :key="index"
                            >
                            </el-option>
                          </el-select>
                        </div>
                        <div v-else>
                          <p>{{ getFieldValue(scope.row, step) }}</p>
                        </div>
                      </div>
                      <div v-else-if="step && step.type == 'CHECKBOX'">
                        <div
                          v-if="
                            scope.$index == quickUpdateIndex &&
                            checkPermissions(step)
                          "
                        >
                          <el-checkbox
                            v-model="
                              quickUpdateRowData[step.template_id][step.id]
                            "
                            :disabled="
                              quickUpdateFieldDisabled(
                                step.template_id,
                                step.id
                              )
                            "
                          ></el-checkbox>
                        </div>
                        <div v-else>
                          {{ getFieldValue(scope.row, step) }}
                        </div>
                      </div>
                      <div v-else-if="step && step.type == 'DATA_TABLE'">
                        <a href="#" @click.prevent="dataTableOpen(scope.row,step)">
                        {{ getNumberOfDataTableFields(scope.row, step) }}
                        </a>
                      </div>
                      <div v-else>
                        <p style="font-size: 13px">
                          {{ getFieldValue(scope.row, step) }}
                        </p>
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                prop="action"
                label="Actions"
                class="action"
                min-width="175"
                fixed="right"
              >
                <template slot-scope="scope">
                  <ul class="action-buttons">
                    <li
                      v-if="
                        checkPerimission('VIEW') &&
                        IframePermissions('VIEW', 'ViewEntityData') &&
                        scope.$index !== quickUpdateIndex
                      "
                    >
                      <template
                        v-if="
                          (customization &&
                            customizationButtons &&
                            customizationButtons.length) ||
                          (customization != null &&
                            customization.actions &&
                            customization.actions.length &&
                            getIframeUrlInfo == null)
                        "
                      >
                        <button
                          v-for="(
                            action, index
                          ) in filteredCustomizationActions"
                          :key="index"
                          class="btn btn-light btn-sm m-lr-1"
                          @click="onAction(action, scope.row)"
                        >
                          <i
                            :class="quickActionIcons[action.type]"
                            :title="action.label"
                          ></i>
                        </button>
                        <button
                          class="btn btn-light btn-sm m-lr-1"
                          type="button"
                          v-for="button in getButtonsList(customizationButtons)"
                          :key="button"
                        >
                          <span
                            v-if="button === 'Edit'"
                            class="text-muted"
                            title="Edit"
                            @click="editWorkUserData(scope.row)"
                            ><i class="fa-solid fa-pen"></i
                          ></span>
                          <span
                            v-else-if="button === 'View'"
                            class="text-muted"
                            title="View"
                            @click="viewWorkUserData(scope.row)"
                            ><i class="fa fa-eye"></i
                          ></span>
                          <span
                            v-else-if="button === 'Delete'"
                            class="text-muted"
                            title="Delete"
                            @click="onEntityDataDelete(scope.row)"
                            ><i class="fa-solid fa-trash"></i
                          ></span>
                          <span
                            v-else-if="button === 'Send Notification'"
                            class="text-muted"
                            title="Send Notification"
                            @click="openSendNotification(scope.row)"
                            ><i class="fa-solid fa-share"></i
                          ></span>
                        </button>
                      </template>
                      <template v-else>
                        <button
                          class="btn btn-light btn-sm m-lr-1"
                          type="button"
                          @click="viewWorkUserData(scope.row)"
                        >
                          <span class="text-muted" title="View">
                            <i class="fa fa-eye"></i>
                          </span>
                        </button>
                      </template>
                    </li>
                    <li v-if="scope.$index == quickUpdateIndex">
                      <el-button
                        plain
                        type="danger"
                        size="mini"
                        class="px-2 ml-2"
                        @click="quickUpdateIndex = -1"
                        title="Cancel"
                      >
                        <i class="el-icon-close"></i>
                      </el-button>
                    </li>
                    <li v-if="scope.$index == quickUpdateIndex">
                      <el-button
                        plain
                        type="success"
                        size="mini"
                        class="px-2 ml-2"
                        @click="updateRow(scope.row)"
                        title="Save"
                      >
                        <i class="el-icon-check"></i>
                      </el-button>
                    </li>
                    <li
                      v-if="
                        ((checkPerimission('EDIT') &&
                          currentEntity &&
                          currentEntity.entity_type != 'INDIVIDUAL') ||
                          checkPerimission('EDIT') ||
                          checkPerimission('SEND NOTIFICATION') ||
                          checkPermission('VIEW') ||
                          checkPerimission('DELETE')) &&
                        (IframePermissions('EDIT', 'ViewEntityData') ||
                          IframePermissions(
                            'SEND NOTIFICATION',
                            'ViewEntityData'
                          ) ||
                          IframePermissions('DELETE', 'ViewEntityData')) &&
                        scope.$index !== quickUpdateIndex
                      "
                    >
                      <el-dropdown>
                        <span class="el-dropdown-link">
                          <i
                            class="el-icon-more"
                            style="transform: rotate(90deg)"
                          ></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <a
                            :underline="false"
                            @click="duplicateEntityData(scope.row)"
                            v-if="
                              checkPerimission('EDIT') &&
                              currentEntity &&
                              currentEntity.entity_type != 'INDIVIDUAL'
                            "
                          >
                            <el-dropdown-item
                              icon="el-icon-document-copy"
                              v-if="checkButtonVisible('Duplicate')"
                              >Duplicate</el-dropdown-item
                            >
                          </a>
                          <a
                            :underline="false"
                            @click="editWorkUserData(scope.row)"
                            v-if="
                              checkPerimission('EDIT') &&
                              IframePermissions('EDIT', 'ViewEntityData') &&
                              checkButtonVisible('Edit') &&
                              !(
                                customizationButtons &&
                                customizationButtons.some(
                                  (button) => button === 'Edit'
                                )
                              )
                            "
                          >
                            <el-dropdown-item icon="el-icon-edit"
                              >Edit</el-dropdown-item
                            >
                          </a>
                          <a
                            :underline="false"
                            @click="viewWorkUserData(scope.row)"
                            v-if="
                              checkPerimission('VIEW') &&
                              customization != null &&
                              checkButtonVisible('View') &&
                              !(
                                customizationButtons &&
                                customizationButtons.some(
                                  (button) => button === 'View'
                                )
                              )
                            "
                          >
                            <el-dropdown-item icon="el-icon-view"
                              >View</el-dropdown-item
                            >
                          </a>
                          <a
                            :underline="false"
                            @click="openSendNotification(scope.row)"
                            v-if="
                              checkPerimission('SEND NOTIFICATION') &&
                              checkButtonVisible('Send Notification') &&
                              IframePermissions(
                                'SEND NOTIFICATION',
                                'ViewEntityData'
                              ) &&
                              !(
                                customizationButtons &&
                                customizationButtons.some(
                                  (button) => button === 'Send Notification'
                                )
                              )
                            "
                          >
                            <el-dropdown-item icon="el-icon-s-promotion"
                              >Send Notification</el-dropdown-item
                            >
                          </a>
                          <a
                            @click="onEntityDataDelete(scope.row)"
                            :underline="false"
                            v-if="
                              checkPerimission('DELETE') &&
                              IframePermissions('DELETE', 'ViewEntityData') &&
                              checkButtonVisible('Delete') &&
                              !(
                                customizationButtons &&
                                customizationButtons.some(
                                  (button) => button === 'Delete'
                                )
                              )
                            "
                          >
                            <el-dropdown-item icon="el-icon-delete"
                              >Delete</el-dropdown-item
                            >
                          </a>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </li>
                  </ul>
                </template>
              </el-table-column>

              <!--<el-table-column label="Updated on" min-width="150">
                <template slot-scope="scope">
                  <div>
                    <span
                      class="black-coral sm-100"
                      v-if="scope.row.updated_at"
                      >{{ scope.row.updated_at | globalDateTimeFormat }}</span
                    >
                  </div>
                </template>
              </el-table-column> -->
            </el-table>
            <el-pagination
              v-if="!refreshPage"
              :class="'custom-pagination'"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-sizes="paginationProps"
              :page-size="pageSize"
              :pager-count="5"
              layout="prev, pager, next, jumper,sizes, total"
              :total="total"
              small
            ></el-pagination>
          </div>
        </div>
      </div>
      <div v-else-if="activeEntityView == 'CARDS' && !loading" class="mt-1">
        <entity-gallery-view
          :search_string="search_string"
          :showFieldsParent="showFieldsParent"
          :isApplicationUserSide="isApplicationUserSide"
          :currentMenu="currentMenu"
          :getSelfEntityFilters="getSelfEntityFilters"
          :applyFiltersData="entityFiltersData"
        >
        </entity-gallery-view>
      </div>
      <div v-else-if="activeEntityView == 'CHARTS' && !loading" class="mt-1">
        <entity-chart-view
          :showFields="showFieldsParent"
          :isApplicationUserSide="isApplicationUserSide"
          :currentMenu="currentMenu"
          :getSelfEntityFilters="getSelfEntityFilters"
        >
        </entity-chart-view>
      </div>
      <div v-else-if="activeEntityView == 'BOARD' && !loading" class="mt-1">
        <entity-board-view
          :showFields="showFieldsParent"
          :isApplicationUserSide="isApplicationUserSide"
          :currentMenu="currentMenu"
          :getSelfEntityFilters="getSelfEntityFilters"
        >
        </entity-board-view>
      </div>
      <add-entity-reationship-data-widget
        v-if="showAddRelationshipDataDialog"
        :relationshipDetails="selectedRelationshipDetails"
        :existedData="selectedRelationshipExistedIds"
        :autoPopup="true"
        v-on:entityDataClosed="showAddRelationshipDataDialog = false"
      ></add-entity-reationship-data-widget>
      <div>
        <dialog-component
          :title="'Filters'"
          :visible="dialogEdit"
          :containerWidth="'70%'"
          @before-close="resetFiltersData"
        >
          <div>
            <EntityFilter
              v-if="dialogEdit"
              :currentEntity="currentEntity"
              :entityFiltersData="entityFiltersData"
              :selectedEntityFields="selectedEntityFields"
              v-on:delete="confimDelete"
            ></EntityFilter>
          </div>

          <span slot="footer" class="dialog-footer">
            <button
              class="btn btn btn-outline-danger btn-sm m-lr-1"
              type="button"
              @click="resetFiltersData"
            >
              <i class="fa fa-close"></i> Cancel
            </button>
            <button
              class="btn btn btn-outline-success btn-sm m-lr-1"
              type="button"
              @click="applyFilter"
              :disabled="checkFilterDisabled"
            >
              <i class="fa fa-check-square-o"></i> {{ getFilterButtonLabel }}
            </button>
          </span>
        </dialog-component>
        <dialog-component
          :title="'Import &amp; Map Fields'"
          :visible="matchDialogVisible"
          @before-close="closeDialog"
          :containerMaxWidth="'100vw'"
          :containerMinWidth="'80vw'"
          :containerMaxHeight="'100vh'"
        >
          <div class="mt-15">
            <el-steps :active="is_active" finish-status="success">
              <el-step
                title="Upload file"
                @click.native="stepNavigation(0)"
              ></el-step>
              <el-step
                title="Map Fields"
                @click.native="stepNavigation(1)"
              ></el-step>
              <el-step
                title="Review &amp; import"
                @click.native="stepNavigation(2)"
              ></el-step>
            </el-steps>
          </div>
          <hr />
          <div
            style="text-align: center"
            v-if="this.is_active == 0"
            v-loading="dialogLoading"
          >
            <div
              class="d-flex flex-row mb-1 blank-excel-container"
              v-if="is_excelUploaded === false"
            >
              <div class="pt-3 pl-1">
                <p class="download-excel-content">
                  A sample Excel format has been created for you.Please download
                  the file and enter your data.
                </p>
              </div>
              <div class="p-1">
                <button
                  @click="downloadExcelFile"
                  class="btn btn-sm btn-outline-primary m-lr-1"
                  type="button"
                >
                  <i class="fa fa-file-excel-o fa-fade">
                    &nbsp;Download blank Excel
                  </i>
                </button>
              </div>
            </div>
            <div
              id="uploadFileContainer"
              v-if="this.is_excelUploaded === false"
            >
              <el-upload
                class="upload-file-el-upload"
                drag
                name="logo"
                :on-change="uploadCSVfile"
                action
                :show-file-list="false"
                :auto-upload="false"
                accept=".xlsx"
              >
                <div class="upload-file">
                  <div class="icon py-2">
                    <img
                      src="@/assets/img/icons/upload-file.svg"
                      alt="Upload File"
                      height="40"
                      width="100"
                    />
                  </div>
                  <p class="text-dark fs-9 mb-0">
                    Drag &amp; Drop Excel file here
                  </p>
                  <p class="fs-9 mb-0" style="color: #babdc2">OR</p>
                  <button
                    class="match_upload_button mt-0"
                    style="padding: 6px 10px !important"
                  >
                    Import Excel
                  </button>
                </div>
              </el-upload>
            </div>
            <div v-if="this.is_excelUploaded === true" class="mb-5">
              <img src="@/assets/img/mapped_file_uploaded_success.svg" />
              <h1>File Uploaded Successfully</h1>
            </div>
            <div class="d-flex flex-row" style="margin-left: 150px">
              <el-input
                :disabled="existedField.length > 0"
                placeholder="Enter the name for file"
                v-model="mappedFileName"
                style="width: 250px"
                @change="checkClearFileOrNot"
              >
              </el-input>
              <p class="p-2">OR</p>
              <el-select
                v-model="existedField"
                placeholder="Select"
                :disabled="mappedFileName.length > 0"
                @change="checkClearFileOrNot"
                clearable
              >
                <el-option
                  v-for="item in uploadedfileDataNames"
                  :key="item._id"
                  :label="item.name"
                  :value="item._id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="error" style="text-align: left; margin-left: 225px">
              <p v-if="isExistedName === true">The name is already existed</p>
            </div>
          </div>
          <div
            style="text-align: center"
            v-if="this.is_active == 1"
            v-loading="dialogLoading"
          >
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="Mapped Fields" name="first">
                <span slot="label"
                  >Mapped Fields
                  <span style="color: #071aed"
                    >({{ this.mapped_fields.length }})</span
                  ></span
                >
                <el-scrollbar
                  wrap-style="max-height: 60vh;"
                  ref="configureScroll"
                >
                  <el-table
                    :data="mapped_fields"
                    style="width: 100%; overflow-x: auto"
                    border
                    size="mini"
                  >
                    <el-table-column
                      prop="excel_field"
                      label="Fields in File"
                      width="180"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.excel_field }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="template_id"
                      label="Esigns Form Templates"
                    >
                      <template slot-scope="scope">
                        <el-select
                          v-model="scope.row.template_id"
                          placeholder="Select"
                          clearable
                        >
                          <el-option
                            v-for="(temp, index) in entityTempsWithException"
                            :key="
                              temp.key +
                              scope.row.excel_field +
                              index +
                              temp._id
                            "
                            :label="temp.name"
                            :value="temp._id"
                          >
                          </el-option>
                        </el-select>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="template_filed_id"
                      label="Esigns Fields"
                    >
                      <template slot-scope="scope">
                        <el-select
                          v-model="scope.row.template_filed_id"
                          placeholder="Select"
                          clearable
                        >
                          <el-option
                            v-for="(field, index) in getSelectedTemplate(
                              scope.row.template_id
                            )"
                            :key="field.key + index + field._id"
                            :disabled="checkIsFieldSelected(field)"
                            :label="field.label"
                            :value="field.key"
                          >
                          </el-option>
                        </el-select>
                      </template>
                    </el-table-column>
                    <el-table-column prop="ignore" label="Ignore">
                      <template slot-scope="scope">
                        <el-checkbox
                          v-model="scope.row.ignore"
                          @change="updateIgnoredRow(scope.row)"
                        ></el-checkbox>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-scrollbar>
              </el-tab-pane>
              <el-tab-pane label="Unmapped Fields" name="second">
                <span slot="label"
                  >Unmapped Fields
                  <span style="color: #071aed"
                    >({{ this.unmapped_fields.length }})</span
                  ></span
                >
                <el-scrollbar
                  wrap-style="max-height: 60vh;"
                  ref="configureScroll"
                >
                  <el-table
                    :data="unmapped_fields"
                    style="width: 100%"
                    id="unmappedTable"
                    border
                    size="mini"
                  >
                    <el-table-column
                      prop="excel_field"
                      label="Fields in File"
                      width="180"
                    >
                      <template slot-scope="scope">
                        <p>{{ scope.row.excel_field }}</p>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="template_id"
                      label="Esigns Form Templates"
                    >
                      <template slot-scope="scope">
                        <el-select
                          v-model="scope.row.template_id"
                          placeholder="Select"
                          clearable
                        >
                          <el-option
                            v-for="(temp, index) in entityTempsWithException"
                            :key="
                              temp.key +
                              scope.row.excel_field +
                              index +
                              temp._id
                            "
                            :label="temp.name"
                            :value="temp._id"
                          >
                          </el-option>
                        </el-select>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="template_filed_id"
                      label="Esigns Fields"
                      id="templateFilledId"
                    >
                      <template slot-scope="scope">
                        <el-select
                          v-model="scope.row.template_filed_id"
                          @change="updateField(scope.row)"
                          placeholder="Select"
                          clearable
                        >
                          <el-option
                            v-for="(field, index) in getSelectedTemplate(
                              scope.row.template_id
                            )"
                            :key="field.key + index + field._id"
                            :label="field.label"
                            :value="field.key"
                            :disabled="checkIsFieldSelected(field)"
                          >
                          </el-option>
                        </el-select>
                      </template>
                    </el-table-column>
                    <el-table-column prop="ignore" label="Ignore">
                      <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.ignore"></el-checkbox>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-scrollbar>
              </el-tab-pane>
            </el-tabs>
          </div>
          <div v-if="this.is_active == 2" v-loading="dialogLoading">
            <SuccessAndErrorsTable
              v-if="preparedData && preparedData.length"
              :templatesData="preparedData"
              :currentEntity="currentEntity"
              :foundArray="foundArray"
              :successedExcelImportData="successedExcelImportData"
            >
            </SuccessAndErrorsTable>
            <el-scrollbar wrap-style="max-height: 60vh;" ref="configureScroll">
            </el-scrollbar>
          </div>
          <hr />
          <span slot="footer" class="dialog-footer" v-if="this.is_active == 0">
            <button
              class="btn btn btn-outline-danger btn-sm m-lr-1"
              type="button"
              @click="matchDialogVisible = false"
            >
              <i class="fa fa-close"></i> Cancel</button
            >&nbsp;&nbsp;

            <button
              class="btn btn btn-outline-success btn-sm m-lr-1"
              type="button"
              @click="prepareImportEntityHeaders"
              id="prepareImportEntityNext"
            >
              <i class="fa fa-sign-out"></i> Next
            </button>
          </span>
          <span slot="footer" class="dialog-footer" v-if="this.is_active == 1">
            <button
              class="btn btn btn-outline-danger btn-sm m-lr-1"
              type="button"
              @click="matchDialogVisible = false"
            >
              <i class="fa fa-close"></i> Cancel</button
            >&nbsp;&nbsp;

            <button
              class="btn btn btn-outline-success btn-sm m-lr-1"
              type="button"
              @click="gotoDataPreviewScreen"
              id="gotoDataPreviewScreenNext"
            >
              <i class="fa fa-sign-out"></i> Next
            </button>
          </span>
          <span slot="footer" class="dialog-footer" v-if="this.is_active == 2">
            <button
              class="btn btn-outline-secondary btn-sm m-lr-1"
              type="button"
            >
              <i class="fa fa-edit"></i>Edit Fields
            </button>
            &nbsp;&nbsp;
            <button
              class="btn btn-outline-success btn-sm m-lr-1"
              type="button"
              @click="importMappedData"
            >
              <i class="fa fa-upload"></i> Finish Import
            </button>
          </span>
        </dialog-component>
        <dialog-component
          :title="'Warning'"
          :visible="unmapped_dialog"
          @before-close="unMappedDialog"
          :containerWidth="'30%'"
        >
          <div>
            <span
              >There are
              <span style="color: #071aed">{{
                this.unmapped_fields.length
              }}</span>
              Unmapped fields. Do you want to ignore and continue?</span
            >
            <span slot="footer" class="dialog-footer">
              <button
                class="btn btn btn-outline-danger btn-sm m-lr-1"
                type="button"
                @click="unmapped_dialog = false"
              >
                <i class="fa fa-close"></i> Cancel</button
              >&nbsp;&nbsp;

              <button
                class="btn btn btn-outline-success btn-sm m-lr-1"
                type="button"
                @click="displayUnmappedDialog"
              >
                <i class="fa fa-sign-out"></i> Continue
              </button>
            </span>
          </div>
        </dialog-component>

        <dialog-component
          :title="'Warning'"
          :visible="entitySignleRecordVisible"
          :containerWidth="'27%'"
          :containerHeight="'180px'"
          @before-close="entitySignleRecordVisible = false"
        >
          <div class="mt-20">
            <span> Are you sure you want to delete the data? </span>
          </div>
          <span slot="footer" class="dialog-footer">
            <button
              class="btn btn-outline-danger btn-sm m-lr-1"
              type="button"
              @click="entitybulkDeleteVisible = false"
            >
              <i class="fa fa-close"></i> Cancel
            </button>
            &nbsp;&nbsp;
            <button
              class="btn btn btn-outline-success btn-sm m-lr-1"
              type="button"
              @click="deleteTemplate(entityData)"
            >
              <i class="fa fa-sign-out"></i> Confirm
            </button>
          </span>
        </dialog-component>

        <el-dialog
          :visible.sync="mappingLoadingDialogVisible"
          width="35%"
          :show-close="false"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          center
        >
          <div class="text-center">
            <img
              src="@/assets/img/importDataLoading.gif"
              class="import-loading-gif"
            />
            <p>Please wait</p>
            <h1>Your data is in progress... <i class="fa fa-spinner"></i></h1>
          </div>
        </el-dialog>
        <dialog-component
          :title="'Success'"
          :visible="mappingStatusDialogVisible"
          :containerWidth="'40%'"
          @before-close="gotoDashboard"
          :isShowFooter="false"
          center
        >
          <div class="text-center popUp-content">
            <img
              src="@/assets/img/mappingDone.svg"
              class="import-loading-gif"
            />
            <h1 class="mb-0.5">The import process has been initiated.</h1>
            <div class="mb-1">
              <span>
                We are currently in the process of importing your Excel file
                data, Sit back and relax.
              </span>
              <span
                >Once the import is complete, we will notify you through
                email.</span
              >
            </div>
            <button
              class="btn btn-outline-primary mt-2 m-lr-1 mb-2"
              type="button"
              @click="gotoDashboard"
            >
              <i class="fa fa-check-circle-o"></i> Done
            </button>
          </div>
        </dialog-component>
        <dialog-component
          :title="'Warning'"
          :visible="entitybulkDeleteVisible"
          :containerWidth="getIsMobile ? '100%' : '30%'"
          :containerHeight="'180px'"
          @before-close="entitybulkDeleteVisibleDialog"
        >
          <div class="mt-4">
            Are you sure you want to delete the selected
            <b>{{ count }}</b> data?
          </div>
          <span slot="footer" class="dialog-footer">
            <button
              class="btn btn-outline-danger btn-sm m-lr-1"
              type="button"
              @click="entitybulkDeleteVisible = false"
            >
              <i class="fa fa-close"></i> Cancel
            </button>
            &nbsp;&nbsp;
            <button
              class="btn btn btn-outline-success btn-sm m-lr-1"
              type="button"
              @click="deleteCheckedEntityData"
            >
              <i class="fa fa-sign-out"></i> Confirm
            </button>
          </span>
        </dialog-component>
      </div>

      <complete-entity-data-popup
        :entityId="selectedChildEntityId"
        :entityDataId="selectedChildEntityDataId"
        :entityRefName="selectedChildEntityRefName"
        :primaryDataValue="selectedChildEntityPrimaryDataValue"
        :isMultiple="selectedChildEntityDataIsMultple"
        :isTitleVisible="true"
        v-if="showChildEntityDataDialog"
        @close="closeChildEntityDataDialog"
      ></complete-entity-data-popup>
      <el-drawer
        title="Layout Options"
        :visible.sync="entityViewModal"
        :direction="'rtl'"
        class="layout-drawer"
        :size="getIsMobile ? '50%' : '20%'"
        :before-close="checkForFilterUpdate"
      >
        <div class="layout-container">
          <div class="layout-selection">
            <div
              v-for="layout in display_views"
              :key="layout.key"
              :class="
                activeEntityView == layout.key
                  ? 'single-layout-active'
                  : 'single-layout'
              "
              @click="activeLayout(layout.key)"
            >
              <img
                v-if="activeEntityView == layout.key"
                :src="
                  require(`@/assets/img/icons/entityViews/active/${layout.key}.svg`)
                "
              />
              <img
                v-else
                :src="
                  require(`@/assets/img/icons/entityViews/${layout.key}.svg`)
                "
              />
              <p>
                {{ layout.label }}
              </p>
            </div>
          </div>
          <div class="mt-1 layout-settings">
            <div v-if="activeEntityView == 'TABLE'">
              <h3 class="layout-head">Field Settings</h3>
              <el-select
                v-model="currentFilter"
                @change="updateCurrentFilter"
                clearable
                placeholder="Select saved filter"
                style="width: 100%"
                :disabled="
                  currentFilter &&
                  isApplicationUserSide &&
                  this.$route.query &&
                  this.$route.query.filter
                    ? true
                    : false
                "
              >
                <el-option
                  v-for="filter in entityAllFilters"
                  :key="filter._id"
                  :label="filter.filter_name"
                  :value="filter._id"
                  >{{ filter.filter_name }}</el-option
                >
              </el-select>
              <el-input
                placeholder="Find field"
                class="mt-1"
                v-model="search_field"
                clearable
              >
              </el-input>
              <el-scrollbar wrap-style="padding: 10px; max-height: 400px;">
                <div v-if="search_field">
                  <el-collapse
                    accordion
                    v-if="getSearchData && getSearchData.length"
                    v-model="activeTemplate"
                  >
                    <el-collapse-item
                      v-for="temp in getSearchData"
                      :key="temp.template_id"
                      name="1"
                    >
                      <template slot="title">
                        <div style="width: 100%">
                          {{ temp.templateInfo.name | truncate(22, "...") }}
                          <el-link
                            :underline="false"
                            class="action-links"
                            type="primary"
                            @click="selectAlltemplates(temp)"
                            >Show all
                          </el-link>
                        </div>
                      </template>
                      <div
                        v-if="
                          temp &&
                          temp.templateInfo &&
                          temp.templateInfo.sections &&
                          temp.templateInfo.sections[0].fields
                        "
                      >
                        <div
                          class="field-selection"
                          v-for="field in temp.templateInfo.sections[0].fields.filter(
                            (e) => contentFields.indexOf(e.input_type) === -1
                          )"
                          :key="field.key"
                          @click="selectField(field, temp.template_id)"
                        >
                          {{ field.label }}
                          <div class="float-right">
                            <img
                              v-if="checkfieldVisible(field, temp.template_id)"
                              src="@/assets/img/icons/entityViews/EYE.svg"
                            />
                            <img
                              v-else
                              src="@/assets/img/icons/entityViews/HIDE.svg"
                            />
                          </div>
                        </div>
                      </div>
                    </el-collapse-item>
                  </el-collapse>
                  <span v-else> No field found </span>
                </div>
                <el-collapse accordion v-else v-model="activeTemplate">
                  <template v-if="currentEntity && currentEntity.templates">
                    <el-collapse-item
                      v-for="temp in (currentEntity.templates || []).filter(
                        (e) => !e.is_repeatable
                      )"
                      :key="temp.template_id"
                      name="1"
                    >
                      <template slot="title">
                        <div style="width: 100%">
                          {{ temp.templateInfo.name | truncate(22, "...") }}
                          <el-link
                            :underline="false"
                            class="action-links"
                            type="primary"
                            @click="selectAlltemplates(temp)"
                            >Show all
                          </el-link>
                        </div>
                      </template>
                      <div
                        v-if="
                          temp &&
                          temp.templateInfo &&
                          temp.templateInfo.sections &&
                          temp.templateInfo.sections[0].fields
                        "
                      >
                        <div
                          class="field-selection"
                          v-for="field in temp.templateInfo.sections[0].fields.filter(
                            (e) => contentFields.indexOf(e.input_type) === -1
                          )"
                          :key="field.key"
                          @click="selectField(field, temp.template_id)"
                        >
                          {{ field.label }}
                          <div class="float-right">
                            <img
                              v-if="checkfieldVisible(field, temp.template_id)"
                              src="@/assets/img/icons/entityViews/EYE.svg"
                            />
                            <img
                              v-else
                              src="@/assets/img/icons/entityViews/HIDE.svg"
                            />
                          </div>
                        </div>
                      </div>
                    </el-collapse-item>
                  </template>
                </el-collapse>
              </el-scrollbar>
            </div>
            <div v-else-if="activeEntityView == 'CARDS'">
              <h4 class="layout-head">Display fields</h4>
              <div>
                Profile
                <el-select
                  v-model="showFieldsParent.profile"
                  clearable
                  placeholder="Select cover profile"
                  style="width: 100%"
                >
                  <el-option
                    v-for="(field, i) in getEntityFieldsByType(['IMAGE'])"
                    :key="field.key + 'image' + i"
                    :label="field.label"
                    :value="field.key"
                    >{{ field.label }}</el-option
                  >
                </el-select>
              </div>
              <div class="mt-1">
                Title
                <el-select
                  v-model="showFieldsParent.title"
                  clearable
                  placeholder="Select cover profile"
                  style="width: 100%"
                >
                  <el-option
                    v-for="(field, i) in getEntityFieldsByType()"
                    :key="field._id + 'title' + i"
                    :label="field.label"
                    :value="field.key"
                    >{{ field.label }}</el-option
                  >
                </el-select>
              </div>
              <div class="mt-1">
                Description
                <el-select
                  v-model="showFieldsParent.description"
                  clearable
                  placeholder="Select description field"
                  style="width: 100%"
                >
                  <el-option
                    v-for="(field, i) in getEntityFieldsByType([
                      'MULTI_LINE_TEXT',
                      'SINGLE_LINE_TEXT',
                    ])"
                    :key="field.key + 'des' + i"
                    :label="field.label"
                    :value="field.key"
                    >{{ field.label }}</el-option
                  >
                </el-select>
              </div>
              <div class="mt-1">
                Action button
                <el-select
                  v-model="showFieldsParent.action_group_button"
                  multiple
                  clearable
                  collapse-tags
                  placeholder="Select action button"
                  style="width: 100%"
                >
                  <el-option-group
                    v-for="group in groupedOptions"
                    :key="group.label"
                    :label="group.label"
                  >
                    <el-option
                      v-for="item in group.options"
                      :key="item.value + 'action'"
                      :label="item.label"
                      :value="item.value"
                    >
                      {{ item.label }}
                    </el-option>
                  </el-option-group>
                </el-select>
              </div>
              <div class="mt-1">
                Select Filters
                <el-select
                  style="width: 100%"
                  v-model="showFieldsParent.selectedFilter"
                  clearable
                  size="small"
                  placeholder="Select saved filter"
                  multiple
                  collapse-tags
                >
                  <el-option
                    v-for="filter in entityAllFilters"
                    :key="filter._id"
                    :label="filter.filter_name"
                    :value="filter._id"
                    >{{ filter.filter_name }}</el-option
                  >
                </el-select>
              </div>
            </div>
            <div v-else-if="activeEntityView == 'BOARD'">
              <h4 class="layout-head">Display fields</h4>
              <div>
                Board field
                <el-select
                  v-model="showFieldsParent.board_field"
                  clearable
                  placeholder="Select board field"
                  style="width: 100%"
                >
                  <el-option
                    v-for="(field, i) in getEntityFieldsByType(['SELECT'])"
                    :key="field.key + 'chart' + i"
                    :label="field.label"
                    :value="field.key"
                    >{{ field.label }}</el-option
                  >
                </el-select>
              </div>
            </div>
            <div v-else-if="activeEntityView == 'CHARTS'">
              <h4 class="layout-head">Display fields</h4>
              <div>
                Chart field
                <el-select
                  v-model="showFieldsParent.chart_field"
                  clearable
                  placeholder="Select chart field"
                  style="width: 100%"
                >
                  <el-option
                    v-for="(field, i) in getEntityFieldsByType([
                      'SELECT',
                      'YES_OR_NO',
                      'MULTI_SELECT',
                      'ENTITY',
                    ])"
                    :key="field.key + 'chart' + i"
                    :label="field.label"
                    :value="field.key"
                    >{{ field.label }}</el-option
                  >
                </el-select>
              </div>
              <div>
                Group by
                <el-select
                  v-model="showFieldsParent.groupBy_chart_field"
                  clearable
                  placeholder="Select chart field"
                  style="width: 100%"
                >
                  <el-option
                    v-for="(field, i) in getEntityFieldsByType([
                      'SELECT',
                      'YES_OR_NO',
                      'MULTI_SELECT',
                      'ENTITY',
                    ]).filter((e) => e.key != showFieldsParent.chart_field)"
                    :key="field.key + 'chart_group_by' + i"
                    :label="field.label"
                    :value="field.key"
                    >{{ field.label }}</el-option
                  >
                </el-select>
              </div>
              <div class="mt-1">
                Chart type
                <el-select
                  v-model="showFieldsParent.chart_type"
                  clearable
                  placeholder="Select chart field"
                  style="width: 100%"
                >
                  <el-option
                    v-for="(field, index) of chartTypes"
                    :value="field.value"
                    :key="field.value + 'chart_type' + index"
                    :label="field.label"
                  >
                    <span>{{ field.label }}</span>
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
      </el-drawer>
      <!-- entity data notifications dialog -->
    </div>
    <el-dialog
      :visible.sync="showCountDialog"
      title="Data"
      :width="getIsMobile ? '100%' : '90%'"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <CustomComponentTable
        :component="countComponent"
        :entity_id="this.currentEntity._id"
        :newFilters="countComponentFilters"
        :customFilters="[]"
        :hide_options="true"
        :hide_expend="true"
        v-if="showCountDialog"
      ></CustomComponentTable>
    </el-dialog>
    <dialog-component
      :visible="showDetailedCount"
      :title="showDetailsFieldLabel"
      @before-close="closeDetailedCount"
      :containerMinHeight="'50vh'"
      :containerWidth="'50w'"
      :isShowFooter="false"
      class="custom-dialog"
    >
      <div>
        <CustomComponentTable
          v-if="showDetailedCount"
          :component="selectedComponent"
          :entity_id="countDetailsEntityId"
          :customFilers="appliedFilters"
          :hide_options="true"
          :hide_export="true"
          :hide_expend="true"
          :hideAllActions="true"
          :externalLimit="10"
          :externalPage="1"
          :parent_entityId="countDetailsParentEntityId"
          :parent_entityDataId="countDetailsParentEntityDataId"
        ></CustomComponentTable>
      </div>
    </dialog-component>
    <dialog-component
      :visible="bulkUpdateDialgVisible"
      :title="'Bulk Update'"
      @before-close="closeBulkUpdateDialogue"
      :containerWidth="'35%'"
      :containerHeight="'30%'"
    >
      <div class="d-flex1">
        <div class="mt-20 mb-2">
          <label for="fields" style="margin-bottom: 5px; display: block"
            >Fields</label
          >
          <el-select
            id="fields"
            v-model="bulkUpdateField"
            @change="fetchValuesForSelectField"
            clearable
          >
            <el-option-group
              v-for="group in bulkUpdateFields"
              :key="group.label"
              :label="group.label"
            >
              <el-option
                v-for="field in group.options"
                :key="field.key"
                :label="field.label"
                :value="
                  field._id +
                  '#' +
                  field.input_type +
                  '#' +
                  field.template_id +
                  '#' +
                  field.key
                "
              >
              </el-option>
            </el-option-group>
          </el-select>
        </div>

        <div>
          <label
            for="values"
            style="margin-bottom: 5px; display: block"
            class="mt-20"
            >Values</label
          >
          <el-select
            id="values"
            v-model="bulkUpdateValue"
            v-loading="optionsLoading"
            clearable
            :multiple="
              bulkUpdateField.split('#')[1] == 'MULTI_SELECT' ? true : false
            "
            collapse-tags
          >
            <el-option
              v-for="option in bulkUpdateOptions"
              :key="option.label"
              :label="option.label"
              :value="option.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <button
          class="btn btn btn-outline-danger btn-sm m-lr-1"
          type="button"
          @click="closeBulkUpdateDialogue"
        >
          <i class="fa fa-close"></i> Cancel</button
        >&nbsp;&nbsp;
        <button
          class="btn btn btn-outline-success btn-sm m-lr-1"
          type="button"
          @click="bulkUpdate"
          id="prepareImportEntityNext"
        >
          <i class="fa fa-sign-out"></i> Update
        </button>
      </span>
    </dialog-component>
    <dialog-component
      :visible="downloadDocuments"
      :title="'Download Documents'"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @before-close="closeDownloadDocuments"
      :containerWidth="getIsMobile ? '100%' : '50%'"
      :containerHeight="getIsMobile ? '100%' : '50%'"
    >
      <el-row>
        <el-input
          class="search mb-10 mt-10 seachWidth float-right mr-3"
          placeholder="Search"
          v-model="searchDocument"
          clearable
          @input="searchDocumentName"
          prefix-icon="el-icon-search"
        ></el-input>
      </el-row>
      <div class="sidemenu dialog-content">
        <el-scrollbar class="scrollbar-container">
          <el-table
            border
            :data="searchDocument ? filteredDocuments : documentsData"
            :header-row-class-name="'my-header-row'"
            class="tableSty"
            :show-header="true"
            size="mini"
          >
            <el-table-column class="labelSty" label="Document Name">
              <template slot-scope="scope">
                <p style="font-size: 14px" slot="reference">
                  {{ scope.row.name }}
                </p>
              </template>
            </el-table-column>
            <el-table-column label="Action" width="150">
              <template slot-scope="scope">
                <ul class="action-buttons">
                  <li>
                    <el-tooltip content="Preview" placement="bottom">
                      <button
                        class="btn btn-light btn-sm m-lr-1"
                        type="button"
                        @click="onPreview(scope.row)"
                      >
                        <span class="text-muted">
                          <i class="fa fa-eye"></i>
                        </span>
                      </button>
                    </el-tooltip>
                  </li>
                  <li>
                    <el-tooltip content="Download File" placement="bottom">
                      <button
                        class="btn btn-light btn-sm m-lr-1"
                        type="button"
                        @click="onDownloadFile(scope.row)"
                      >
                        <span
                          class="text-muted"
                          target="_blank"
                          ref="downloadLink"
                        >
                          <i class="el-icon-download"></i>
                        </span>
                      </button>
                    </el-tooltip>
                  </li>
                </ul>
              </template>
            </el-table-column>
          </el-table>

          <p
            v-if="searchDocument && filteredDocuments.length === 0"
            class="ml-3 m-lr-1 m-tb-1"
          >
            No documents found.
          </p>
          <p
            v-else-if="!searchDocument && documentsData.length === 0"
            class="ml-3 m-lr-1 m-tb-1"
          >
            No documents found.
          </p>
        </el-scrollbar>
      </div>

      <span slot="footer">
        <button
          class="btn btn btn-outline-danger btn-sm m-lr-1"
          type="button"
          @click="downloadDocuments = false"
        >
          <i class="fa fa-close"></i> Close
        </button>
      </span>
    </dialog-component>
    <dialog-component
      title="Send Notification"
      :visible="sendNotificationDialog"
      v-loading="emailConfigLoading"
      :containerWidth="getIsMobile ? '100%' : '52%'"
      @before-close="sendNotificationDialog = false"
      :isShowFooter="false"
    >
      <EmailConfig
        :currentEntity="currentEntity"
        :isSendIndividual="true"
        :entityData="selectedNotifyEntity"
        :paramsContent="paramsData"
        v-on:closeNotificationDialog="closeSendNotificationDialog"
      >
      </EmailConfig>
    </dialog-component>
    <dialog-component
      title="Bulk Notification (selected data)"
      :visible="bulkSendNotificationDialog"
      v-loading="emailConfigLoading"
      :containerWidth="getIsMobile ? '100%' : '52%'"
      @before-close="bulkSendNotificationDialog = false"
      :isShowFooter="false"
    >
      <EmailConfig
        :currentEntity="currentEntity"
        :isSendIndividual="false"
        :selectedIds="selectedIds"
        :paramsContent="paramsData"
        v-on:closeNotificationDialog="closeBulkSendNotificationDialog"
      >
      </EmailConfig>
    </dialog-component>
    <dialog-component
      :title="multiLineTextLabel"
      :visible="openMultiLineTextDialog"
      @before-close="closeMultiLneData"
      :isShowFooter="false"
      :containerMaxWidth="'50vw'"
    >
      <div class="desription-text" v-if="isRichText">
        <ckeditor :config="editorConfig" v-model="multiLineTextData" read-only>
        </ckeditor>
      </div>
      <div class="desription-text" v-else>
        {{ multiLineTextData | truncateHTML() }}
      </div>
    </dialog-component>
    <dialog-component
      :visible="isShowOnMap"
      @before-close="closeShowOnMap"
      :containerWidth="'500px'"
      :containerHeight="'500px'"
    >
      <div class="map-view">
        <l-map :zoom="zoom" :center="center" style="height: 100%">
          <l-tile-layer :url="url"></l-tile-layer>
          <l-marker :lat-lng="coordinates" :icon="markerIcon"></l-marker>
        </l-map>
      </div>
    </dialog-component>

    <!-- <quickEntityAddWidget v-if="quickActionModalVisible" :currentEntity="quickActionCurrentEntity" :openNewAdd="true"
      :actionButtonField="quickActionDummyField" :relationshipDetails="quickActionRelationshipDetails"
      :detailed_add="true">
    </quickEntityAddWidget> -->
    <div v-if="quickActionModal && quickActionDummyField">
      <ActionButtonExecute
        v-if="quickActionModal && quickActionDummyField"
        :data="quickActionDummyField"
        :isView="false"
        :entityId="currentEntity._id"
        :entityDataId="quickActionDummyField.parent_entityDataId"
        :fromQuickAction="true"
        @closeModal="quickActionModal = false"
      >
      </ActionButtonExecute>
    </div>
    <el-drawer
      title="Privacy Link"
      :visible.sync="copyModal"
      :direction="'rtl'"
      :with-header="true"
      :size="getIsMobile ? '80%' : '20%'"
    >
      <IframeLink :routeKey="url_key" :type="type" :templateData="templateData">
      </IframeLink>
    </el-drawer>
    <dialog-component
      v-if="isDialogVisible"
      :title="currentField.label"
      :visible="isDialogVisible"
      @before-close="dataTableClose"
    >
      <DataTableExecute
        ref="dataTable"
        :data="currentField"
        v-if="currentField && currentField.input_type === 'DATA_TABLE'"
        :form="currentData"
        :is-view="!isExecuteCopy"
        :fieldsData="currentTemplateFields"
        :entityDataId="entityDataId"
        :savingData="savingData"
        :hasLabel="false"
      >
     </DataTableExecute>
      <span slot="footer" class="dialog-footer">
        <button class="btn btn-outline-danger btn-sm m-lr-1" type="button" @click="dataTableClose">
          <i class="fa fa-close"></i> Close
        </button>
      </span>
    </dialog-component>
  </section>
</template>

<script>
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import axios from "axios";
import { mapGetters } from "vuex";
import config from "@/config/app";
//import Vue from "vue";
//import VueLodash from "vue-lodash";
//import lodash from "lodash";
import { ckEditorConfig } from "@/assets/data/ckeditor_config.ts";
import NavigationHelper from "@/mixins/navigationHelper";
import entityRelationshipMixin from "@/mixins/entityRelationshipMixin";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
import TemplateBuilderhelper from "@/mixins/templateBuilderHelper.js";
import EntityHelper from "@/mixins/EntitiesHelper";
import SuccessAndErrorsTable from "./SuccessAndErrorsTable.vue";
import moment from "moment";
import * as XLSX from "xlsx";
import AutomationHelper from "@/mixins/AutomationHelper";
import QRCode from "qrcode";
import draggable from "vuedraggable";
import { isEqual } from "@/helpers/lodashHelper";

import {
  fetchAllEntityFiltersByEntity,
  addSingleFilterToLocal,
  updateFilterData,
  deleteFilter,
} from "@/repo/filtersRepo";
import {
  fetchAllEntityCustomizationsById,
  fetchActiveEntityCustomizationsByEntity,
} from "@/repo/entityCustomizationRepo";
//Vue.use(VueLodash, {
//  name: "custom",
//  lodash: lodash,
//});
export default {
  name: "EntityDataList",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    EntityFilter: () => import("./entityFilters.vue"),
    CustomComponentTable: () =>
      import("@/components/customDashboard/customDashboardTable.vue"),
    AddEntityReationshipDataWidget: () =>
      import("./../widgets/AddEntityReationshipDataWidget.vue"),

    CompleteEntityDataPopup: () => import("./completeEntityDataPopup.vue"),
    QuickEntityFilters: () =>
      import("@/components/widgets/entityQuickFilters.vue"),
    EntityGalleryView: () =>
      import("@/components/widgets/entityViewWidgets/EntityGalleryView.vue"),
    EntityChartView: () =>
      import("@/components/widgets/entityViewWidgets/EntityChartView"),
    EntityBoardView: () =>
      import("@/components/widgets/entityViewWidgets/EntityBoardView"),
    SuccessAndErrorsTable,
    EmailConfig: () => import("../notifications/emailConfig.vue"),
    draggable,
    EntityExecute: () =>
      import("@/components/templates/formComponentsExecute/EntityExecute.vue"),
    ActionButtonExecute: () =>
      import(
        "@/components/templates/formComponentsExecute/ActionButtonExecute"
      ),
      DataTableExecute: () =>
      import("@/components/templates/formComponentsExecute/DataTableExecute"),
    IframeLink: () => import("../../views/iframeLink.vue"),
  },
  mixins: [
    NavigationHelper,
    entityRelationshipMixin,
    CustomDashboardConfig,
    TemplateBuilderhelper,
    EntityHelper,
    AutomationHelper,
  ],
  data() {
    return {
      currentField: null, 
      isExecuteCopy: false,
      savingData: {}, 
      isDialogVisible: false,
      selectedRoww: null,
      currentData : null,  
      allEntityFields : [],
      currentTemplateFields: [], 
      filteredDocuments: [],
      markerIcon: "",
      savedCoordinates: "",
      isShowOnMap: false,
      center: [17.4065, 78.4772],
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      coordinates: [17.4065, 78.4772],
      zoom: 10,
      isRichText: null,
      editorConfig: ckEditorConfig,
      multiLineTextData: "",
      multiLineTextLabel: "",
      openMultiLineTextDialog: false,
      colorFields: {},
      pdfExport: false,
      treeCheckedNodes: null,
      count: 0,
      quickUpdateIndex: -1,
      quickUpdateRowData: null,
      currentkey: "",
      refreshPage: false,
      limit: 1,
      downloadDocuments: false,
      documentsData: [],
      activeNameForExcelImport: "success",
      currentActiveRowIndex: -1,
      excelFileData: [],
      successedExcelImportData: [],
      errorExcelImportData: [],
      currentEntityAllFields: [],
      foundArray: [],
      pageSizeForIEDS: 5,
      currentPageForIEDS: 1,
      totalForIEDS: 0,
      pageSizeForIE: 5,
      currentPageForIE: 1,
      totalForIE: 0,
      currentActiveSuccessRowIndex: -1,
      currentActiveErrorRowIndex: -1,
      entitySignleRecordVisible: false,
      entitybulkDeleteVisible: false,
      totalFields: [],
      isExistedName: false,
      mappingLoadingDialogVisible: false,
      mappingStatusDialogVisible: false,
      unmapped_dialog: false,
      is_excelUploaded: false,
      mappedFileName: "",
      selectedFile: "",
      mapped_fields: [],
      unmapped_fields: [],
      current_entity_tempates: [],
      activeName: "first",
      ExportscreenLoading: false,
      matchDialogVisible: false,
      is_active: 0,
      uploadedfileDataNames: [],
      existedField: "",
      uploadedFileObject: {},
      selectedRowsIds: [],
      selectedIds: [],
      filterText: "",
      loading: false,
      loadingText: "Loading...",
      total: 0,
      currentPage: 1,
      entityDataId: null,
      pageSize: 0,
      data: [],
      documentsLoading: false,
      firstTemplateId: null,
      primaryField: "",
      primaryKey: "",
      dialogTableVisible: false,
      entity_id: "",
      props: {
        children: "children",
        label: "label",
        disabled: "disabled",
      },
      fieldsListData: [],
      defaultKeys: [],
      selectedTableFields: [],
      filterColumList: [],
      checkedFields: [],
      selectedFieldKeys: [],
      modalLoading: false,
      currentEntity: {},
      entityFiltersData: {
        query_type: "AND",
        filters: [],
        filter_name: "",
        entity_id: "",
        isSaveFilter: false,
        columns_settings: [],
        quick_filters: [],
        displayType: "",
      },
      selectedEntityFields: [],
      entityAllFilters: [],
      currentFilter: "",
      dialogEdit: false,
      entityFieldSettings: [],
      refresh: false,
      relationshipsActions: [],
      selectedRelationshipDetails: [],
      selectedRelationshipExistedIds: [],
      showAddRelationshipDataDialog: false,
      showChildEntityDataDialog: false,
      selectedChildEntityDataId: "",
      selectedChildEntityId: "",
      selectedChildEntityRefName: "",
      selectedChildEntityPrimaryDataValue: "",
      selectedChildEntityDataIsMultple: false,
      selectedFileds: {},
      relationshipsData: [],
      globalVariablesData: null,
      order_type: null,
      order_by: null,
      totalData: [],
      search_string: "",
      dialogLoading: false,
      sortBy: "_id",
      sortOrder: -1,
      applied_quick_filters: [],
      applied_filters: [],
      activeEntityView: "TABLE",
      entityViewModal: false,
      importedData: [],
      mappedData: [],
      tableLoading: false,
      showDetailedCount: false,
      selectedComponent: null,
      appliedFilters: [],
      countDetailsEntityId: "",
      showDetailsFieldLabel: "",
      countDetailsParentEntityDataId: "",
      countDetailsParentEntityId: "",
      display_views: [
        {
          label: "Table",
          key: "TABLE",
        },
        {
          label: "Board",
          key: "BOARD",
        },
        // {
        //   label: "Calendar",
        //   key: "CALENDAR",
        // },
        // {
        //   label: "Timeline",
        //   key: "TIMELINE",
        // },
        {
          label: "Charts",
          key: "CHARTS",
        },
        {
          label: "Cards",
          key: "CARDS",
        },
      ],
      countComponent: null,
      countComponentFilters: [],
      custom_columns: [],
      groupedData: [],
      showCountDialog: false,
      sub_group: "",
      search_field: "",
      activeTemplate: "1",
      showFieldsParent: {
        profile: "",
        title: "",
        description: "",
        fields: [],
        chart_field: "",
        chart_type: "PIE",
        board_field: "",
        action_button: "",
        groupBy_chart_field: "",
        action_group_button: [],
        selectedFilter: [],
      },
      contentFields: [
        "IMAGE",
        "HEADING",
        "PARAGRAPH",
        "VIDEO",
        "SINGLE_LINE_CONTENT",
        "HORIZONTAL_LINE",
        "DIVISION",
        "HTML_CONTENT",
        "ACTION_BUTTON",
        "HTML_CONTENT",
      ],
      chartTypes: [
        {
          label: "Pie",
          value: "PIE",
        },
        {
          label: "Bar",
          value: "BAR",
        },
        {
          label: "Doughnut",
          value: "DOUGHNUT",
        },
      ],
      isApplicationUserSide: false,
      activeWorkspace: "",
      showErrorMessage: "",
      //Entity notification variables
      openEntityNotification: false,
      entityNotificationLoading: false,
      selectedNotifyEntity: null,
      entityNotificationBody: {
        subject: "",
        body: "",
        filters: [],
      },
      selectedEntityRelationships: [],
      jsonDataOfExcel: [],
      dateFormat: "dd-MM-yyyy",
      entityTempsWithException: [],
      excelFileHeaders: [],
      filterColumns: [],
      getTotalFields: [],
      preparedData: [],
      exportFilters: [],
      exportDataIds: [],
      bulkUpdateDialgVisible: false,
      bulkUpdateField: "",
      bulkUpdateValue: "",
      bulkUpdateFields: [],
      bulkUpdateOptions: [],
      selectAndEntityFields: [],
      optionsLoading: false,
      documentSearchData: [],
      searchDocument: "",
      dataTableSelected: false,
      allOtherEntityFields: [],
      filterVariableColumns: [],
      pageSizes: [10, 20, 50],
      isFilterOpen: true,
      current_entity_id: "",
      forbiddenAccess: false,
      isShowError: false,
      sendNotificationDialog: false,
      bulkSendNotificationDialog: false,
      paramsData: {},
      emailConfigLoading: false,
      nestedRelationalEntityDatas: [],
      settingsOrder: [],
      menuTitleLoading: false,
      quickActionButtonType: {
        add: 2,
        assign: 1,
        view: 4,
        add_assign: 3,
      },
      quickActionIcons: {
        add: "el-icon-plus",
        assign: "el-icon-check",
        view: "el-icon-view",
        add_assign: "el-icon-top-right",
      },
      quickActionDummyField: null,
      quickActionModal: false,
      relationQuickFilters: [],
      quickFilterVisible: false,
      customization_id: "",
      customization: null,
      entityUserInfoData: {},
      action_button: [],
      viewEditOptions: [
        { label: "View", value: "View" },
        { label: "Edit", value: "Edit" },
      ],
      selectedActions: [],
      copyModal: false,
      url_key: "",
      templateData: [],
      checkRoutekey: "",
      type: "",
      defaultSettingFields : []
    };
  },
  computed: {
    ...mapGetters("entities", [
      "getEntityDataById",
      "getPageSize",
      "getEntityDataByEntityId",
      "getEntityDataUpdateStatus",
      "getEntityDocumentsDataStatus",
      "getEntityDocumentsData",
      "getEntityRecordsForTable",
      "getUploadCSVfileStatus",
      "getupdateMappedFieldStatus",
      "getmappedFieldsData",
      "getuploadedFilesData",
      "getstoreUploadedFileStatus",
      "getimportMappedDataStatusUpdatedVersion",
      "getentitydataBulkDeleteStatus",
      "getEntitydataBulkDeleteErrors",
      "getEntityShowFields",
      "getSentEntityDataNotificationStatus",
      "getEntityById",
      "getBulkUpdateStatus",
      "getSentEntityNotificationStatus",
      "getSentEntityNotificationStatusErrors",
      "getEntityDataByIdError",
      "getEntityVariableFieldsInputTypes",
      "getEntityDataExportPDFStatus",
      "getEntityDataByQuery",
      "getQuickEntityFiltersObject",
    ]),
    ...mapGetters("companyTemplates", ["getTemplateDataTempVariable"]),
    ...mapGetters("templatesData", [
      "getTemplateDeleteStatus",
      "getTemplateDeleteError",
      "getDownloadUrl",
      "getDownloadError",
      "getDuplicateEntityData",
      "getDuplicateDataCheck",
    ]),
    ...mapGetters("filters", [
      "getAllEntityFilters",
      "getCreateNewFilter",
      "getUpdateFilterStatus",
      "getUpdateFilterData",
      "getDeleteFilterStatus",
      "getCreateNewFilterError",
      "getUpdateFilterError",
      "getDeleteFilterErros",
      "getSingleFilterData",
    ]),
    ...mapGetters("entityRelationships", [
      "getEntityRelationships",
      "getCurrentEntityRelationships",
      "getParentEntityRelatiponshipData",
      "getParentEntityNestedRelationshipData",
      "getEntityRelationshipDataAssignStatus",
    ]),
    ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getDefaultDateFormat",
      "getActiveContactType",
      "getUserTypeEmailsErrors",
      "getUserTypeEmails",
      "getUserTypeList",
      "getActiveWorkspace",
      "getGlobalFiltervalue",
    ]),
    ...mapGetters("menuManagementV2", [
      "getMenu",
      "getMenuManagementAddStatus",
      "getMenuManagementUpdateStatus",
      "getEntityMenu",
    ]),
    ...mapGetters("applicationUsersPermission", [
      "getCreatePermissionStatus",
      "getEntityPermission",
      "getUpdatePermissionStatus",
    ]),
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("contacts", ["getAllContacts"]),
    ...mapGetters("entityEmailTemplate", [
      "getEntityEmailTemplatesData",
      "getEmailTemplateError",
    ]),
    ...mapGetters("s3FileUpload", [
      "getFileUploadData",
      "getCompanyLogoUpdateData",
      "getFileUploadStatus",
    ]),
    ...mapGetters("sso", ["getAuthenticatedSSOToken", "getIsUserLoggedIn"]),
    ...mapGetters("iFrame", ["getIframeUrlInfo"]),
    ...mapGetters("entityCustomization", [
      "getCustomizationById",
      "getEntityActiveCustomization",
    ]),
    showQuickFilters() {
      return (
        this.isFilterOpen &&
        ((this.entityFiltersData &&
          this.entityFiltersData.quick_filters &&
          this.entityFiltersData.quick_filters.length) ||
          this.relationQuickFilters.length)
      );
    },
    filteredCustomizationActions() {
      if (this.customization) {
        return this.customization.actions.filter(
          (action) => action.QAPrimary && action.active
        );
      }
      return [];
    },
    paginationProps() {
      return [5, 10, 20, 50, 100];
    },
    paginationPropsForTable() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50],
      };
    },
    getFilterColumList() {
      if (this.treeCheckedNodes && this.treeCheckedNodes.length) {
        let data =  this.treeCheckedNodes
          .filter((e) => e.id && e.template_id)
          .map((e) => {
            if (e?.id && e.id.includes("#")) {
              e.id = e.id.split("#")[1];
            }
            return e;
          });
          return data;
      }
      return [];
    },
    getEntityRoute() {
      return "/entity-execute/" + this.$route.params.entity_id;
    },
    logoUploadUrl() {
      return config.S3_BUCKET_URL;
    },
    getCurrentFilters() {
      return this.entityFiltersData &&
        this.entityFiltersData.filters &&
        this.entityFiltersData.filters.length
        ? this.entityFiltersData.filters
        : [];
    },
    getFilterButtonLabel() {
      let name = "Apply";
      if (this.entityFiltersData) {
        if (this.entityFiltersData.isSaveFilter) {
          name = "Save & Apply";
        } else if (this.entityFiltersData.updateFilter) {
          name = "Update & Apply";
        }
      }
      return name;
    },
    checkFilterDisabled() {
      if (this.entityFiltersData) {
        if (
          this.entityFiltersData.filters &&
          this.entityFiltersData.filters.length
        ) {
          const lastmonthFilter = this.entityFiltersData.filters.find(
            (fl) =>
              (fl.operator === "lastmonth" && fl.field === "updated_at") ||
              (fl.operator === "thismonth" && fl.field === "updated_at") ||
              (fl.operator === "lastmonth" && fl.field === "created_at") ||
              (fl.operator === "thismonth" && fl.field === "created_at")
          );

          if (lastmonthFilter) {
            return false;
          }
        }
        if (
          this.entityFiltersData.filters &&
          this.entityFiltersData.filters.length
        ) {
          return this.entityFiltersData.filters.find(
            (fl) =>
              !fl ||
              !fl.field ||
              !fl.operator ||
              (!fl.data_source &&
                fl.operator != "exists" &&
                fl.operator != "notexists" &&
                fl.operator != "between" &&
                fl.operator != "notbetween" &&
                fl.operator != "dateinclude" &&
                fl.operator != "notdateinclude" &&
                fl.operator != "real_time" &&
                fl.operator != "weekdays" &&
                fl.operator != "filter" &&
                fl.field != "created_by" &&
                fl.field != "updated_by") ||
              (fl.data_source == "GLOBAL_VARIABLE" &&
                !fl.global_variable_id &&
                fl.operator != "exists" &&
                fl.operator != "notexists") ||
              (fl.data_source == "self_field" &&
                !fl.value_field &&
                fl.operator != "exists" &&
                fl.operator != "notexists") ||
              (fl.data_source == "TEMPLATE" &&
                !fl.value_field &&
                fl.operator != "exists" &&
                fl.operator != "notexists") ||
              (!fl.value &&
                typeof fl.value !== "number" &&
                typeof fl.value !== "boolean" &&
                fl.data_source != "self_field" &&
                fl.operator != "exists" &&
                fl.operator != "between" &&
                fl.operator != "notexists" &&
                fl.data_source != "GLOBAL_VARIABLE" &&
                fl.data_source != "LOGIN_USER" &&
                fl.operator != "weekdays" &&
                fl.data_source != "TEMPLATE")
          ) ||
            (!this.entityFiltersData.filter_name &&
              (this.entityFiltersData.isSaveFilter ||
                this.entityFiltersData.updateFilter))
            ? true
            : false;
        } else if (
          this.entityFiltersData.quick_filters &&
          this.entityFiltersData.quick_filters.length
        ) {
          return !this.entityFiltersData.filter_name &&
            (this.entityFiltersData.isSaveFilter ||
              this.entityFiltersData.updateFilter)
            ? true
            : false;
        }
        return this.entityFiltersData.isSaveFilter ||
          this.entityFiltersData.updateFilter
          ? true
          : false;
      }
      return false;
    },
    filteredData() {
      // if (this.search_string) {
      //   return this.data.filter((data) => {
      //     if (data?.entityData) {
      //       let found = false;
      //       Object.keys(data.entityData).forEach((temp) => {
      //         Object.values(data.entityData[temp]).forEach((value) => {
      //           if (
      //             typeof value == "string" &&
      //             value.toLowerCase().includes(this.search_string.toLowerCase())
      //           ) {
      //             found = true;
      //           } else if (
      //             typeof value == "number" &&
      //             value == this.search_string
      //           ) {
      //             found = true;
      //           } else if (
      //             typeof value == "object" &&
      //             value != null &&
      //             value != undefined
      //           ) {
      //             if (value.length) {
      //               let valueFound = value.find((item) => {
      //                 if (typeof item == "number") {
      //                   return item == this.search_string;
      //                 } else if (typeof item == "string") {
      //                   return item
      //                     .toLowerCase()
      //                     .includes(this.search_string.toLowerCase());
      //                 }
      //               });
      //               if (valueFound) {
      //                 found = true;
      //               }
      //             } else {
      //               if (
      //                 value["name"] &&
      //                 typeof value.name == "string" &&
      //                 value.name
      //                   .toLowerCase()
      //                   .includes(this.search_string.toLowerCase())
      //               ) {
      //                 found = true;
      //               }
      //             }
      //           }
      //         });
      //       });
      //       return found;
      //     }
      //   });
      // }
      return this.data;
    },
    successedExcelImportDataPagination() {
      const from = (this.currentPageForIEDS - 1) * this.pageSizeForIEDS;
      let to = from + this.pageSizeForIEDS;
      // if (to > this.totalForIEDS) {
      //   to = this.totalForIEDS;
      // }
      return this.successedExcelImportData.slice(from, to);
    },
    errorExcelImportDataPagination() {
      const from = (this.currentPageForIE - 1) * this.pageSizeForIE;
      let to = from + this.pageSizeForIE;
      // if (to > this.totalForIEDS) {
      //   to = this.totalForIEDS;
      // }
      return this.errorExcelImportData.slice(from, to);
    },
    getSearchData() {
      let result = [];
      if (this.search_field) {
        this.currentEntity.templates.forEach((td) => {
          let template = JSON.parse(JSON.stringify(td));
          if (template?.templateInfo?.sections && !template.is_repeatable) {
            let filterFields =
              template?.templateInfo?.sections[0]?.fields.filter(
                (field) =>
                  field?.label &&
                  field.label
                    .toLowerCase()
                    .includes(this.search_field.toLocaleLowerCase())
              );
            if (filterFields && filterFields.length > 0) {
              template.templateInfo.sections[0].fields = [];
              template.templateInfo.sections[0].fields = [...filterFields];
              result.push(template);
            }
          }
        });
      }
      return result;
    },
    currentMenu() {
      if (
        this.isApplicationUserSide &&
        this.$route?.params?.menuId &&
        this.getEntityMenu?.menu_list
      ) {
        let menu;
        let id = this.$route.params.menuId || this.$route.query.menu_id;
        this.getEntityMenu.menu_list.forEach((e) => {
          if (e._id == id) {
            menu = e;
          } else if (e.children) {
            (e.children || []).forEach((el) => {
              if (el._id == id) {
                menu = el;
              } else if (el.children) {
                (el.children || []).forEach((ele) => {
                  if (ele._id == id) {
                    menu = ele;
                  }
                });
              }
            });
          }
        });
        return menu;
      }
      return null;
    },
    getSelfEntityFilters() {
      if (
        this.isApplicationUserSide &&
        this.getAuthenticatedUser?.contact_types
      ) {
        let currentActiveWorkspace =
          this.getAuthenticatedUser.contact_types.find((e) => {
            let id =
              e.contact_type && e.contact_type._id
                ? e.contact_type._id
                : e.contact_type;
            if (id && id == this.activeWorkspace) {
              return true;
            }
          });
        return [
          {
            field: "self#related_to/1",
            operator: "=",
            value:
              currentActiveWorkspace && currentActiveWorkspace.account_data_id
                ? currentActiveWorkspace.account_data_id
                : "",
            value_type: "",
            value_source: "",
            query_type: "OR",
            value_field: "",
            validations: {
              data_type: "",
            },
            nested_related_id: "",
            data_source: "self_field",
            relation_entity_id: this.activeWorkspace,
            data_type: "RELATION",
          },
        ];
      }
      return [];
    },
    // showError() {
    //   if ((this.isApplicationUserSide && !this.currentMenu) || this.forbiddenAccess) {
    //     return true;
    //   }
    //   return false;
    // },
    getCurrentFilter() {
      return this.entityAllFilters.find(
        (e) => e && e._id == this.currentFilter
      );
    },
    getMainLabel() {
      if (this.entityFiltersData && this.entityFiltersData.tableFieldGroupBy) {
        return this.getLabel(this.entityFiltersData.tableFieldGroupBy);
      }
      return "";
    },
    getSettingsLabel(key) {
      return this.fieldsListData.find((fd) => fd.key == key).label;
    },
    groupedOptions() {
      return [
        {
          label: "options",
          options: this.viewEditOptions,
        },
        {
          label: "Action Buttons",
          options: this.getEntityFieldsByType(["ACTION_BUTTON"]).map(
            (field) => ({
              label: field.label,
              value: field.key,
            })
          ),
        },

        {
          label: "Customization",
          options: this.customization?.actions
            .filter((e) => e.active)
            .map((action) => ({
              label: action.label,
              value: action.label,
            })),
        },
      ];
    },
    displayBackButton() {
      if (this.getIframeUrlInfo) {
        let modifiedKey = this.checkRoutekey;
        if (this.checkRoutekey.startsWith("if#")) {
          const parts = this.checkRoutekey.split("#");
          if (parts.length > 2) {
            modifiedKey = parts.slice(2).join("#");
          }
        }
        if (modifiedKey === this.getIframeUrlInfo.url_key) {
          return false;
        }
      }
      return true;
    },
    getType() {
      if (this.activeEntityView == "CARDS") {
        return "EntityGalleryView";
      } else {
        return "ViewEntityData";
      }
    },
  },
  async mounted() {
    this.menuTitleLoading = true;
    this.searchDocument = "";
    this.currentkey = this.$route.query.key;
    let limit =
      this.pageSizes && this.pageSizes[0]
        ? this.pageSizes[0]
        : this.getPageSize;
    this.pageSize = limit;
    this.refreshPage = true;
    this.currentPage = parseInt(this.$route.query.page) || 1;
    this.pageSize = parseInt(this.$route.query.pageSize) || 10;

    if (this.$route.query.order_by) {
      this.sortBy = this.$route.query.order_by;
    }
    if (this.$route.query.order_type) {
      this.sortOrder = this.$route.query.order_type;
    }

    if (this.$route.query.customization) {
      this.customization_id = this.$route.query.customization;
    }
    setTimeout(() => {
      this.refreshPage = false;
    });
    this.entity_id = this.$route.params.entity_id;
    this.search_string = "";
    this.getNecessaryInfo();
    await this.isIframe();
    // await this.checkUrlType()
    // this.fetchAllEmployee();
    this.checkRoutekey = this.generateOutputFromUrl(this.$route.fullPath, [
      "key",
      "page",
      "pageSize",
    ]);
  },

  methods: {
    checkIsEntityCount(key) {
      let field = this.getField(key);
      return field && field.show_count ? true : false;
    },
    closeDetailedCount() {
      this.selectedComponent = null;
      this.countDetailsParentEntityDataId = "";
      this.countDetailsParentEntityId = "";
      this.countDetailsEntityId = "";
      this.appliedFilters = [];
      this.showDetailedCount = false;
    },
    openDetailedCount(key, parent) {
      let field = this.getField(key);
        this.selectedComponent = {
        entity_id: field.entity_id,
        component_name: "",
        customFilers: field.filters || [],
        parent_entityId: parent.entity_id,
        parent_entityDataId: parent._id,
      };
      this.countDetailsParentEntityDataId = parent._id;
      this.countDetailsParentEntityId = parent.entity_id;
      this.countDetailsEntityId = field.entity_id;
      this.appliedFilters = field.filters || [];
      this.showDetailedCount = true;
    },
    formatDate(value) {
      if (!value) {
        return "";
      }

      const date = new Date(value);
      if (isNaN(date.getTime())) {
        return "";
      }

      return this.globalDateTimeFormat(date);
    },
    getNumberOfDataTableFields(row, step) {
      if (row.entityData && row.entityData[step.template_id] && row.entityData[step.template_id][step.id]) {
        const dataTable = row.entityData[step.template_id][step.id];
        return Object.keys(dataTable[0]).filter(key => key !== 'keyIndex' && key !== 'date_time/dateformat').length;
      }
      return 0;
    },
    dataTableOpen(row,step) {
    console.log("Opening dialog for row:", row,"step",step);
    this.currentTemplateFields = (this.allEntityFields || []).filter(e => e.template_id == step.template_id);
    this.currentField = (this.currentTemplateFields || []).find(e => e.key == step.id);
    this.currentData = {};
    this.currentData ={
      ...row.entityData[step.template_id]
    };
    this.selectedRoww = row;
    this.isDialogVisible = true;
  },
  dataTableClose() {
    this.isDialogVisible = false;
  },
    globalDateTimeFormat(date) {
      const dateString = date
        .toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/\//g, "-");

      const timeString = date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      });

      return `${dateString} ${timeString}`;
    },

    getStandardTemplateInfo(row) {
      if (this.currentEntity && this.currentEntity.templates) {
        let entity = this.currentEntity;
        let standardTemp = "";
        if (entity.entity_type === "INDIVIDUAL") {
          standardTemp = entity.templates.find((temp) => {
            if (
              temp &&
              temp.templateInfo &&
              temp.templateInfo.type === "STANDARD" &&
              temp.template_id
            ) {
              return temp;
            }
          });
          if (
            standardTemp?.template_id &&
            row?.entityData &&
            row.entityData[standardTemp.template_id] &&
            row.entityData[standardTemp.template_id]?.first_name &&
            row.entityData[standardTemp.template_id].last_name
          ) {
            return (
              row.entityData[standardTemp.template_id].first_name +
              "," +
              row.entityData[standardTemp.template_id].last_name
            );
          } else {
            return "";
          }
        }
        return "";
      }
      return "";
    },
    getInitials(name) {
      const words = name.split(",");
      const firstLetter = words[0].charAt(0).toUpperCase();
      const lastLetter = words[words.length - 1].charAt(0).toUpperCase();
      return firstLetter + lastLetter;
    },
    showMap(newValue) {
      this.isShowOnMap = true;
      this.getCoordinates(newValue);
    },
    closeShowOnMap() {
      this.isShowOnMap = false;
    },
    async getCoordinates(address) {
      try {
        // These are query Params to make API call
        const config = {
          params: {
            address: address,
            sensor: true,
            key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
          },
        };

        // Make the API request using the config Params object
        const response = await axios.get(
          "https://maps.googleapis.com/maps/api/geocode/json",
          config
        );

        // Extract coordinates from response
        const coordinates = response.data.results[0].geometry.location;
        // Update the coordinates for the marker to point
        this.coordinates = [coordinates.lat, coordinates.lng];
        // Update coordinates to focus map at where the pointer is
        this.center = [coordinates.lat, coordinates.lng];
        this.$set(
          this.form,
          this.data.key,
          `${this.coordinates[0]}, ${this.coordinates[1]}`
        );
        return coordinates;
      } catch (error) {
        return null;
      }
    },
    checkPermissions(step) {
      let access = "EDIT";
      let template_id = step.template_id;
      if (this.isApplicationUserSide) {
        if (this.getEntityPermission) {
          if (this.currentMenu) {
            if (
              this.currentMenu?.entity_type == "ENTITY" &&
              this.getEntityPermission.other_entities &&
              template_id &&
              this.getEntityPermission.other_entities[this.currentEntity._id]
                ?.templates?.[template_id] &&
              this.getEntityPermission.other_entities[
                this.currentEntity._id
              ]?.templates?.[template_id].indexOf(access) === -1
            ) {
              return false;
            } else if (
              this.currentMenu?.entity_type == "RELATIONAL_ENTITY" &&
              this.getEntityPermission.relational_entities &&
              this.getEntityPermission.relational_entities[
                this.currentEntity._id
              ]?.permissions &&
              this.getEntityPermission.relational_entities[
                this.currentEntity._id
              ]?.templates?.[template_id].indexOf(access) === -1
            ) {
              return false;
            } else if (
              this.currentMenu?.entity_type == "NESTED_RELATIONAL_ENTITY" &&
              this.currentMenu?.nested_relational_entity &&
              this.getEntityPermission.nested_relational_entities &&
              this.getEntityPermission.nested_relational_entities[
                this.currentMenu.nested_relational_entity
              ]?.templates?.[template_id].indexOf(access) === -1
            ) {
              return false;
            }
          } else if (this.$route.query.fromCustom) {
            if (
              this.getEntityPermission?.other_entities &&
              this.getEntityPermission?.other_entities[
                this.currentEntity._id
              ]?.templates?.[template_id].indexOf(access) === -1
            ) {
              return false;
            }
            return true;
          }
        }
        return true;
      }
      return true;
    },
    getStyleWidth(step) {
      if (step.width) {
        return `width:${step.width - 15}px`;
      }
      return "width:235px";
    },
    getFieldForQuickUpdate(step) {
      return this.getEntityDataById?.templates
        ?.find((e) => e.template_id == step.template_id)
        .templateInfo.sections[0]?.fields.find((e) => e.key == step.id);
    },
    truncateText(text) {
      const cleanedText = text.replace(/<p>/g, "").replace(/<\/p>/g, "");
      const maxLength = 100;
      if (cleanedText.length > maxLength) {
        return cleanedText.substring(0, maxLength) + "...";
      } else {
        return cleanedText;
      }
    },
    openMultiLineData(htmlContent, label, step) {
      this.openMultiLineTextDialog = true;
      this.multiLineTextData = htmlContent;
      this.multiLineTextLabel = label;
      if ("currency_code" in step && "data_type" in step.currency_code) {
        this.isRichText = false;
      } else {
        this.isRichText = true;
      }
    },
    closeMultiLneData() {
      this.openMultiLineTextDialog = false;
      this.isRichText = null;
    },
    rulesEventEmitter(template_id, form) {
      let template = this.getEntityDataById?.templates?.find(
        (e) => e?.templateInfo._id == template_id
      );
      let fields = template?.templateInfo?.sections[0]?.fields || [];
      let templateRules = template.templateInfo.rules.filter(
        (rl) => !rl?.show_alert && rl?.when !== "AFTER_SAVE"
      );
      this.applyRulesOnFields(fields, templateRules, form);
    },
    entitybulkDeleteVisibleDialog() {
      this.entitybulkDeleteVisible = false;
    },
    closeBulkUpdateDialogueBox() {
      this.closeBulkUpdateDialogue = false;
    },
    unMappedDialog() {
      this.unmapped_dialog = false;
    },
    async getNestedRelationshipDataIds() {
      if (
        this.isApplicationUserSide &&
        this.getAuthenticatedUser?.contact_types &&
        this.currentMenu?.nested_relational_entity
      ) {
        let currentActiveWorkspace =
          this.getAuthenticatedUser.contact_types.find((e) => {
            let id =
              e.contact_type && e.contact_type._id
                ? e.contact_type._id
                : e.contact_type;
            if (id && id == this.activeWorkspace) {
              return true;
            }
          });
        let params = {
          parent_entity_id: this.activeWorkspace,
          parent_entity_data_id: currentActiveWorkspace.account_data_id,
          child_entity_id:
            this.currentMenu.nested_relational_entity.split("#")[0],
          nested_child_entity_id:
            this.currentMenu.nested_relational_entity.split("#")[1],
        };
        await this.$store.dispatch(
          "entityRelationships/fetchParentEntityNestedRelationshipData",
          params
        );
        if (
          this.getParentEntityNestedRelationshipData &&
          this.getParentEntityNestedRelationshipData.child_entity_data_ids &&
          this.getParentEntityNestedRelationshipData.child_entity_data_ids
            .length
        ) {
          return this.getParentEntityNestedRelationshipData
            .child_entity_data_ids;
        }
      }
      return [];
    },
    fieldDisabled(field) {
      return this.bulkUpdateRows.find(
        (e) =>
          e.field.split("#")[0] == field.template_id &&
          e.field.split("#")[1] == field.key
      ) == undefined
        ? false
        : true;
    },
    addBulkUpdateRow() {
      this.bulkUpdateRows.push({
        field: "",
        value: "",
      });
    },
    removeBulkUpdateRow(index) {
      this.bulkUpdateRows.splice(index, 1);
    },
    getFieldOptions(template_id, key) {
      return (
        this.getEntityDataById?.templates
          ?.find((e) => e?.templateInfo._id == template_id)
          .templateInfo.sections[0]?.fields?.find((e) => e.key == key)
          .options || []
      );
    },
    quickUpdateFieldDisabled(template_id, key) {
      let field = this.getEntityDataById?.templates
        ?.find((e) => e?.templateInfo._id == template_id)
        .templateInfo.sections[0]?.fields?.find((e) => e.key == key);
      if (
        field &&
        field?.properties &&
        field.properties.filed_content &&
        ["READ ONLY", "DISABLED", "Hide"].includes(
          field.properties.filed_content
        )
      ) {
        return true;
      }
      return false;
    },
    async getEntityFieldOptions(template_id, key) {
      let field = this.getEntityDataById?.templates
        ?.find((e) => e?.templateInfo._id == template_id)
        .templateInfo.sections[0]?.fields?.find((e) => e.key == key);
      await this.$store.dispatch("entities/fetchEntityRecordsForTable", {
        entity_id: field.entity_id,
        limit: 500,
        page: this.bulkUpdateEntityFieldPage,
      });
      if (
        this.getEntityRecordsForTable &&
        this.getEntityRecordsForTable.length
      ) {
        this.bulkUpdateEntityFieldsData[field.entity_id] = [
          ...this.bulkUpdateEntityFieldsData[field.entity_id],
          ...this.getEntityRecordsForTable,
        ];
      }
    },
    async updateRow(row) {
      this.loading = true;
      await Promise.all(
        this.getEntityDataById?.templates.map(async (temp) => {
          if (
            !Object.values(this.quickUpdateRowData[temp.template_id]).every(
              (value) => value == null || value === ""
            ) &&
            !isEqual(
              row.entityData[temp.template_id],
              this.quickUpdateRowData[temp.template_id]
            )
          ) {
            this.loadingText = "Checking duplicate data...";
            await this.$store.dispatch("templatesData/checkDuplicateData", {
              data: [
                {
                  form: this.quickUpdateRowData[temp.template_id],
                  template_id: temp.template_id,
                },
              ],
              entity_id: this.entity_id,
              parent_entityDataId: row._id || null,
            });
            if (this.getDuplicateDataCheck) {
              if (!this.getDuplicateDataCheck?.allowed) {
                this.$notify.warning({
                  title: "Warning",
                  message: "Duplicate data found",
                });
                this.loading = false;
                this.loadingText = "Loading...";
                return;
              }
            }
            this.loadingText = "Updating data...";
            await this.$store.dispatch("templatesData/updateUserTemplateData", {
              template_data: this.quickUpdateRowData[temp.template_id],
              entity_data_id: row._id,
              template_id: temp.template_id,
              entity_id: this.entity_id,
            });
          }
        })
      );
      this.quickUpdateIndex = -1;
      this.loading = false;
      await this.fetchEntitiesDataForTable();
    },
    closeDialog() {
      this.matchDialogVisible = false;
    },
    showError() {
      if (
        (this.isApplicationUserSide && !this.currentMenu) ||
        this.forbiddenAccess
      ) {
        this.isShowError = true;
        return true;
      }
      this.isShowError = false;
      return false;
    },
    openFiltersCollapse() {
      this.isFilterOpen = !this.isFilterOpen;
    },
    async duplicateEntityData(row) {
      this.loading = true;
      let data = {
        entity_id: this.entity_id,
        existed_data_id: row._id,
        current_date: this.getCurrentDate,
      };
      await this.$store.dispatch("templatesData/duplicateEntityData", data);
      if (this.getDuplicateEntityData?._id) {
        this.$notify.success({
          title: "Success",
          message: "Data duplicated successfully",
        });
        this.loading = false;
        this.fetchEntitiesDataForTable();
      } else {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message:
            "Error while duplicating " +
            (this.currentEntity && this.currentEntity.name
              ? this.currentEntity.name
              : "data"),
        });
      }
    },
    searchDocumentName() {
      this.filteredDocuments = this.documentsData.filter((document) => {
        return document.name
          .toLowerCase()
          .includes(this.searchDocument.toLowerCase());
      });
    },
    stepNavigation(step) {
      if (this.is_active > step) {
        if (this.is_active == 2) {
          if (step == 1) {
            this.foundArray = [];
            this.preparedData = [];
          } else if (step == 0) {
            this.mappedFileName = "";
            this.existedField = "";
            this.uploadedFileObject = null;
            this.mapped_fields = [];
            this.unmapped_fields = [];
            this.foundArray = [];
            this.preparedData = [];
            this.is_excelUploaded = false;
          }
        } else if (this.is_active == 1) {
          this.mappedFileName = "";
          this.existedField = "";
          this.uploadedFileObject = null;
          this.mapped_fields = [];
          this.unmapped_fields = [];
          this.is_excelUploaded = false;
        }
        this.is_active = step;
      } else {
        this.$message.warning("You can't go to that step");
      }
    },
    checkClearFileOrNot() {
      if (this.mappedFileName == "" || this.existedField == "") {
        this.is_excelUploaded = false;
      }
    },
    async onPreview(data) {
      let fileName = data.name;
      fileName = await fileName.replace(/\//g, "-");
      fileName = await fileName.replace(/ /g, "_");
      fileName = await fileName.replace(/[()]/g, "");
      if (data && !data.path) {
        data.path = `template-data-documents/${fileName}`;
      }
      await this.$store.dispatch("templatesData/downloadTemplateDocument", {
        path: data.path,
      });
      let newWindow = open(
        this.getDownloadUrl,
        "example",
        "width=1500,height=900"
      );
      newWindow.focus();
      newWindow.onload = function () {};
    },
    async onDownloadFile(data) {
      this.loading = true;
      this.loadingText = "Downloading File....";
      let fileName = data.name;
      fileName = await fileName.replace(/\//g, "-");
      fileName = await fileName.replace(/ /g, "_");
      fileName = await fileName.replace(/[()]/g, "");
      if (data && !data.path) {
        data.path = `template-data-documents/${fileName}`;
      }
      await this.$store.dispatch("templatesData/downloadTemplateDocument", {
        path: data.path,
      });
      await fetch(this.getDownloadUrl, {
        method: "GET",
        responseType: "blob",
      })
        .then((res) => res.blob())
        .then((response) => {
          var fileURL = window.URL.createObjectURL(response);
          const link = document.createElement("a"); // Create an anchor tag
          link.href = fileURL; // Set the URL as the href attribute
          link.download = fileName; // Set the desired filename
          document.body.appendChild(link); // Append the anchor tag to the document body
          link.click(); // Simulate a click on the anchor tag to initiate the download
          document.body.removeChild(link);
          this.loading = false;
        });
      if (this.getDownloadUrl) {
        // window.open(this.getDownloadUrl, "_blank");
        // this.$refs.this.getDownloadUrl.click();
      } else {
        this.$notify.error({
          title: "Error",
          message: this.getDownloadError,
        });
      }
      this.loading = false;
      this.loadingText = "Loading...";
    },
    async headerDragged(newWidth, oldWidth, column, event) {
      console.log("event", event);
      if (
        this.getCurrentFilter?.columns_settings &&
        this.getCurrentFilter.columns_settings.length
      ) {
        const columnSetting = this.getCurrentFilter.columns_settings;
        let resizedField = columnSetting.map((e) =>
          e.label == column.label ? { ...e, width: newWidth } : e
        );
        await this.$store.dispatch("filters/updateFilter", {
          id: this.getCurrentFilter._id,
          params: {
            columns_settings: resizedField,
          },
        });
      } else if (
        this.getEntityDataById.settings &&
        this.getEntityDataById.settings.length
      ) {
        let entity = this.getEntityDataById;
        let draggedField = entity.settings.find((e) => e.label == column.label);
        let draggedFieldIndex = 0;
        let settings = entity.settings.filter((e) => {
          if (e.label !== draggedField.label) {
            return e;
          } else {
            draggedFieldIndex = entity.settings.indexOf(e);
          }
        });
        draggedField["width"] = newWidth;
        settings.splice(draggedFieldIndex, 0, draggedField);
        let params = {
          name: entity.name,
          description: entity.description,
          entity_type: entity.entity_type,
          templates: entity.templates,
          id: entity._id,
          primaryFields: entity.primaryFields,
          settings: settings,
        };
        await this.$store.dispatch("entities/updateEntityById", params);
      }
    },
    cellDoubleClicked(row) {
      if (this.checkUrlType() == "SignIn") {
        if (!this.checkPerimission("ROW DATA EDIT")) {
          this.$message({
            message: "You don't have permission to edit the data",
            type: "warning",
          });
          return;
        }
        this.quickUpdateIndex = this.data.findIndex(
          (e) => e._id.toString() == row._id.toString()
        );
        this.quickUpdateRowData = null;
        this.quickUpdateRowData = JSON.parse(JSON.stringify(row.entityData));
        if (
          this.getEntityDataById?.templates.length !==
          Object.keys(row.entityData)
        ) {
          this.getEntityDataById?.templates.map((tmp) => {
            if (
              !Object.keys(this.quickUpdateRowData).includes(tmp.template_id)
            ) {
              let emptyTemplateDataObj = {};
              tmp.templateInfo.sections[0].fields.map((fd) => {
                emptyTemplateDataObj[fd.key] = "";
              });
              this.quickUpdateRowData[tmp.template_id] = emptyTemplateDataObj;
            }
          });
        }
      }
    },
    checkPermission(access) {
      return this.entityFiltersData.permissions.access_set.includes(access);
    },
    downloadDocumentsPopup(row, step) {
      this.documentsData =
        row.entityData[step.template_id][step.id].files &&
        row.entityData[step.template_id][step.id].files.length
          ? row.entityData[step.template_id][step.id].files
          : row.entityData[step.template_id][step.id];
      this.downloadDocuments = true;
      //this.downloadDocuments = true
    },
    closeDownloadDocuments() {
      this.downloadDocuments = false;
    },
    headerCellStyle() {
      return {
        backgroundColor: "#F2F6FC",
        color: "#606266",
        fontWeight: "bold",
      };
    },
    getFieldsForBulkUpdate() {
      let templates = this.getEntityDataById?.templates || [];
      if (this.isApplicationUserSide) {
        let templateIds = [];
        let entityId;
        if (this.currentMenu && this.currentMenu.entity_id) {
          entityId = this.currentMenu.entity_id._id
            ? this.currentMenu.entity_id._id
            : this.currentMenu.entity_id;
          if (
            entityId &&
            this.getEntityPermission &&
            this.getEntityPermission.other_entities &&
            this.getEntityPermission.other_entities[entityId] &&
            this.getEntityPermission.other_entities[entityId].templates
          ) {
            Object.keys(
              this.getEntityPermission.other_entities[entityId].templates
            ).forEach((e) => {
              if (
                this.getEntityPermission.other_entities[entityId].templates[
                  e
                ].includes("BULK UPDATE")
              ) {
                templateIds.push(e);
              }
            });
          }
        } else if (this.currentMenu && this.currentMenu.relational_entity) {
          entityId = this.currentMenu.relational_entity._id
            ? this.currentMenu.relational_entity._id
            : this.currentMenu.relational_entity;
          if (
            entityId &&
            this.getEntityPermission &&
            this.getEntityPermission.relational_entities &&
            this.getEntityPermission.relational_entities[entityId] &&
            this.getEntityPermission.relational_entities[entityId].templates
          ) {
            Object.keys(
              this.getEntityPermission.relational_entities[entityId].templates
            ).forEach((e) => {
              if (
                this.getEntityPermission.relational_entities[
                  entityId
                ].templates[e].includes("BULK UPDATE")
              ) {
                templateIds.push(e);
              }
            });
          }
        }
        let indexesToBeDeleted = [];
        templates.forEach((temp) => {
          if (!templateIds.includes(temp.templateInfo._id.toString())) {
            indexesToBeDeleted.push(templates.indexOf(temp));
          }
        });
        indexesToBeDeleted.sort((a, b) => b - a);
        indexesToBeDeleted.forEach((num) => {
          templates.splice(num, 1);
        });
      }
      this.selectAndEntityFields = [];
      this.bulkUpdateFields = [];
      let selectFields = [];
      let entityFields = [];
      let multiSelectFields = [];
      let checkboxFields = [];
      templates.forEach((template) => {
        let select = template?.templateInfo?.sections[0]?.fields.filter(
          (e) =>
            e.input_type === "SELECT" && e.properties.filed_content !== "Hide"
        );
        let entity = template?.templateInfo?.sections[0]?.fields.filter(
          (e) =>
            e.input_type === "ENTITY" && e.properties.filed_content !== "Hide"
        );
        let multiSelect = template?.templateInfo?.sections[0]?.fields.filter(
          (e) =>
            e.input_type === "MULTI_SELECT" &&
            e.properties.filed_content !== "Hide"
        );
        let checkbox = template?.templateInfo?.sections[0]?.fields.filter(
          (e) =>
            e.input_type === "CHECKBOX" && e.properties.filed_content !== "Hide"
        );
        select.forEach((e) => {
          e["template_id"] = template.template_id;
        });
        entity.forEach((e) => {
          e["template_id"] = template.template_id;
        });
        multiSelect.forEach((e) => {
          e["template_id"] = template.template_id;
        });
        checkbox.forEach((e) => {
          e["template_id"] = template.template_id;
        });
        selectFields = [...selectFields, ...select];
        entityFields = [...entityFields, ...entity];
        multiSelectFields = [...multiSelectFields, ...multiSelect];
        checkboxFields = [...checkboxFields, ...checkbox];
      });
      if (selectFields && selectFields.length) {
        this.bulkUpdateFields.push({
          label: "Select",
          options: selectFields,
        });
      }
      if (multiSelectFields && multiSelectFields.length) {
        this.bulkUpdateFields.push({
          label: "Multi Select",
          options: multiSelectFields,
        });
      }
      if (entityFields && entityFields.length) {
        this.bulkUpdateFields.push({
          label: "Entity",
          options: entityFields,
        });
      }
      if (checkboxFields && checkboxFields.length) {
        this.bulkUpdateFields.push({
          label: "Checkbox",
          options: checkboxFields,
        });
      }
      this.selectAndEntityFields = [
        ...selectFields,
        ...entityFields,
        ...multiSelectFields,
        ...checkboxFields,
      ];
      this.bulkUpdateDialgVisible = true;
    },
    async fetchValuesForSelectField() {
      this.optionsLoading = true;
      this.bulkUpdateOptions = [];
      this.bulkUpdateValue = "";
      let field = this.selectAndEntityFields.find(
        (e) => e._id == this.bulkUpdateField.split("#")[0]
      );
      if (field.input_type == "SELECT" || field.input_type == "MULTI_SELECT") {
        field.options.forEach((option) => {
          this.bulkUpdateOptions.push({
            label: option,
            value: option,
          });
        });
      } else if (field.input_type == "ENTITY") {
        await this.$store.dispatch("entities/fetchEntityByIdForEntityField", {
          entity_id: field.entity_id,
        });
        if (this.getEntityById) {
          await this.$store.dispatch("entities/fetchEntityRecordsForTable", {
            entity_id: field.entity_id,
          });
          if (
            this.getEntityRecordsForTable &&
            this.getEntityRecordsForTable.data &&
            this.getEntityRecordsForTable.data.length
          ) {
            this.getEntityRecordsForTable.data.forEach((record) => {
              if (
                record.entityData[
                  this.getEntityById.primaryFields[0].template_id
                ][this.getEntityById.primaryFields[0].key] !== undefined
              ) {
                this.bulkUpdateOptions.push({
                  label:
                    record.entityData[
                      this.getEntityById.primaryFields[0].template_id
                    ][this.getEntityById.primaryFields[0].key],
                  value: `${
                    record.entityData[
                      this.getEntityById.primaryFields[0].template_id
                    ][this.getEntityById.primaryFields[0].key]
                  }#${record._id}`,
                });
              }
            });
          }
        }
      } else if (field.input_type == "CHECKBOX") {
        this.bulkUpdateOptions = [
          ...[
            { label: "Checked", value: true },
            { label: "Unchecked", value: false },
          ],
        ];
      }
      this.optionsLoading = false;
    },
    async bulkUpdate() {
      let selectedIds = this.selectedRowsIds.map((row) => {
        return row._id;
      });
      let params = {
        field: this.bulkUpdateField,
        value: this.bulkUpdateValue,
        ids: selectedIds,
        entity_id: this.entity_id,
      };
      await this.$store.dispatch("entities/bulkUpdateEntityData", params);
      this.bulkUpdateDialgVisible = false;
      if (this.getBulkUpdateStatus) {
        this.fetchEntitiesDataForTable();
        this.$notify.success({
          title: "Success",
          message: "Your data updated successfully",
        });
      } else {
        this.$notify.error({
          title: "Error",
          message: "Error in updating  the data",
        });
      }
      this.bulkUpdateOptions = [];
      this.bulkUpdateValue = "";
      this.bulkUpdateField = "";
      this.bulkUpdateFields = [];
    },
    closeBulkUpdateDialogue() {
      this.bulkUpdateOptions = [];
      this.bulkUpdateValue = "";
      this.bulkUpdateField = "";
      this.bulkUpdateFields = [];
      this.bulkUpdateDialgVisible = false;
    },
    openCountDialog(data, step) {
      this.countComponent = null;
      this.countComponentFilters = [];
      this.countComponentFilters.push({
        field: this.entityFiltersData.tableFieldGroupBy,
        operator: "in",
        value: [data._id],
        value_type: "",
        value_source: "",
        data_type: "STRING",
        query_type: "AND",
        value_field: "",
        validations: {
          data_type: "",
        },
        data_source: "field_option",
      });
      if (
        this.entityFiltersData.tableFieldSubGroupBy &&
        step &&
        step.includes("?")
      ) {
        this.countComponentFilters.push({
          field: this.entityFiltersData.tableFieldSubGroupBy,
          operator: "in",
          value: [step.split("?")[0]],
          value_type: "",
          value_source: "",
          data_type: "STRING",
          query_type: "AND",
          value_field: "",
          validations: {
            data_type: "",
          },
          data_source: "field_option",
        });
      }
      if (this.applied_filters && this.applied_filters.length) {
        this.countComponentFilters = [
          ...this.countComponentFilters,
          ...this.applied_filters,
        ];
      }
      this.countComponent = {
        component_name: this.currentEntity?.name,
        entity_id: this.currentEntity._id,
      };
      this.showCountDialog = true;
    },
    getCurrencyFormat(value, key) {
      let field = this.getField(key);
      let label = "";
      if (field?.inputType == "CURRENCY") {
        let type = field.validations.currency
          ? field.validations.currency
          : "USD";
        let locale = field.validations.locale
          ? field.validations.locale
          : "en-US";
        label = new Intl.NumberFormat(locale, {
          style: "currency",
          currency: type,
        }).format(value);
      } else if (
        field?.inputType == "AGGREGATE_FUNCTION" ||
        field?.inputType == "FORMULA"
      ) {
        let isCurrencyField = (field.selected_fields || []).find((el) => {
          if (
            el?.inputType == "CURRENCY" ||
            el?.input_type == "CURRENCY" ||
            el?.result_type == "CURRENCY"
          ) {
            return true;
          }
        });
        if (
          !isCurrencyField &&
          field?.inputType == "FORMULA" &&
          field.result_type == "CURRENCY" &&
          field.selected_fields?.[0]?.validations?.currency
        ) {
          let locale = field.validations?.locale || "en-US";
          return (label = new Intl.NumberFormat(locale, {
            style: "currency",
            currency: field.selected_fields[0].validations.currency,
          }).format(value));
        }
        if (isCurrencyField) {
          if (field?.inputType == "AGGREGATE_FUNCTION") {
            let dataTableField = this.getField(
              field.template_id + "#" + isCurrencyField.data_table_key
            );
            if (dataTableField) {
              let rField = (dataTableField.data_table_columns || []).find(
                (e) => e.key == isCurrencyField.key
              );
              if (rField?.validations) {
                isCurrencyField["validations"] = rField.validations;
              }
            }
          } else {
            if (field?.validations) {
              isCurrencyField["validations"] = field.validations;
            }
          }
          let type =
            isCurrencyField &&
            isCurrencyField.validations &&
            isCurrencyField.validations.currency
              ? isCurrencyField.validations.currency
              : "USD";
          let locale =
            isCurrencyField &&
            isCurrencyField.validations &&
            isCurrencyField.validations.locale
              ? isCurrencyField.validations.locale
              : "en-US";
          label = new Intl.NumberFormat(locale, {
            style: "currency",
            currency: type,
          }).format(value);
        } else {
          label = value;
        }
      } else {
        label = value;
      }
      return label;
    },
    getLabel(field) {
      let found = this.getField(field);
      return found && found.label ? found.label : "";
    },
    getCalendarMessage(data, body) {
      let str = "";
      if (data && data.entityData && body?.custom_message?.content) {
        (body?.custom_message?.content || []).forEach((el) => {
          (el?.content || []).forEach((e) => {
            if (e?.type == "text") {
              str = str + e.text;
            } else if (
              e?.type == "mention" &&
              e?.attrs?.id &&
              e.attrs.id.includes("#")
            ) {
              let [slug, key] = e?.attrs?.id.split("#");
              let templates = (this.currentEntity.templates || []).map(
                (temp) => temp.templateInfo
              );
              let temp = (templates || []).find((e) => e.slug == slug);
              if (
                temp?._id &&
                data.entityData[temp._id] &&
                data.entityData[temp._id][key] &&
                temp?.sections[0]?.fields
              ) {
                let field = temp.sections[0].fields.find((e) => e.key == key);
                if (data.entityData[temp._id][key + "/name"]) {
                  str = str + data.entityData[temp._id][key + "/name"];
                } else if (field.inputType == "CURRENCY") {
                  str =
                    str +
                    Intl.NumberFormat(this.getCurrencyFormet(field).code, {
                      style: "currency",
                      currency: this.getCurrencyFormet(field).currency,
                    }).format(data.entityData[temp._id][key]);
                } else {
                  str = str + data.entityData[temp._id][key];
                }
              }
            }
          });
        });
      }
      return str;
    },
    async openSendNotification(entityData) {
      this.selectedNotifyEntity = entityData;
      this.sendNotificationDialog = true;
    },
    async openBulkSendNotification() {
      this.selectedIds = this.selectedRowsIds.map((row) => {
        return row._id;
      });
      this.bulkSendNotificationDialog = true;
    },
    generateShowFields(entity) {
      if (entity?.templates) {
        if (entity?.entity_type == "INDIVIDUAL") {
          let standardTemp = entity?.templates.find(
            (e) => e && e?.templateInfo?.type == "STANDARD"
          );
          this.showFieldsParent.profile = "defaultpic";
          if (standardTemp?.template_id) {
            this.showFieldsParent.title =
              standardTemp.template_id + "#" + "name";
            this.showFieldsParent.description =
              standardTemp.template_id + "#" + "title";
          }
        } else {
          entity?.templates.forEach((temp) => {
            if (temp?.templateInfo && !temp?.is_repeatable) {
              if (!this.showFieldsParent.profile) {
                let field = (
                  this.getTemplateFields(temp.templateInfo) || []
                ).find((el) => el.inputType == "IMAGE");
                if (field?.key) {
                  this.showFieldsParent.profile = field.key;
                }
              }
              if (!this.showFieldsParent.description) {
                let field = (
                  this.getTemplateFields(temp.templateInfo) || []
                ).find((el) => el.inputType == "MULTI_LINE_TEXT");
                if (field?.key) {
                  this.showFieldsParent.description = field.key;
                }
              }
            }
          });
          if (
            !this.showFieldsParent.title &&
            entity.primaryFields &&
            entity.primaryFields[0]
          ) {
            this.showFieldsParent.title =
              entity.primaryFields[0].template_id +
              "#" +
              entity.primaryFields[0].key;
          }
        }
        entity?.templates.forEach((temp) => {
          if (!this.showFieldsParent.chart_field) {
            let field = (this.getTemplateFields(temp.templateInfo) || []).find(
              (el) =>
                ["SELECT", "YES_OR_NO", "MULTI_SELECT"].indexOf(el.inputType) !=
                -1
            );
            if (field?.key) {
              this.showFieldsParent.chart_field = field.key;
            }
          }
          if (!this.showFieldsParent.board_field) {
            let field = (this.getTemplateFields(temp.templateInfo) || []).find(
              (el) =>
                ["SELECT", "YES_OR_NO", "MULTI_SELECT"].indexOf(el.inputType) !=
                -1
            );
            if (field?.key) {
              this.showFieldsParent.board_field = field.key;
            }
          }
        });
      }
    },
    getEntityFieldsByType(types) {
      let results = [];
      (this.currentEntity && this.currentEntity.templates
        ? this.currentEntity.templates
        : []
      ).forEach((temp) => {
        if (temp?.templateInfo?.sections && !temp.is_repeatable) {
          if (types && types.length) {
            results = [
              ...results,
              ...temp.templateInfo.sections[0].fields
                .filter((e) => types.indexOf(e.inputType) != -1)
                .map((el) => {
                  let key = el.key;
                  if (key && !key.includes("#")) {
                    key = temp.template_id + "#" + key;
                  }
                  el.key = key;
                  return el;
                }),
            ];
          } else {
            results = [
              ...results,
              ...temp.templateInfo.sections[0].fields.map((el) => {
                let key = el.key;
                if (key && !key.includes("#")) {
                  key = temp.template_id + "#" + key;
                }
                el.key = key;
                return el;
              }),
            ];
          }
        }
      });
      if (
        types &&
        types.includes("IMAGE") &&
        this.currentEntity?.entity_type == "INDIVIDUAL"
      ) {
        results.push({
          label: "Default picture",
          key: "defaultpic",
        });
      }
      return results;
    },
    async checkForFilterUpdate() {
      this.entityViewModal = false;
      this.filterColumList = this.filterColumList.filter(
        (e) => e.id && e.template_id
      );
      if (this.isApplicationUserSide) {
        let data = this.getEntityShowFields || {};
        if (data) {
          data[this.getEntityDataById._id] = { ...this.showFieldsParent };
        }
        await this.$store.commit("entities/setEntityShowFields", data, {
          root: true,
        });
      } else if (this.currentFilter) {
        await this.$store.dispatch("filters/updateFilter", {
          id: this.currentFilter,
          params: {
            columns_settings: this.filterColumList,
          },
        });
      } else {
        await this.$store.dispatch("entities/updateEntityById", {
          settings: this.filterColumList,
          name: this.getEntityDataById.name,
          description: this.getEntityDataById.description,
          entity_type: this.getEntityDataById.entity_type,
          templates: this.getEntityDataById.templates,
          id: this.getEntityDataById._id,
          primaryFields: this.getEntityDataById.primaryFields,
          views_configuration: this.showFieldsParent,
          viewType: this.activeLayout,
        });
      }
    },
    selectAlltemplates(temp) {
      let fields = this.getTemplateFields(temp.templateInfo);
      if (fields && fields.length) {
        fields.forEach((field) => {
          if (field) {
            let key;
            if (field?.key && field.key.includes("#")) {
              key = field.key.split("#")[1];
            }
            let index = this.filterColumList.findIndex(
              (e) => e.id == key && e.template_id == field.template_id
            );
            if (index == -1) {
              this.filterColumList.push({
                id: key,
                label: field.label,
                template_id: field.template_id,
                type: field.inputType,
              });
            }
          }
        });
      }
    },
    checkfieldVisible(data, template_id) {
      let field = { ...data, ...{ template_id: template_id } };
      if (field && field.key) {
        let key, found;
        if (field.key.includes("#")) {
          key = field.key.split("#")[1];
        } else {
          key = field.key;
        }
        if (this.getFilterColumList && this.getFilterColumList.length) {
          found = this.getFilterColumList.find((e) => {
            if (e.id && e.id.includes("#")) {
              e.id = e.id.split("#")[1];
            }
            if (e.id == key && e.template_id == field.template_id) {
              return true;
            }
          });
        }
        return found ? true : false;
      }
      return false;
    },
    selectField(data, template_id) {
      let field = { ...data, ...{ template_id: template_id } };
      let existed = this.checkfieldVisible(field, template_id);
      let key;
      if (field.key.includes("#")) {
        key = field.key.split("#")[1];
      } else {
        key = field.key;
      }
      if (existed) {
        let index = this.filterColumList.findIndex(
          (e) => e.id == key && e.template_id == field.template_id
        );
        if (index > -1) {
          this.filterColumList.splice(index, 1);
        }
      } else {
        this.filterColumList.push({
          id: key,
          label: field.label,
          template_id: field.template_id,
          type: field.inputType,
        });
      }
    },
    async activeLayout(layout) {
      if (this.activeEntityView !== layout) {
        try {
          this.activeEntityView = layout;

          let query = await this.getNavigationQuery(this.$route.query);
          query["viewType"] = layout;

          await this.$router.push({
            name: this.$route.name,
            query: query,
          });
          let entity = this.getEntityDataById;
          let params = {
            id: entity._id,
            name: entity.name,
            description: entity.description,
            entity_type: entity.entity_type,
            templates: entity.templates,
            primaryFields: entity.primaryFields,
            settings: entity.settings,
            viewType: layout,
          };
          await this.$store.dispatch("entities/updateEntityById", params);
        } catch (error) {
          console.error("Error updating layout:", error);
        }
      }
    },
    openViewConfigPage() {
      this.entityViewModal = true;
    },
    searchEntityData() {
      if (this.activeEntityView == "TABLE") {
        this.fetchEntitiesDataForTable([]);
      }
    },
    changeEntityView(view) {
      this.activeEntityView = view;
      this.$router.push({
        path: this.$route.path,
        query: { ...this.$route?.query, ...{ viewType: view } },
      });
    },
    //entity data edit code in import
    getPropValue(step) {
      if (step.type == "ENTITY") {
        return step.template_id + "#" + step.id + "/name";
      }
      return step.template_id + "#" + step.id;
    },
    checkFieldDisabled(data) {
      let existedField = this.mapped_fields.find(
        (e) =>
          e.template_id == data.template_id &&
          e.template_filed_id == e.template_filed_id &&
          e.keyIndex != data.keyIndex
      );
      if (existedField) {
        return true;
      }
      return false;
    },
    downloadExcelFromJson(csvData, fileName) {
      const worksheet = XLSX.utils.json_to_sheet(csvData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);

      document.body.appendChild(link);
      link.click();

      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }, 0);
    },

    downloadExcelFile() {
      let templateFields = [];
      if (this.current_entity_tempates) {
        this.current_entity_tempates.forEach((temp) => {
          if (temp && temp.is_repeatable === false) {
            if (temp.sections && temp.sections[0] && temp.sections[0].fields) {
              temp.sections[0].fields.forEach((fi) => {
                templateFields.push(fi.label);
              });
            }
          }
        });
      }
      this.gotoDownload(templateFields);
    },
    gotoDownload(data) {
      const headers = data;
      const worksheet = XLSX.utils.json_to_sheet([], { header: headers });
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = this.currentEntity.name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      // const a = document.createElement("a");
      // a.setAttribute("hidden", "");
      // a.setAttribute("href", url);
      // a.setAttribute("download", `filename.xlsx`);
      // document.body.appendChild(a);
      // a.click();
      // document.body.removeChild(a);
    },
    clearImportData() {
      this.mapped_fields = [];
      this.unmapped_fields = [];
      this.getmappedFieldsData = [];
      this.uploadedFileObject = null;
      this.is_excelUploaded = false;
      this.is_active = 0;
      this.mappedFileName = "";
      this.existedField = "";
      this.matchDialogVisible = true;
    },
    async displayimportdialog() {
      let params = {
        id: this.entity_id,
      };
      this.loading = true;
      await this.$store.dispatch("entities/getuploadedFiles", params);
      if (this.getuploadedFilesData && this.getuploadedFilesData.data) {
        this.uploadedfileDataNames = this.getuploadedFilesData.data;
      }
      this.clearImportData();
      this.loading = false;
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    editMappedFileds() {
      this.is_active = this.is_active - 1;
      this.importedData = this.mappedData;
    },
    updateField(selectedField) {
      if (selectedField.template_id && selectedField.template_filed_id) {
        let index = this.unmapped_fields.findIndex(
          (e) => e.keyIndex === selectedField.keyIndex
        );
        let item = this.unmapped_fields[index];
        this.mapped_fields.push(item);
        this.unmapped_fields.splice(index, 1);
      }
    },
    updateIgnoredRow(checkedField) {
      if (checkedField.ignore === true) {
        let index = this.mapped_fields.findIndex(
          (e) => e.keyIndex === checkedField.keyIndex
        );
        let item = this.mapped_fields[index];
        item.template_id = "";
        item.template_filed_id = "";
        this.unmapped_fields.push(item);
        this.mapped_fields.splice(index, 1);
      }
      // if(checkedField.ignore===true){

      //   let item=this.mapped_fields[index];
      //   item.ignore=true;
      //   item.template_filed_id="";
      //   item.template_id="";
      //   this.unmapped_fields.push(item);
      //   this.mapped_fields.splice(index,1);
      // }
    },
    async downloadGroupbyData() {
      let filters = this.applied_filters;
      if (
        this.currentMenu?.entity_type == "RELATIONAL_ENTITY" &&
        this.isApplicationUserSide
      ) {
        filters = [...filters, ...this.getSelfEntityFilters];
      }
      if (!this.entityFiltersData && this.$route.query.filter) {
        this.resetEntityFiltersData();
      }
      filters = [...this.entityFiltersData.filters, ...filters];
      this.exportFilters = filters;
      let checkDataTable = this.filterColumList.find(
        (e) => e.type == "DATA_TABLE"
      );
      let params = {
        entity_id: this.entity_id,
        template_fields_data: (this.currentEntity &&
        this.currentEntity.templates
          ? this.currentEntity.templates
          : []
        ).map((template) => {
          return {
            template_id: template.template_id,
          };
        }),
        filters: this.mapDateFilters(
          this.addGlobalFilters(
            filters || [],
            this.entity_id,
            this.checkGlobalFilter
          )
        ),
        sortBy: this.sortBy,
        sortOrder: this.sortOrder,
        search_string: this.search_string,
        data_table_field: checkDataTable,
      };
      if (this.entityFiltersData?.tableFieldGroupBy) {
        let subField = this.getField(this.entityFiltersData.tableFieldGroupBy);
        if (subField?.inputType == "MULTI_SELECT") {
          params.unwind = true;
        }
        params.group_by = this.entityFiltersData.tableFieldGroupBy;
        params.group_by_fields = this.entityFiltersData.tableFieldGroupByFields;
        if (this.entityFiltersData?.tableFieldSubGroupBy) {
          params.sub_group_by = this.entityFiltersData?.tableFieldSubGroupBy;
        } else {
          params.entity_variable_columns =
            this.entityFiltersData.filterEntityVariableColumns;
          if (
            this.getField(this.entityFiltersData.tableFieldGroupBy)?.entity_id
          ) {
            params.filter_variable_entity = this.getField(
              this.entityFiltersData.tableFieldGroupBy
            ).entity_id;
          }
        }
      }
      await this.$store.dispatch("entities/fetchEntityRecordsForTable", params);
      let groupedData = [],
        csvData = [];
      if (this.entityFiltersData?.tableFieldGroupBy) {
        if (this.entityFiltersData?.tableFieldSubGroupBy) {
          let custom_columns = [];
          groupedData = this.getEntityRecordsForTable.data.map((data) => {
            if (data?.properties) {
              let label = "",
                total = 0;
              (data.properties || []).forEach((el) => {
                if (!label) {
                  label = el.entity_label;
                }
                total = total + el.count;
                let value = el.sub_entity_label
                  ? el.sub_entity_label
                  : el.label;
                let index = custom_columns.findIndex(
                  (e) => e.value == el.label
                );
                if (index == -1 && value) {
                  custom_columns.push({
                    value: el.label,
                    label: value,
                  });
                }
                (Object.keys(el) || []).forEach((key) => {
                  if (
                    ["entity_label", "label", "sub_entity_label"].indexOf(
                      key
                    ) == -1
                  ) {
                    data[el.label + "?" + key] = el[key];
                    data[value + "?" + key] = el[key];
                  }
                });
              });

              data.entity_label = label;
              data.count = total;
            }
            return data;
          });
          if (
            this.entityFiltersData?.table_field_group_by_selected_options &&
            this.entityFiltersData.table_field_group_by_selected_options.length
          ) {
            custom_columns = custom_columns.filter(
              (e) =>
                this.entityFiltersData.table_field_group_by_selected_options.indexOf(
                  e.value
                ) != -1
            );
          }
          this.entityFiltersData.tableFieldGroupByFields
            .filter((e) => e.sub_group)
            .forEach((field) => {
              (custom_columns || []).forEach((col) => {
                this.custom_columns.push({
                  label: col.label + "-" + field.name,
                  value: col.value + "?" + field.key,
                });
              });
            });
        } else {
          groupedData = this.getEntityRecordsForTable.data;
          groupedData = this.groupedData.map((e) => {
            if (e && e.name && e.name.includes("#")) {
              e.name = e.name.split("#")[0];
            }
            return e;
          });
        }
      }
      groupedData.forEach((data) => {
        let obj = {};
        obj[this.getMainLabel] = data.entity_label
          ? data.entity_label
          : data._id;
        this.entityFiltersData.tableFieldGroupByFields
          .filter((e) => !e.sub_group)
          .forEach((step) => {
            obj[step.name] = data[step.key];
          });
        this.custom_columns.forEach((step) => {
          obj[step.label] = this.getSubField(data, step.value);
        });
        csvData.push(obj);
      });
      this.downloadExcelFromJson(csvData, "ExcelData");
    },
    async exportCSV() {
      if (this.entityFiltersData.tableFieldGroupBy) {
        await this.downloadGroupbyData();
        return;
      }
      let params = {
        id: this.entity_id,
        entityName: this.currentEntity.name,
        filters: this.mapDateFilters(this.exportFilters || []),
        dataIds: this.exportDataIds,
        selectedFilter: this.currentFilter,
      };
      this.loadingText = "Generating Excel...";
      this.loading = true;
      await this.$store.dispatch("entities/entityDataExport", params);
      this.loading = false;
      this.loadingText = "Loading...";
    },
    async exportPDF() {
      let params = {
        id: this.entity_id,
        entityName: this.currentEntity.name,
        filters: this.mapDateFilters(this.exportFilters || []),
        selectedFilter: this.currentFilter,
        dataIds: this.exportDataIds,
        document_id: this.currentEntity?.export_printing_document,
      };
      this.loading = true;
      this.loadingText = "Generating PDF...";
      await this.$store.dispatch("entities/entityDataExportPDF", params);
      if (
        this.getEntityDataExportPDFStatus &&
        this.getEntityDataExportPDFStatus.success &&
        this.getEntityDataExportPDFStatus.data.length
      ) {
        await this.downloadEntityDataPdf(
          this.getEntityDataExportPDFStatus.data[0],
          this.currentEntity?.name
        );
      } else {
        this.$notify.error({
          title: "Error",
          message: "Error while generating Document",
        });
      }
      this.loading = false;
      this.loadingText = "Loading...";
    },
    async prepareImportEntityHeaders() {
      //eliminating repeatable templates
      this.entityTempsWithException = [];
      this.current_entity_tempates.forEach((template) => {
        if (template && template.is_repeatable === false) {
          this.entityTempsWithException.push(template);
        }
      });
      let nextBtn = document.getElementById("prepareImportEntityNext");
      nextBtn.style.color = "black";
      if (this.is_excelUploaded === true) {
        this.dialogLoading = true;
        if (this.mappedFileName.length > 0 || this.existedField.length > 0) {
          this.uploadedFileObject.name =
            this.uploadedFileObject.name.includes(".") &&
            this.uploadedFileObject.name.split(".")[0]
              ? this.uploadedFileObject.name.split(".")[0]
              : this.uploadedFileObject.name;
          // var formData = new FormData();
          // formData.append(
          //   "file",
          //   this.uploadedFileObject.raw !== undefined
          //     ? this.uploadedFileObject.raw
          //     : this.uploadedFileObject
          // );
          // let params = {
          //   id: this.entity_id,
          //   data: formData,
          //   fileName: this.mappedFileName
          //     ? this.mappedFileName
          //     : this.existedField,
          // };
          // await this.$store.dispatch("entities/storeUploadedFile", params);
          let params = {
            id: this.entity_id,
            name: this.mappedFileName,
            mappedFileId: this.existedField,
            excelHeaders: this.excelFileHeaders,
          };
          await this.$store.dispatch("entities/uploadCSVfile", params);
          if (
            this.getUploadCSVfileStatus &&
            this.getUploadCSVfileStatus.data &&
            this.getUploadCSVfileStatus.data.mapped_fields
          ) {
            this.is_active++;
            this.dialogLoading = false;
            let mappingFields = this.getUploadCSVfileStatus.data.mapped_fields;
            mappingFields.forEach((e) => {
              let keyIndex = Math.random().toString(36).substring(1, 9);
              e.keyIndex = keyIndex;
            });
            mappingFields.forEach((field) => {
              if (
                field &&
                field.template_filed_id &&
                field.template_id &&
                field.ignore === false
              ) {
                this.mapped_fields.push(field);
              } else {
                this.unmapped_fields.push(field);
              }
              // }
            });
            this.mapped_fields.forEach((f) => {
              let duplicate = this.unmapped_fields.find((e) => {
                if (e.excel_index == f.excel_index) {
                  return true;
                }
              });
              if (duplicate) {
                this.mapped_fields.splice(this.mapped_fields.indexOf(f), 1);
              }
            });
            this.mapped_fields.forEach((field) => {
              let duplicate = this.mapped_fields.find((e) => {
                if (
                  e.excel_index == field.excel_index &&
                  e.excel_field !== field.excel_field
                )
                  return true;
              });
              if (
                duplicate &&
                !this.uploadedfileDataNames[0].mapped_fields.includes(field)
              ) {
                this.mapped_fields.splice(this.mapped_fields.indexOf(field), 1);
              }
            });
          } else {
            this.dialogLoading = true;
          }
        } else {
          this.$notify({
            title: "Error",
            message: "give the name for a file or select a file.",
            type: "error",
          });
          this.dialogLoading = false;
        }
      } else {
        this.$notify({
          title: "Error",
          message: "Please upload the file.",
          type: "error",
        });
      }
    },
    uploadCSVfile(file) {
      this.uploadedFileObject = file;
      let fileNameArray = this.uploadedFileObject.name.split(".");
      fileNameArray.splice(fileNameArray.length - 1, 1);
      let fileName = fileNameArray.join(".");
      if (this.uploadedfileDataNames.length > 0) {
        let foundObject = this.uploadedfileDataNames.find((e) => {
          if (e.name.toLowerCase() == fileName.toLowerCase()) {
            return true;
          }
        });
        if (foundObject && foundObject._id) {
          this.existedField = foundObject._id;
        } else {
          this.mappedFileName = fileName;
        }
      } else {
        this.mappedFileName = fileName;
      }
      this.is_excelUploaded = true;
      this.handleFileUpload(this.uploadedFileObject);
    },
    handleFileUpload(fileData) {
      const file = fileData.raw;
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = Buffer.from(e.target.result);
        const workbook = XLSX.read(data, { type: "array", cellDates: true });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        this.jsonDataOfExcel = this.convertDataToJSON(jsonData);
        this.jsonDataOfExcel.map((e) => {
          Object.keys(e).map((f) => {
            if (moment.isDate(e[f])) {
              e[f] = moment(e[f]).add(10, "seconds").toISOString();
            }
          });
        });
      };
      reader.onerror = (e) => {
        console.error("File reading error:", e);
      };
      reader.readAsArrayBuffer(file);
    },
    convertDataToJSON(data) {
      const headers = data[0];
      this.excelFileHeaders = headers;
      const jsonData = [];
      for (let i = 1; i < data.length; i++) {
        const row = data[i];
        let item = {};
        let allKeys = [];
        for (let j = 0; j < headers.length; j++) {
          const value = row[j] !== null ? row[j] : "";
          let itemKeys = Object.keys(item);
          allKeys.push(headers[j]);
          if (
            itemKeys &&
            itemKeys.length > 0 &&
            itemKeys.includes(headers[j])
          ) {
            let count =
              allKeys.filter((element) => element === headers[j]).length - 1;
            headers[j] = headers[j] + "_" + count;
            item[headers[j]] = value;
          } else {
            item[headers[j]] = value;
          }
        }
        jsonData.push(item);
      }
      return jsonData;
    },
    async gotoDataPreviewScreen() {
      // let primaryFields =
      let nextBtn = document.getElementById("gotoDataPreviewScreenNext");
      nextBtn.style.color = "black";
      let unmappedFields = (this.currentEntity.primaryFields || []).filter(
        (e) => {
          let mapped = this.mapped_fields.find((fl) => {
            let key =
              fl?.template_filed_id &&
              fl.template_filed_id.includes("#") &&
              fl.template_filed_id.split("#")[1]
                ? fl.template_filed_id.split("#")[1]
                : fl.template_filed_id;
            if (key == e.key && fl.template_id == e.template_id) {
              return true;
            }
          });
          if (!mapped) {
            return true;
          }
        }
      );
      let allMappedFields = [];
      this.mapped_fields.forEach((e) => {
        allMappedFields.push(e.template_filed_id);
      });
      let allUnmappedFields = [];
      unmappedFields.forEach((e) => {
        allUnmappedFields.push(e.key);
      });
      let requiredFields = [];
      this.currentEntity.templates.map((e) => {
        let req = e.templateInfo.sections[0].fields.filter(
          (f) => f && f.validations && f.validations.required
        );
        requiredFields = [...requiredFields, ...req];
      });
      if (allMappedFields && allMappedFields.length) {
        let requiredFieldsMapped = requiredFields
          .map((e) => e.key)
          .every((e) => allMappedFields.includes(e));
        if (requiredFieldsMapped == false) {
          this.$notify({
            title: "Error",
            message: "Please map all the required fields",
            type: "error",
          });
          return;
        }
      }
      if (this.currentEntity.entity_type == "INDIVIDUAL") {
        let firstLastNameMapped = ["first_name", "last_name"].every((field) =>
          allMappedFields.includes(field)
        );
        if (!allMappedFields.includes("name") && !firstLastNameMapped) {
          this.$notify.error({
            title: "Error",
            message: "First name and Last name Or Name should be mapped",
          });
          return;
        }
        if (!allMappedFields.includes("email")) {
          this.$notify.error({
            title: "Error",
            message: "Email should be mapped",
          });
          return;
        }
      }
      if (unmappedFields && unmappedFields.length) {
        if (
          unmappedFields.length === 1 &&
          unmappedFields[0].key === "name" &&
          allMappedFields.includes("first_name") &&
          allMappedFields.includes("last_name")
        ) {
          /* unmappedFields */
        } else if (
          (unmappedFields.length === 1 || unmappedFields.length === 2) &&
          (allUnmappedFields.includes("first_name") ||
            allUnmappedFields.includes("last_name")) &&
          allMappedFields.includes("name")
        ) {
          /* unmappedFields */
        } else {
          let keys = unmappedFields.flatMap((e) => e.label).join(",");
          this.$notify({
            title: "Error",
            message: `Primary fields must be mapped  (${keys})`,
            type: "error",
          });
          return;
        }
      }
      if (this.unmapped_fields.length > 0) {
        this.unmapped_dialog = true;
      } else {
        this.directToPreviewScreen();
      }
    },
    displayUnmappedDialog() {
      this.unmapped_dialog = false;
      this.directToPreviewScreen();
    },
    async directToPreviewScreen() {
      this.dialogLoading = true;
      this.unmapped_fields.forEach((e) => {
        e.ignore = true;
      });
      let params = {
        id: this.getUploadCSVfileStatus.excelFile_id,
        mapped_fields: this.mapped_fields,
      };
      await this.$store.dispatch("entities/updatemappedFields", params);
      if (this.getupdateMappedFieldStatus) {
        this.$notify.success({
          title: this.getupdateMappedFieldStatus.status,
          message: this.getupdateMappedFieldStatus.message,
        });
      } else {
        this.$notify.error({
          title: "Error",
          message: "Error in updating data",
        });
      }
      this.mappedData = this.jsonDataOfExcel;
      this.prepareEntityImportData(
        this.mappedData,
        this.getupdateMappedFieldStatus.data.mapped_fields
      );
      // if (this.getmappedFieldsData) {
      // }
      this.dialogLoading = false;
      this.is_active++;
    },
    checkFieldData(individualFieldValue, foundedField) {
      if (foundedField && individualFieldValue) {
        if (foundedField.inputType == "SELECT") {
          let individualFieldValueLower = individualFieldValue
            .toString()
            .toLowerCase();
          let selectArr = [];
          foundedField.options.forEach((e) => {
            e = e.trim();
            selectArr.push(e.toLowerCase());
          });
          let isExisted = selectArr.includes(individualFieldValueLower.trim());
          if (isExisted) {
            return {
              success: true,
              data: individualFieldValue,
            };
          } else {
            return {
              success: false,
              data: "",
              message: "Entered data not matched",
            };
          }
        } else if (foundedField && foundedField.inputType === "CURRENCY") {
          if (typeof individualFieldValue === "number") {
            return {
              success: true,
              data: individualFieldValue,
            };
          } else {
            return {
              success: false,
              data: "",
              message: "Entered data not matched",
            };
          }
        } else if (foundedField && foundedField.inputType === "MULTI_SELECT") {
          let resultArr = [];
          if (typeof individualFieldValue == "object") {
            if (individualFieldValue.length > -1) {
              resultArr = foundedField.options.filter((e) => {
                return (
                  individualFieldValue
                    .map((el) => el.toLowerCase())
                    .indexOf(e) != -1
                );
              });
            }
          } else {
            if (individualFieldValue.includes(",")) {
              let multiArr = individualFieldValue
                .split(",")
                .map((e) => e.toLowerCase());
              // let smallMultiArr = [];
              resultArr = foundedField.options.filter((op) => {
                if (op && multiArr.indexOf(op.toLowerCase()) != -1) {
                  return op;
                }
              });
            } else {
              let found = foundedField.options.find((e) => {
                if (
                  e &&
                  e.toLowerCase() == individualFieldValue.toLowerCase()
                ) {
                  return e;
                }
              });
              resultArr = found ? [found] : [];
            }
          }
          return {
            success: true,
            data: resultArr,
          };
        } else if (foundedField && foundedField.inputType === "CHECKBOX") {
          if (typeof individualFieldValue === "boolean") {
            return {
              success: true,
              data: individualFieldValue,
            };
          } else {
            return {
              success: false,
              data: false,
              message: "Entered data not matched",
            };
          }
        } else if (
          foundedField &&
          foundedField.inputType === "CHECKBOX_GROUP"
        ) {
          // let options = [];
          // foundedField.options.forEach((e) => {
          //   options.push(e.toLowerCase());
          // });
          let resultArr = [];
          if (typeof individualFieldValue == "object") {
            if (individualFieldValue.length > -1) {
              resultArr = foundedField.options.filter((e) => {
                return (
                  individualFieldValue
                    .map((el) => el.toLowerCase())
                    .indexOf(e) != -1
                );
              });
            }
          } else {
            if (individualFieldValue.includes(",")) {
              let inputArr = individualFieldValue
                .split(",")
                .map((e) => e.toLowerCase());
              resultArr = foundedField.options.filter((op) => {
                if (op && inputArr.indexOf(op.toLowerCase()) != -1) {
                  return op;
                }
              });
            } else {
              let found = foundedField.options.find((e) => {
                if (
                  e &&
                  e.toLowerCase() == individualFieldValue.toLowerCase()
                ) {
                  return e;
                }
              });
              resultArr = found ? [found] : [];
            }
          }
          // else {
          //   let isExisted = options.includes(
          //     individualFieldValue.toLowerCase()
          //   );
          //   if (isExisted) {
          //     resultantArr.push(individualFieldValue.toLowerCase());
          //   }
          // }
          if (
            resultArr.length >= foundedField.min_selection &&
            resultArr.length <= foundedField.max_selection
          ) {
            return {
              success: true,
              data: resultArr,
            };
          } else {
            resultArr = [];
            return {
              success: false,
              data: resultArr,
            };
          }
        } else if (foundedField && foundedField.inputType === "DATE") {
          var parsedDate = Date.parse(individualFieldValue);
          if (isNaN(parsedDate)) {
            return {
              success: false,
              data: "",
              message: "Entered data not matched",
            };
          } else {
            if (typeof individualFieldValue === "number") {
              const d = new Date(
                Math.round((individualFieldValue - 25569) * 86400 * 1000)
              );
              individualFieldValue = d;
            }
            return {
              success: true,
              data: individualFieldValue,
            };
          }
        }
        return {
          success: true,
          data: individualFieldValue,
        };
      }
      return {
        success: false,
        data: "",
        message: "Field is empty",
      };
    },
    async prepareEntityImportData(data, mapped_fields) {
      this.excelFileData = JSON.parse(JSON.stringify(data));
      let entity = this.currentEntity;
      //first part==>field level validations
      this.currentEntityAllFields = [];
      this.successedExcelImportData = [];
      this.errorExcelImportData = [];
      this.foundArray = [];
      if (entity && entity.templates) {
        entity.templates.forEach((temp) => {
          if (
            temp &&
            temp.templateInfo &&
            temp.templateInfo.sections[0] &&
            temp.templateInfo.sections[0].fields
          ) {
            temp.templateInfo.sections[0].fields.forEach((field) => {
              if (field && field.key) {
                if (field.key.includes("#")) {
                  let key = field.key.split("#")[1];
                  field.key = key;
                }
              }
              field = { ...field, ...{ template_id: temp.templateInfo._id } };
              this.currentEntityAllFields.push(field);
            });
          }
        });
      }
      let templatesData = [];
      this.foundArray = this.currentEntityAllFields.filter((e) => {
        let fieldPresent = mapped_fields.find((fie) => {
          if (
            e.key == fie.template_filed_id &&
            e.template_id == fie.template_id
          ) {
            return true;
          }
        });
        if (fieldPresent) {
          return true;
        }
      });
      await (data || []).forEach((details) => {
        let row = {};
        let fields = Object.keys(details);
        fields.forEach((field) => {
          let fieldPresent = mapped_fields.find((e) => {
            if (e.excel_field == field) {
              return true;
            }
          });
          if (fieldPresent) {
            if (row[fieldPresent.template_id]) {
              // let foundedField = this.currentEntityAllFields.find((fie) => {
              //   if (
              //     fie &&
              //     fie.key &&
              //     fie.template_id &&
              //     fie.key == fieldPresent.template_filed_id &&
              //     fie.template_id == fieldPresent.template_id
              //   ) {
              //     return true;
              //   }
              // });
              // let individualFieldValue = details[field];
              // if (foundedField) {
              //   let checkIsvalid = this.checkFieldData(
              //     individualFieldValue,
              //     foundedField
              //   );
              //   if (checkIsvalid && checkIsvalid.success) {
              //     details[field] = checkIsvalid.data;
              //   } else {
              //     details[field] = "";
              //   }
              // }
              row[fieldPresent.template_id] = {
                ...row[fieldPresent.template_id],
                ...{ [fieldPresent.template_filed_id]: details[field] },
              };
            } else {
              row[fieldPresent.template_id] = {
                [fieldPresent.template_filed_id]: details[field],
              };
            }
          }
        });
        templatesData.push(row);
      });
      //let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      this.foundArray.map((e) => {
        if (e.inputType == "TIME" || e.inputType == "FIXED_TIME") {
          templatesData.map((data) => {
            let date = moment(templatesData[templatesData.indexOf(data)][
                e.template_id.toString()
              ][e.key])
            if (
              date.isValid()
            ) {
              templatesData[templatesData.indexOf(data)][
                e.template_id.toString()
              ][e.key] = moment(
                templatesData[templatesData.indexOf(data)][
                  e.template_id.toString()
                ][e.key]
              ).add(-10, "seconds");
              templatesData[templatesData.indexOf(data)][
                e.template_id.toString()
              ][e.key] = moment(
                templatesData[templatesData.indexOf(data)][
                  e.template_id.toString()
                ][e.key]
              ).format("HH:mm:ss");
            }
          });
        } else 
        if (e.inputType == "SELECT") {
          templatesData.map((data) => {
            if (
              templatesData[templatesData.indexOf(data)][
                e.template_id.toString()
              ][e.key] !== undefined
            ) {
              templatesData[templatesData.indexOf(data)][
                e.template_id.toString()
              ][e.key] =
                templatesData[templatesData.indexOf(data)][
                  e.template_id.toString()
                ][e.key].toString();
            }
          });
        } else if (e.inputType == "MULTI_SELECT") {
          templatesData.map((data) => {
            if (
              data[e.template_id][e.key] &&
              data[e.template_id][e.key].toString().split(",").length
            ) {
              let multiSelectArray = templatesData[templatesData.indexOf(data)][
                e.template_id.toString()
              ][e.key]
                .toString()
                .split(",");
              multiSelectArray.forEach((e) => {
                multiSelectArray[multiSelectArray.indexOf(e)] = e.toString();
              });
              templatesData[templatesData.indexOf(data)][
                e.template_id.toString()
              ][e.key] = multiSelectArray;
            }
          });
        } else if (e.inputType == "FORMULA") {
          templatesData.map((data) => {
            data[e.template_id][e.key] = 0;
          });
        } else if (
          e.inputType == "NUMBER" &&
          e.input_type !== "ENTITY_VARIABLE"
        ) {
          templatesData.map((data) => {
            let value = parseInt(data[e.template_id][e.key]);
            if (!isNaN(value)) {
              data[e.template_id][e.key] = value;
            } else {
              data[e.template_id][e.key] = 0;
            }
          });
        }
      });
      this.preparedData = templatesData;
      //entity type validations
      // let standardTemp = "";
      // if (entity.entity_type === "INDIVIDUAL") {
      //   standardTemp = this.currentEntity.templates.find((temp) => {
      //     if (
      //       temp &&
      //       temp.templateInfo &&
      //       temp.templateInfo.type === "STANDARD"
      //     ) {
      //       return true;
      //     }
      //   });
      // }
      // await templatesData.forEach((rowData) => {
      //   if (entity.entity_type === "INDIVIDUAL") {
      //     if (rowData[standardTemp.template_id]) {
      //       if (
      //         ((Object.keys(rowData[standardTemp.template_id]).indexOf(
      //           "first_name"
      //         ) !== -1 &&
      //           Object.keys(rowData[standardTemp.template_id]).indexOf(
      //             "last_name"
      //           ) !== -1) ||
      //           Object.keys(rowData[standardTemp.template_id]).indexOf(
      //             "name"
      //           ) !== -1) &&
      //         Object.keys(rowData[standardTemp.template_id]).indexOf(
      //           "email"
      //         ) !== -1
      //       ) {
      //         if (
      //           rowData[standardTemp.template_id]["name"] &&
      //           typeof rowData[standardTemp.template_id]["name"] === "string"
      //         ) {
      //           rowData[standardTemp.template_id]["name"] =
      //             rowData[standardTemp.template_id]["name"].trim();
      //         }
      //         if (!rowData[standardTemp.template_id]["first_name"]) {
      //           if (!rowData[standardTemp.template_id]["name"]) {
      //             this.errorExcelImportData.push(rowData);
      //             return;
      //           }
      //         }
      //         if (!rowData[standardTemp.template_id]["last_name"]) {
      //           if (!rowData[standardTemp.template_id]["name"]) {
      //             this.errorExcelImportData.push(rowData);
      //             return;
      //           }
      //         }
      //         if(Object.keys(rowData[standardTemp.template_id]).indexOf('phone_number') !== -1){
      //           if(rowData[standardTemp.template_id]['phone_number'] !== ''){
      //             const phoneValidation = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/
      //             if( !phoneValidation.test(rowData[standardTemp.template_id]['phone_number'])){
      //               this.errorExcelImportData.push(rowData);
      //               return
      //             }
      //           }
      //         }
      //         if (rowData[standardTemp.template_id]["email"] !== "") {
      //           var mailformat =
      //             /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      //           if (
      //             rowData[standardTemp.template_id]["email"].match(
      //               mailformat
      //             ) === null
      //           ) {
      //             this.errorExcelImportData.push(rowData);
      //             return;
      //           }
      //         } else {
      //           this.errorExcelImportData.push(rowData);
      //           return;
      //         }
      //       } else {
      //         this.errorExcelImportData.push(rowData);
      //         return;
      //       }
      //     } else {
      //       //stabdard temp found avvakapothe
      //       this.errorExcelImportData.push(rowData);
      //       return;
      //     }
      //     this.successedExcelImportData.push(rowData);
      //   } else if (
      //     entity.entity_type === "GENERAL" ||
      //     entity.entity_type === "BUSINESS"
      //   ) {
      //     //need to write primary fields conditions
      //     let result = entity.primaryFields.every((pr) => {
      //       if (
      //         pr.template_id &&
      //         rowData[pr.template_id] &&
      //         rowData[pr.template_id][pr.key]
      //       ) {
      //         return true;
      //       }
      //     });
      //     if (result) {
      //       this.successedExcelImportData.push(rowData);
      //     } else {
      //       this.errorExcelImportData.push(rowData);
      //     }
      //   }
      // });
    },
    handleSizeChangeForIE(val) {
      this.pageSizeForIE = val;
    },
    handleCurrentChangeForIE(val) {
      this.currentPageForIE = val;
    },
    handleSizeChangeForIEDS(val) {
      this.pageSizeForIEDS = val;
    },
    handleCurrentChangeForIEDS(val) {
      this.currentPageForIEDS = val;
    },
    editSuccessedExcelImportData(row) {
      this.currentActiveSuccessRowIndex =
        this.successedExcelImportData.indexOf(row);
    },
    resetSuccessedExcelImportData() {
      let selectedRow =
        this.successedExcelImportData[this.currentActiveSuccessRowIndex];
      let isVaild = this.validateSelectedRow(selectedRow);
      if (isVaild && isVaild.success === false) {
        this.errorExcelImportData.push(selectedRow);
        this.successedExcelImportData.splice(
          this.currentActiveSuccessRowIndex,
          1
        );
      }
    },
    editErrorExcelImportData(row) {
      this.currentActiveErrorRowIndex = this.errorExcelImportData.indexOf(row);
    },
    async closeSendNotificationDialog(params) {
      this.emailConfigLoading = true;
      await this.$store.dispatch(
        "entities/sendNotificationToEntityDatausers",
        params
      );
      if (this.getSentEntityDataNotificationStatus) {
        this.emailConfigLoading = false;
        this.$notify({
          title: "Success",
          message: "Sending mail(s) initiated",
          type: "success",
        });
      } else {
        this.emailConfigLoading = false;
        this.$notify({
          title: "Error",
          message:
            this.getSentEntityNotificationStatusErrors ||
            "Error while sending mails",
          type: "error",
        });
      }
      this.paramsData = {};
      this.sendNotificationDialog = false;
    },
    async closeBulkSendNotificationDialog(params) {
      this.emailConfigLoading = true;
      if (params.attachments && params.attachments?.length) {
        this.loadingText = "Uploading Files";
        let files = params.attachments.map((e) => {
          return {
            name: e.filename,
            mimetype: e.type,
            data: e.content,
            size: e.size,
          };
        });
        let paramsData = {
          files: files,
          path: "email-attachments",
        };
        await this.$store.dispatch(
          "s3FileUpload/uploadMultipleBase64Files",
          paramsData
        );
        if (this.getFileUploadData && this.getFileUploadData.length) {
          params.attachments = this.getFileUploadData;
        }
      }
      await this.$store.dispatch("entities/sendNotificationToAllUsers", params);
      if (this.getSentEntityNotificationStatus) {
        this.emailConfigLoading = false;
        this.$notify({
          title: "Success",
          message: "Sending mails initiated",
          type: "success",
        });
      } else {
        this.emailConfigLoading = false;
        this.$notify({
          title: "Error",
          message: this.getSentEntityNotificationStatusErrors.message,
          type: "error",
        });
      }
      this.paramsData = {};
      this.emailConfigLoading = false;
      this.bulkSendNotificationDialog = false;
    },
    resetErrorExcelImportData() {
      let selectedRow =
        this.errorExcelImportData[this.currentActiveErrorRowIndex];
      let isValid = this.validateSelectedRow(selectedRow);
      if (isValid && isValid.success === true) {
        this.successedExcelImportData.push(selectedRow);
        this.errorExcelImportData.splice(this.currentActiveErrorRowIndex, 1);
      }
    },
    validateSelectedRow(rowData) {
      //1st part
      let entity = this.currentEntity;
      let standardTemp = "";
      if (entity.entity_type === "INDIVIDUAL") {
        standardTemp = entity.templates.find((temp) => {
          if (
            temp &&
            temp.templateInfo &&
            temp.templateInfo.type === "STANDARD"
          ) {
            return true;
          }
        });
      }
      //second part
      if (rowData) {
        if (entity.entity_type === "INDIVIDUAL") {
          if (rowData[standardTemp.template_id]) {
            // if(Object.keys(rowData[standardTemp.template_id]).indexOf('phone_number') !== -1){
            //   if(rowData[standardTemp.template_id]['phone_number'] !== ''){
            //     const phoneValidation = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/
            //     if(!phoneValidation.test(rowData[standardTemp.template_id]['phone_number'])){
            //       return{
            //         success : false
            //       }
            //     }
            //   }
            // }
            if (
              ((Object.keys(rowData[standardTemp.template_id]).indexOf(
                "first_name"
              ) !== -1 &&
                Object.keys(rowData[standardTemp.template_id]).indexOf(
                  "last_name"
                ) !== -1) ||
                Object.keys(rowData[standardTemp.template_id]).indexOf(
                  "name"
                ) !== -1) &&
              Object.keys(rowData[standardTemp.template_id]).indexOf(
                "email"
              ) !== -1
            ) {
              if (!rowData[standardTemp.template_id]["first_name"]) {
                if (!rowData[standardTemp.template_id]["name"]) {
                  return {
                    success: false,
                  };
                }
              }
              if (!rowData[standardTemp.template_id]["last_name"]) {
                if (!rowData[standardTemp.template_id]["name"]) {
                  return {
                    success: false,
                  };
                }
              }
              if (rowData[standardTemp.template_id]["email"] !== "") {
                var mailformat =
                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                if (
                  rowData[standardTemp.template_id]["email"].match(
                    mailformat
                  ) === null
                ) {
                  return {
                    success: false,
                  };
                }
              } else {
                return {
                  success: false,
                };
              }
            } else {
              return {
                success: false,
              };
            }
          } else {
            //stabdard temp found avvakapothe
            return {
              success: false,
            };
          }
          return {
            success: true,
          };
        } else if (
          entity.entity_type === "GENERAL" ||
          entity.entity_type === "BUSINESS"
        ) {
          //need to write primary fields conditions
          let result = entity.primaryFields.every((pr) => {
            if (
              pr.template_id &&
              rowData[pr.template_id] &&
              rowData[pr.template_id][pr.key]
            ) {
              return true;
            }
          });
          if (result) {
            return {
              success: true,
            };
          } else {
            return {
              success: false,
            };
          }
        }
      }
      //validate selected row
    },
    checkIsFieldSelected(field) {
      return this.mapped_fields.find(
        (e) =>
          e.template_id == field.template_id && e.template_filed_id == field.key
      )
        ? true
        : false;
    },
    getSelectedTemplate(template_id) {
      let contentFields = [
        "HEADING",
        "PARAGRAPH",
        "HTML_CONTENT",
        "SINGLE_LINE_CONTENT",
        "VIDEO",
        "ACTION_BUTTON",
        "HORIZONTAL_LINE",
        "DIVISION",
      ];
      if (template_id) {
        let selectedTemplate = this.entityTempsWithException.find(
          (e) => e._id == template_id
        );
        if (
          selectedTemplate &&
          selectedTemplate.sections &&
          selectedTemplate.sections[0] &&
          selectedTemplate.sections[0].fields
        ) {
          let filteredFields = selectedTemplate.sections[0].fields.filter(
            (field) => {
              if (
                field &&
                field.input_type &&
                contentFields.includes(field.input_type) === false
              ) {
                return true;
              } else {
                return false;
              }
            }
          );
          if (filteredFields) {
            return filteredFields.map((e) => {
              e.template_id = template_id;
              return e;
            });
          }
        }
        return [];
      }
      return [];
    },
    getSelectedTemplateName(row) {
      if (row.template_id) {
        let selectedField = this.mapped_fields.filter(
          (e) => e.keyIndex == row.keyIndex
        );
        selectedField.forEach((e) => {
          if (e?.template_filed_id && e.template_filed_id.includes("#")) {
            let splitted_one = e.template_filed_id.split("#");
            e.template_filed_id = splitted_one[1];
          } else {
            let templtate_filled_id = e.template_filed_id;
            e.template_filed_id = templtate_filled_id;
          }
        });
        return selectedField;
      }
    },

    getSelectedTemplatemappedData(row) {
      if (row.template_id) {
        let excelfield = row.excel_field;
        let individualArray = [];
        this.getmappedFieldsData.forEach((e) => {
          let objectKeys = Object.keys(e);
          let is_existed = objectKeys.includes(excelfield);
          if (is_existed) {
            individualArray.push(e[excelfield]);
          }
        });
        return individualArray;
      }
    },
    async importMappedData() {
      this.matchDialogVisible = false;
      // let excelFileName = this.getUploadCSVfileStatus.excelfilePath;
      // let params = {
      //   excelFileId: this.getUploadCSVfileStatus.excelFile_id,
      //   excelFilePath: excelFileName,
      // };
      let params = {
        data: this.successedExcelImportData,
        entityId: this.currentEntity._id,
        excelFileId: this.getUploadCSVfileStatus.excelFile_id,
        //excelFilePath: this.getUploadCSVfileStatus.excelfilePath,
      };
      this.mappingLoadingDialogVisible = true;
      this.loading = true;
      await this.$store.dispatch("entities/importMappedDataVersion", params);
      this.loading = false;
      if (this.getimportMappedDataStatusUpdatedVersion) {
        if (
          this.getimportMappedDataStatusUpdatedVersion.status &&
          this.getimportMappedDataStatusUpdatedVersion.message
        ) {
          this.$notify.success({
            title: this.getimportMappedDataStatusUpdatedVersion.status,
            message: this.getimportMappedDataStatusUpdatedVersion.message,
          });
          this.mappingLoadingDialogVisible = false;
          this.mappingStatusDialogVisible = true;
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error in importing data",
          });
        }
      } else {
        this.mappingLoadingDialogVisible = false;
        this.$notify.error({
          title: "Error",
          message:
            this.getimportMappedDataStatusUpdatedVersion.data.errors[0].message,
        });
      }
    },
    gotoDashboard() {
      this.mappingStatusDialogVisible = false;
      if (this.isApplicationUserSide) {
        window.location.reload();
      } else {
        this.$router.push("/entity");
      }
    },

    handleSelectionChange(checkedDocs) {
      this.selectedRowsIds = checkedDocs;
    },
    async deleteOncheck() {
      this.entitybulkDeleteVisible = true;
    },
    async deleteCheckedEntityData() {
      let selectedIds = [];
      this.selectedRowsIds.forEach((item) => {
        if (item && item._id) {
          selectedIds.push(item._id);
        }
      });
      this.count = selectedIds.length;
      let params = {
        selectedIds: selectedIds,
        entity_id: this.entity_id,
      };
      this.entitybulkDeleteVisible = false;
      this.loading = true;
      await this.$store.dispatch("entities/entitydataBulkDelete", params);
      if (
        this.getentitydataBulkDeleteStatus &&
        this.getentitydataBulkDeleteStatus.message
      ) {
        this.fetchEntitiesDataForTable();
        this.$notify.success({
          title: "Success",
          message: this.getentitydataBulkDeleteStatus.message,
        });
        this.loading = false;
      } else {
        let message = "Error in deleting data";
        if (this.getEntitydataBulkDeleteErrors) {
          message = this.getEntitydataBulkDeleteErrors;
        }
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: message,
        });
      }
    },

    checkPerimission(access) {
      if (this.isApplicationUserSide) {
        if (access == "BULK DELETE") {
          return false;
        } else if (
          this.getEntityPermission &&
          this.currentMenu &&
          (this.currentMenu.entity_id ||
            this.currentMenu.relational_entity ||
            this.currentMenu.nested_relational_entity)
        ) {
          if (access == "UPDATE") {
            access = "EDIT";
          }
          if (this.currentMenu.entity_id) {
            let entityId = this.currentMenu.entity_id._id
              ? this.currentMenu.entity_id._id
              : this.currentMenu.entity_id;
            if (
              this.currentMenu.entity_type == "ENTITY" &&
              this.getEntityPermission.other_entities &&
              entityId &&
              this.getEntityPermission.other_entities[entityId] &&
              this.getEntityPermission.other_entities[entityId].permissions &&
              this.getEntityPermission.other_entities[
                entityId
              ].permissions.indexOf(access) != -1
            ) {
              return true;
            }
          } else if (this.currentMenu.relational_entity) {
            let entityId = this.currentMenu.relational_entity._id
              ? this.currentMenu.relational_entity._id
              : this.currentMenu.relational_entity;
            if (
              entityId &&
              this.getEntityPermission.relational_entities[entityId] &&
              this.getEntityPermission.relational_entities[entityId]
                .permissions &&
              this.getEntityPermission.relational_entities[
                entityId
              ].permissions.indexOf(access) != -1
            ) {
              return true;
            }
          } else if (this.currentMenu?.nested_relational_entity) {
            if (
              this.getEntityPermission &&
              this.getEntityPermission.nested_relational_entities &&
              this.getEntityPermission.nested_relational_entities[
                this.currentMenu.nested_relational_entity
              ]?.permissions.indexOf(access) != -1
            ) {
              return true;
            }
          }
          return false;
        }
        return true;
      } else {
        if (access == "BULK DELETE") {
          return true;
        } else if (access == "BULK UPDATE") {
          if (
            this.$route.query.isMenu == "true" &&
            this.getMenu &&
            this.getMenu.menu_list &&
            this.getMenu.menu_list.length
          ) {
            let menuItems = [];
            this.getMenu.menu_list.map((menu) => {
              if (!menu.children?.length) {
                menuItems.push(menu);
              } else {
                menu.children.map((sub) => {
                  if (!sub.children?.length) {
                    menuItems.push(sub);
                  } else {
                    menuItems = [...menuItems, ...sub.children];
                  }
                });
              }
            });
            let menuItem = menuItems.find(
              (e) =>
                e &&
                e.feature == "ENTITIES" &&
                e.isGroup == "ENTITIES" &&
                e.entity_id &&
                (e.entity_id._id
                  ? this.entity_id.toString() == e.entity_id._id.toString()
                  : this.entity_id.toString() == e.entity_id.toString())
            );
            if (
              menuItem &&
              menuItem.bulkUpdate &&
              menuItem.bulkUpdate == true
            ) {
              return true;
            }
            return false;
          }
          return true;
        } else if (
          access == "SEND NOTIFICATION" ||
          access == "LAYOUT OPTIONS"
        ) {
          return true;
        } else if (
          this.entityFiltersData?.permissions?.access_set &&
          this.entityFiltersData?._id
        ) {
          if (access == "EDIT") {
            access = "UPDATE";
          }
          return (
            this.entityFiltersData.permissions.access_set.indexOf(access) != -1
          );
        }
        return true;
      }
    },
    getSort() {
      if (this.sortBy) {
        return {
          prop: this.sortBy,
          order: this.sortOrder == "asc" ? "ascending" : "descending",
        };
      }
      return null;
    },
    async sortChange(a) {
      if (
        this.sortBy == a.prop &&
        this.sortOrder == (a.order == "ascending" ? "asc" : "desc")
      ) {
        return;
      }
      this.sortBy = a.prop;
      this.sortOrder = a.order == "ascending" ? "asc" : "desc";
      const query = this.$route.query;
      this.$router.push({
        query: { ...query, order_by: this.sortBy, order_type: this.sortOrder },
      });
      this.fetchEntitiesDataForTable(this.applied_quick_filters);
      if (a.order == null) {
        this.sortBy = null;
        this.sortOrder = null;
        this.fetchEntitiesDataForTable(this.applied_quick_filters);
      }
    },
    async downloadFile(data) {
      this.loading = true;
      this.loadingText = "Downloading File....";
      let fileName = data.name;
      fileName = await fileName.replace(/\//g, "-");
      fileName = await fileName.replace(/ /g, "_");
      fileName = await fileName.replace(/[()]/g, "");
      if (data && !data.path) {
        data.path = `template-data-documents/${fileName}`;
      }
      await this.$store.dispatch("templatesData/downloadTemplateDocument", {
        path: data.path,
      });
      await fetch(this.getDownloadUrl, {
        method: "GET",
        responseType: "blob",
      })
        .then((res) => res.blob())
        .then((response) => {
          var fileURL = window.URL.createObjectURL(response);
          const link = document.createElement("a");
          link.href = fileURL;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      if (this.getDownloadUrl) {
        // window.open(this.getDownloadUrl, "_blank");
      } else {
        this.$notify.error({
          title: "Error",
          message: this.getDownloadError || "Error while downloading",
        });
      }
      this.loading = false;
      this.loadingText = "Loading....";
    },
    quickFilterUpdated(data) {
      this.applied_quick_filters = [...data];
      let filterValuesObject = {
        [this.currentFilter]: {
          selectedFields: this.selectedFileds,
          filters: data,
        },
      };
      this.$store.commit(
        "entities/setQuickEntityFiltersObject",
        filterValuesObject,
        {
          root: true,
        }
      );
      this.fetchEntitiesDataForTable(data);
    },
    async openSettingsModal() {
      if (
        this.entityFiltersData &&
        this.entityFiltersData.columns_settings &&
        this.entityFiltersData.columns_settings.length
      ) {
        this.refresh = true;
        this.selectedTableFields = [];
        this.selectedTableFields = this.getValidColumnSettings(
          this.entityFiltersData.columns_settings
        ).map((e) => e.id);
        this.treeCheckedNodes = this.getValidColumnSettings(
          this.entityFiltersData.columns_settings
        );
        setTimeout(() => {
          this.refresh = false;
        });
      }
      if (this.currentFilter && this.getCurrentFilter) {
        this.filterColumns = [];
        if (this.getCurrentFilter.tableFieldGroupByFields) {
          this.getCurrentFilter.tableFieldGroupByFields.forEach((e) => {
            if (e?.sub_group) {
              this.filterColumns.push("sub_group/" + e.key);
            } else {
              this.filterColumns.push(e.key);
            }
          });
        } else {
          this.filterColumns.push("count");
        }
        if (this.getCurrentFilter?.tableFieldGroupBy) {
          let subField = this.getField(this.getCurrentFilter.tableFieldGroupBy);
          if (this.getCurrentFilter?.filterEntityVariableColumns) {
            this.filterVariableColumns =
              this.getCurrentFilter.filterEntityVariableColumns;
          }
          if (subField?.input_type == "ENTITY") {
            this.loading = true;
            this.allOtherEntityFields = await this.fetchEntityDetails(
              subField.entity_id,
              true
            );
            this.allOtherEntityFields = this.allOtherEntityFields.filter(
              (e) => e.key
            );
            this.loading = false;
          }
        }
      }
      this.dialogTableVisible = true;
    },

    getValidColumnSettings(columns) {
      return columns.filter(
        (f) => (f.type == "ENTITY" && f.entity_id) || f.type != "ENTITY"
      );
    },
    async updateFilterColumn() {
      this.refresh = true;
      this.modalLoading = true;
      this.loading = true;
      if (this.getCurrentFilter && this.getCurrentFilter.tableFieldGroupBy) {
        if (!this.filterColumns.length) {
          this.$message({
            message: "Atleast one column must be selected",
            type: "warning",
          });
          this.modalLoading = false;
          this.loading = false;
          this.refresh = false;
          return;
        }
        let columns = [];
        this.filterColumns = this.filterColumns.filter(
          (e) =>
            !e.includes("#") || (e.includes("#") && e.split("#").length == 2)
        );
        this.filterColumns.forEach((e) => {
          if (e) {
            if (e.includes("sub_group/")) {
              columns.push({
                key: e.replace("sub_group/", ""),
                name: this.getLabel(e.replace("sub_group/", "")),
                type: e.replace("sub_group/", "") == "count" ? "count" : "sum",
                sub_group: true,
              });
            } else {
              columns.push({
                key: e,
                name:
                  this.getField(e) && this.getField(e).label
                    ? this.getField(e).label
                    : e,
                type: e == "count" ? "count" : "sum",
                sub_group: false,
              });
            }
          }
        });
        await this.$store.dispatch("filters/updateFilter", {
          id: this.currentFilter,
          params: {
            tableFieldGroupByFields: columns,
            filterEntityVariableColumns: this.filterVariableColumns,
          },
        });
      } else {
        // let checked = this.$refs.tree.getCheckedNodes();

        // let selectedEntity = await lodash.filter(checked, function (node) {
        //   return !node.children;
        // });
        let fields = this.treeCheckedNodes.filter((e) => !e.fields);
        this.selectedFieldKeys = fields.map((field) => field);
        if (this.selectedFieldKeys && !this.selectedFieldKeys.length) {
          this.$message({
            message: "Atleast one column must be selected",
            type: "warning",
          });
          this.modalLoading = false;
          this.loading = false;
          this.refresh = false;
          return;
        }
        await this.$store.dispatch("filters/updateFilter", {
          id: this.currentFilter,
          params: {
            columns_settings: this.selectedFieldKeys,
          },
        });
      }
      if (this.getUpdateFilterStatus) {
        if (this.getUpdateFilterData?.data) {
          await updateFilterData(
            this.currentFilter,
            this.getUpdateFilterData.data
          );
        }
        this.loading = false;
        this.$notify({
          title: "Success",
          message: "Filters updated",
          type: "success",
        });
        this.modalLoading = false;
        this.dialogTableVisible = false;
        this.filterColumList = [];
        this.filterColumList = [...this.selectedFieldKeys];
        this.selectedTableFields = this.selectedFieldKeys.map((e) => e.id);
        await this.fetchAllEntityFilters(true);
      } else {
        this.loading = false;
        this.$notify({
          title: "Error",
          message: "Failed to Save the filters",
          type: "error",
        });
      }
      this.refresh = false;
    },
    updateSelectedColumn() {
      this.refresh = true;
      // this.selectedTableFields = [];
      let selectedFilter = this.entityAllFilters.find(
        (e) => e && e._id == this.currentFilter
      );
      if (
        selectedFilter &&
        selectedFilter.columns_settings &&
        selectedFilter.columns_settings.length
      ) {
        this.selectedTableFields = [];
        this.selectedTableFields = selectedFilter.columns_settings.map(
          (e) => e.id
        );
      } else {
        this.selectedTableFields = [...this.entityFieldSettings];
      }
      setTimeout(() => {
        this.refresh = false;
      });
    },
    getTemplateFields(
      temp,
      include_data_tables = false,
      include_data_table_key = false
    ) {
      let fields = [];
      let data = JSON.parse(JSON.stringify(temp));
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });

      fields = fields.map((e) => {
        e.template_id = data._id;
        if (e.key && !e.key.includes("#")) {
          e.key = data._id + "#" + e.key;
        }
        e.template_name = data.name;
        e.data_type =
          e.validations && e.validations.type ? e.validations.type : "STRING";
        return e;
      });
      if (include_data_tables) {
        //have to check data table fields
        let dataTableFields = fields.filter(
          (e) => e?.inputType == "DATA_TABLE"
        );
        if (dataTableFields && dataTableFields.length) {
          dataTableFields.forEach((fl) => {
            if (fl?.data_table_columns) {
              fields = [
                ...fields,
                ...fl?.data_table_columns.map((e) => {
                  e.data_table_key = fl.key;
                  e.template_id = data._id;
                  e.label = fl.label + "-" + e.label;
                  e.template_name = data.name;
                  if (include_data_table_key) {
                    e.key = e.data_table_key + "." + e.key;
                  }
                  return e;
                }),
              ];
            }
          });
        }
        fields = fields.filter((e) => {
          return e.inputType != "DATA_TABLE";
        });
      }
      return fields;
    },
    resetFiltersData() {
      // if (!this.currentFilter) {
      //   this.resetEntityFiltersData();
      // } else {
      let check = this.checkFilterDisabled;
      if (!this.entityFiltersData.name && this.entityFiltersData.isSaveFilter) {
        this.entityFiltersData.quick_filters = [];
      }
      this.entityFiltersData.filters = this.removeEmptyRows(
        this.entityFiltersData.filters || []
      );
      if (check) {
        let selectedFilter = this.entityAllFilters.find(
          (e) => e && e._id == this.currentFilter
        );
        if (selectedFilter) {
          this.entityFiltersData = {
            ...selectedFilter,
            ...{ updateFilter: true },
          };
        }
      }
      // }
      if (
        this.entityFiltersData._id &&
        this.entityAllFilters &&
        this.entityAllFilters.length
      ) {
        let entityFiltersData = this.entityAllFilters.find(
          (e) => e._id == this.entityFiltersData._id
        );
        if (entityFiltersData?._id) {
          this.entityFiltersData = { ...entityFiltersData };
        }
      }
      this.dialogEdit = false;
    },
    getfilterLabel(filter) {
      let name = "";
      if (filter && filter.field && this.selectedEntityFields) {
        let filteredTemplate = this.selectedEntityFields.find(
          (e) => e.key == filter.field
        );
        if (filteredTemplate) {
          name =
            (filteredTemplate.template_name
              ? filteredTemplate.template_name
              : "") +
            "-" +
            (filteredTemplate.label ? filteredTemplate.label : "");
        }
      }
      return name;
    },
    confimDelete() {
      this.$confirm("Are you sure to delete?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.deleteFilter();
      });
    },
    async fetchAllEntityFilters(refreshTable) {
      //await this.$store.dispatch("filters/fetchAllEntityFilters", {
      //  entityId: this.entity_id,
      //});
      const getAllEntityFilters = await fetchAllEntityFiltersByEntity({
        entityId: this.entity_id,
      });
      this.entityAllFilters = [];
      if (getAllEntityFilters) {
        this.entityAllFilters = getAllEntityFilters;
        if (this.isApplicationUserSide) {
          this.entityAllFilters = this.entityAllFilters.filter(
            (e) => e._id == this.$route.query.filter
          );
        }
        if (this.$route.query.filter) {
          let entityFiltersData = this.entityAllFilters.find(
            (e) => e._id == this.$route.query.filter
          );
          if (entityFiltersData) {
            this.entityFiltersData = { ...entityFiltersData };
            this.currentFilter = this.$route.query.filter;
          } else {
            //removing not existed filter from url
            delete this.$route.query.filter;
            return;
          }
        } else if (refreshTable && this.currentFilter) {
          this.entityFiltersData = this.entityAllFilters.find(
            (e) => e && e._id == this.currentFilter
          );
          await this.fetchEntitiesDataForTable();
        }
        await this.getRelationQuickFilters();
      }
    },
    isNumber(value) {
      return (
        (typeof value === "number" && !isNaN(value)) ||
        (typeof value === "string" && !isNaN(Number(value)))
      );
    },
    mapDateFilters(filters) {
      return filters.map((d) => {
        let e = JSON.parse(JSON.stringify(d));
        if (e.global_variable_id) {
          let variable = this.globalVariablesData.find(
            (fl) => fl._id == e.global_variable_id
          );
          if (variable?.value) {
            if (this.isNumber(variable.value)) {
              e.value = parseInt(variable.value);
            } else {
              e.value = variable.value;
            }
          }
        }
        if (e.field == "created_at" || e.field == "updated_at") {
          if (new Date().getTimezoneOffset() < 0) {
            e.value = moment(e.value)
              .subtract(new Date().getTimezoneOffset() * -1, "minutes")
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
          } else {
            e.value = moment(e.value)
              .add(new Date().getTimezoneOffset(), "minutes")
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
          }
        } else if (
          e.operator == "real_time" &&
          e.data_type == "DATE" &&
          !e.every_year
        ) {
          let data = this.getDatePickerHelper1(e.selected_date_picker, e.value);
          e.value = data.value;
          e.today = data.today;
          e.operator = "between";
        } else if (
          e.data_source == "login_user" &&
          e.value_field &&
          this.getAuthenticatedUser[e.value_field]
        ) {
          e.value = this.getAuthenticatedUser[e.value_field];
        } else if (
          e.field == "created_by" &&
          e.data_source == "LOGIN_USER" &&
          this.getAuthenticatedUser
        ) {
          e.value = [this.getAuthenticatedUser._id];
        } else if (
          e.field == "updated_by" &&
          e.data_source == "LOGIN_USER" &&
          this.getAuthenticatedUser
        ) {
          e.value = [this.getAuthenticatedUser._id];
        } else if (
          e.data_type == "DATE_TIME" &&
          e.data_source == "REAL_TIME_PICKER" &&
          e.selected_date_picker &&
          e.operator == "dateinclude"
        ) {
          let data = this.getDatePickerHelper(e.selected_date_picker);
          e.value = data.value;
        } else if (e.data_type == "NUMBER" && e.operator == "between") {
          let data = [e.range1, e.range2];
          e.value = data;
        } else if (e.data_type == "CURRENCY" && e.operator == "between") {
          let data = [e.range1, e.range2];
          e.value = data;
        } else if (
          e.data_type == "DATE" &&
          e.value &&
          e.data_source == "CURRENT_DATE"
        ) {
          if (e.value == "today") {
            if (e.operator == ">") {
              e.value = moment().endOf("day").toISOString();
            } else if (e.operator == ">=") {
              e.value = moment().startOf("day").toISOString();
            } else if (e.operator == "<") {
              e.value = moment().startOf("day").toISOString();
            } else if (e.operator == "<=") {
              e.value = moment().endOf("day").toISOString();
            } else if (e.operator == "=") {
              let data = this.getDatePickerHelper1("today", moment());
              e.value = data.value;
              e.today = data.today;
              e.operator = "between";
            }
          }
        } else if (
          e.data_type == "DATE" &&
          e.value &&
          ["before", "after", "weekdays"].indexOf(e.operator) == -1
        ) {
          if (e.operator == "=") {
            let data = this.getDatePickerHelper1("custom_date", e.value);
            e.value = data.value;
            e.today = data.today;
            e.operator = "between";
          } else if (
            ["notbetween", "between"].includes(e.operator) &&
            e.value
          ) {
            let data = this.getDatePickerHelper1("custom_date_range", e.value);
            e.value = data.value;
            e.today = data.today;
          } else if (
            e.operator == "real_time" &&
            e.data_type == "DATE" &&
            e.value &&
            !e.every_year
          ) {
            let data = this.getDatePickerHelper1(
              e.selected_date_picker,
              e.value
            );
            e.value = data.value;
            e.today = data.today;
            e.operator = "between";
          } else {
            e.value = moment(new Date(e.value)).endOf("day");
          }
        }
        e["current_date"] = this.getCurrentDate;
        return e;
      });
    },
    getField(field) {
      if (this.currentEntity?.templates && field && field.includes("#")) {
        let selectedEntityFields = [];
        this.currentEntity.templates.forEach((e) => {
          if (e && e.templateInfo && !e.is_repeatable) {
            selectedEntityFields = [
              ...selectedEntityFields,
              ...this.getTemplateFields(
                JSON.parse(JSON.stringify(e.templateInfo))
              ),
            ];
          }
        });
        let foundField = selectedEntityFields.find((e) => {
          let [tempId, key] = field.split("#");
          if (tempId == e.template_id && e.key) {
            let fieldKey = e.key.includes("#")
              ? e.key.split("#")[e.key.split("#").length - 1]
              : e.key;
            if (key == fieldKey) {
              return true;
            }
          }
        });
        return foundField ? foundField : null;
      }
      return null;
    },
    async fetchEntitiesDataForTable(filters = []) {
      this.loadingText = "Fetching data...";
      this.loading = true;
      if (
        this.currentMenu?.entity_type == "RELATIONAL_ENTITY" &&
        this.isApplicationUserSide
      ) {
        filters = [...filters, ...this.getSelfEntityFilters];
      }
      let nestedRelationalEntityDatas = [];
      if (
        this.currentMenu?.entity_type == "NESTED_RELATIONAL_ENTITY" &&
        this.isApplicationUserSide
      ) {
        nestedRelationalEntityDatas = await this.getNestedRelationshipDataIds();
      }
      if (!this.entityFiltersData && this.$route.query.filter) {
        this.resetEntityFiltersData();
      }
      filters = [...this.entityFiltersData.filters, ...filters];
      // this.exportFilters = filters;
      let checkDataTable = this.filterColumList.find(
        (e) => e.type == "DATA_TABLE"
      );
      let params = {
        entity_id: this.entity_id,
        template_fields_data: (this.currentEntity &&
        this.currentEntity.templates
          ? this.currentEntity.templates
          : []
        ).map((template) => {
          return {
            template_id: template.template_id,
          };
        }),
        filters: this.mapDateFilters(
          this.addGlobalFilters(
            filters || [],
            this.entity_id,
            this.checkGlobalFilter
          )
        ),
        limit: this.pageSize,
        page: parseInt(this.getCurrentPage)
          ? parseInt(this.getCurrentPage)
          : this.currentPage,
        sortBy: this.sortBy,
        sortOrder: this.sortOrder,
        search_string: this.search_string,
        data_table_field: checkDataTable,
      };
      if (
        this.currentMenu?.entity_type == "NESTED_RELATIONAL_ENTITY" &&
        this.isApplicationUserSide
      ) {
        params.entity_data_ids = nestedRelationalEntityDatas;
        this.exportDataIds = nestedRelationalEntityDatas;
        if (!nestedRelationalEntityDatas.length) {
          this.data = [];
          this.total = 0;
          this.totalData = [];
          this.tableLoading = false;
          return;
        }
      }
      this.applied_filters = [];
      this.applied_filters = this.mapDateFilters(filters || []);
      if (this.entityFiltersData?.tableFieldGroupBy) {
        let subField = this.getField(this.entityFiltersData.tableFieldGroupBy);
        if (subField?.inputType == "MULTI_SELECT") {
          params.unwind = true;
        }
        params.group_by = this.entityFiltersData.tableFieldGroupBy;
        params.group_by_fields = this.entityFiltersData.tableFieldGroupByFields;
        if (this.entityFiltersData?.tableFieldSubGroupBy) {
          params.sub_group_by = this.entityFiltersData?.tableFieldSubGroupBy;
        } else {
          params.entity_variable_columns =
            this.entityFiltersData.filterEntityVariableColumns;
          if (
            this.getField(this.entityFiltersData.tableFieldGroupBy)?.entity_id
          ) {
            params.filter_variable_entity = this.getField(
              this.entityFiltersData.tableFieldGroupBy
            ).entity_id;
          }
        }
      }
      await this.$store.dispatch("entities/fetchEntityRecordsForTable", params);
      this.exportFilters = params.filters;
      if (!this.currentEntity || this.currentEntity?._id !== this.entity_id) {
        this.currentEntity = this.getEntityRecordsForTable.selectedEntity;
        await this.$store.commit(
          "entities/setEntityDataById",
          this.currentEntity,
          {
            root: true,
          }
        );
      }
      this.data = [];
      if (
        this.entityFiltersData &&
        this.entityFiltersData.columns_settings &&
        this.entityFiltersData.columns_settings.length
      ) {
        this.filterColumList = [];
        this.filterColumList = this.entityFiltersData.columns_settings;
        //map with latest field type
        this.filterColumList = this.mapTableFieldsType(
          this.currentEntity,
          this.filterColumList
        );
      }
      if (this.getEntityRecordsForTable) {
        this.data = this.getEntityRecordsForTable.data;
        //this.entityUserInfoData = await this.mapEntityDataWithUserInfo(
        //  this.data
        //);
        this.templateData =
          this.getEntityRecordsForTable.selectedEntity.templates.map((e) => e);
        this.mapEntityDataWithUserInfo(this.data).then((e) => {
          this.entityUserInfoData = { ...e };
        });
        this.totalData = [...this.data];
        this.total = this.getEntityRecordsForTable.total
          ? this.getEntityRecordsForTable.total
          : 0;
        this.custom_columns = [];
        if (this.entityFiltersData?.tableFieldGroupBy) {
          if (this.entityFiltersData?.tableFieldSubGroupBy) {
            let custom_columns = [];
            this.groupedData = this.getEntityRecordsForTable.data.map(
              (data) => {
                if (data?.properties) {
                  let label = "",
                    total = 0;
                  (data.properties || []).forEach((el) => {
                    if (!label) {
                      label = el.entity_label;
                    }
                    total = total + el.count;
                    let value = el.sub_entity_label
                      ? el.sub_entity_label
                      : el.label;
                    let index = custom_columns.findIndex(
                      (e) => e.value == el.label
                    );
                    if (index == -1 && value) {
                      custom_columns.push({
                        value: el.label,
                        label: value,
                      });
                    }
                    (Object.keys(el) || []).forEach((key) => {
                      if (
                        ["entity_label", "label", "sub_entity_label"].indexOf(
                          key
                        ) == -1
                      ) {
                        data[el.label + "?" + key] = el[key];
                        data[value + "?" + key] = el[key];
                      }
                    });
                  });

                  data.entity_label = label;
                  data.count = total;
                }
                return data;
              }
            );
            if (
              this.entityFiltersData?.table_field_group_by_selected_options &&
              this.entityFiltersData.table_field_group_by_selected_options
                .length
            ) {
              custom_columns = custom_columns.filter(
                (e) =>
                  this.entityFiltersData.table_field_group_by_selected_options.indexOf(
                    e.value
                  ) != -1
              );
            }
            this.entityFiltersData.tableFieldGroupByFields
              .filter((e) => e.sub_group)
              .forEach((field) => {
                (custom_columns || []).forEach((col) => {
                  this.custom_columns.push({
                    label: col.label + "-" + field.name,
                    value: col.value + "?" + field.key,
                  });
                });
              });
          } else {
            this.groupedData = this.getEntityRecordsForTable.data;
            this.groupedData = this.groupedData.map((e) => {
              if (e && e.name && e.name.includes("#")) {
                e.name = e.name.split("#")[0];
              }
              return e;
            });
          }
        }
        this.loading = false;
        if (this.data.length) {
          // await this.prepareRlationships();
        }
      } else {
        this.loading = false;
      }
    },
    getSubField(data, step) {
      let value = 0,
        key = step && step.includes("?") ? step.split("?")[1] : step;
      let field = this.getField(key);
      if (data && step && data[step]) {
        value = data[step];
      }
      if (field?.inputType == "CURRENCY") {
        let type = field.validations.currency
          ? field.validations.currency
          : "USD";
        let locale = field.validations.locale
          ? field.validations.locale
          : "en-US";
        value = new Intl.NumberFormat(locale, {
          style: "currency",
          currency: type,
        }).format(value);
      } else if (field?.inputType == "AGGREGATE_FUNCTION") {
        let isCurrencyField = (field.selected_fields || []).find((el) => {
          if (el?.inputType == "CURRENCY" || el?.result_type == "CURRENCY") {
            return true;
          }
        });
        if (isCurrencyField) {
          let type =
            isCurrencyField &&
            isCurrencyField.validations &&
            isCurrencyField.validations.currency
              ? isCurrencyField.validations.currency
              : "USD";
          let locale =
            isCurrencyField &&
            isCurrencyField.validations &&
            isCurrencyField.validations.locale
              ? isCurrencyField.validations.locale
              : "en-US";
          value = new Intl.NumberFormat(locale, {
            style: "currency",
            currency: type,
          }).format(value);
        } else {
          return value;
        }
      }
      return value;
    },
    async prepareRlationships() {
      await this.fetchEntityRelationships(this.entity_id);
      let relationships = this.getCurrentEntityRelationships;
      relationships = relationships.filter(
        (e) => e.representation == "TAB" || e.owner_type == "CHILD"
      );

      this.relationshipsActions = relationships.map((r) => {
        return {
          title: "Add " + r.relationship_title,
          type: "ADD",
          child_relationship_id: r.relationship_id,
          relationship_details: r,
        };
      });
    },
    checkApplicationUserPermission(rowData, stepData) {
      if (this.isApplicationUserSide) {
        if (
          this.getEntityPermission &&
          this.getEntityPermission.other_entities &&
          this.getEntityPermission.other_entities[stepData.entity_id] &&
          this.getEntityPermission.other_entities[stepData.entity_id]
            .permissions &&
          this.getEntityPermission.other_entities[
            stepData.entity_id
          ].permissions.indexOf("VIEW") !== -1
        ) {
          this.showChildEntityDataDetails(rowData, stepData);
        } else {
          this.$message({
            message: "You don't have permission to view the data",
            type: "warning",
          });
        }
      } else {
        this.showChildEntityDataDetails(rowData, stepData);
      }
    },
    closeChildEntityDataDialog() {
      this.showChildEntityDataDialog = false;
    },
    async showChildEntityDataDetails(rowData, stepData) {
      let field = this.getField(stepData.template_id + "#" + stepData.id);
      this.showChildEntityDataDialog = false;
      this.selectedChildEntityDataIsMultple = false;

      this.selectedChildEntityDataId =
        rowData.entityData[stepData.template_id][stepData.id];

      this.selectedChildEntityId = stepData.entity_id;

      this.selectedChildEntityRefName = stepData.label;
      this.selectedChildEntityPrimaryDataValue = this.getFieldValue(
        rowData,
        stepData
      );
      //For multiple entity show as table
      if (
        field?.allow_multiple &&
        typeof this.selectedChildEntityDataId == "object" &&
        this.selectedChildEntityDataId.length > 1
      ) {
        this.selectedChildEntityDataIsMultple = true;
      }
      this.showChildEntityDataDialog = true;
    },

    async openAddNewRelationshipData(data, action) {
      this.showAddRelationshipDataDialog = false;
      action.relationship_details.parent_entity_data_id = data._id;
      this.selectedRelationshipDetails = { ...action.relationship_details };
      this.selectedRelationshipExistedIds = [];
      this.showAddRelationshipDataDialog = true;
    },
    async updateCurrentFilter() {
      this.quickFilterVisible = true;
      this.resetEntityFiltersData();
      if (this.currentFilter) {
        let selectedFilter = this.entityAllFilters.find(
          (e) => e && e._id == this.currentFilter
        );
        if (selectedFilter.displayType) {
          this.activeEntityView = selectedFilter.displayType;
          let query = { ...this.$route.query };
          query["viewType"] = selectedFilter.displayType;
          query["filter"] = selectedFilter._id;
          this.$router.push({
            name: this.$route.name,
            query: query,
          });
        }
        this.entityFiltersData = {
          ...selectedFilter,
          ...{ isSaveFilter: true },
        };
        await this.getRelationQuickFilters();
        this.treeCheckedNodes =
          selectedFilter.columns_settings &&
          selectedFilter.columns_settings.length
            ? selectedFilter.columns_settings
            : this.currentEntity?.settings && this.currentEntity.settings.length
            ? this.currentEntity.settings
            : this.defaultSettingFields;
        this.selectedTableFields = this.treeCheckedNodes.map((e) => e.id);
      } else {
        this.filterColumList = [];
        if (this.currentEntity.settings && this.currentEntity.settings.length) {
          this.selectedTableFields = [];
          this.filterColumList = this.getValidColumnSettings(
            this.currentEntity.settings
          );
          //map with latest field type
          // this.filterColumList = this.mapTableFieldsType(
          //   this.currentEntity,
          //   this.filterColumList
          // );
        } else {
          this.filterColumList = [
            ...this.filterColumList,
            ...this.defaultSettingFields
          ]
        }
        this.treeCheckedNodes = this.filterColumList;
        this.selectedTableFields = this.treeCheckedNodes.map((e) => e.id);
        let query = { ...this.$route.query };
        delete query["viewType"];
        delete query["filter"];
        this.$router.push({
          name: this.$route.name,
          query: query,
        });
      }
      this.quickFilterVisible = false;
      this.fetchEntitiesDataForTable();
    },
    async getRelationQuickFilters() {
      this.quickFilterVisible = true;
      const relationFilters = this.entityFiltersData.filters.filter(
        (e) => e.data_type == "RELATION"
      );
      if (relationFilters.length) {
        await this.$store.dispatch(
          "filters/getFilterById",
          relationFilters[0].value
        );
        if (
          this.getSingleFilterData &&
          this.getSingleFilterData.quick_filters.length
        ) {
          this.relationQuickFilters =
            this.getSingleFilterData.quick_filters.map(
              (e) => e + "#" + relationFilters[0].relation_entity_id
            );
        }
      }
      this.quickFilterVisible = false;
    },
    resetEntityFiltersData() {
      this.entityFiltersData = {
        ...{
          filters: [],
          filter_name: "",
          isSaveFilter: false,
          query_type: "AND",
          quick_filters: [],
          columns_settings: [],
        },
      };
      this.relationQuickFilters = [];
    },
    async deleteFilter() {
      let id = this.entityFiltersData._id ? this.entityFiltersData._id : "";
      if (!id) {
        this.$notify({
          title: "Error",
          message: "Filter not selected",
          type: "error",
        });
        return;
      }
      this.loading = true;
      await this.$store.dispatch("filters/deleteFilter", id);
      if (this.getDeleteFilterStatus) {
        await deleteFilter(id);
        this.dialogEdit = false;
        this.resetEntityFiltersData();
        this.currentFilter = "";
        this.loading = false;
        this.$notify({
          title: "Success",
          message: "Filters deleted",
          type: "success",
        });
        this.loading = false;
        this.fetchAllEntityFilters();
        this.fetchEntitiesDataForTable();
      } else {
        this.loading = false;
        let msg = "Failed to delete the filters";
        if (this.getDeleteFilterErros?.message) {
          msg = this.getDeleteFilterErros.message;
        }
        this.$notify({
          title: "Error",
          message: msg,
          type: "error",
        });
      }
    },
    async updateFilter() {
      let params = this.prepareFilterParams();
      if (!params.filter_name) {
        this.$notify({
          title: "Error",
          message: "Please enter Filter name",
          type: "error",
        });
        return;
      }
      let id = this.entityFiltersData._id ? this.entityFiltersData._id : "";
      if (!id) {
        await this.saveFilter();
      }
      await this.$store.dispatch("filters/updateFilter", {
        id: id,
        params: params,
      });
      if (this.getUpdateFilterStatus) {
        if (this.getUpdateFilterData?.data) {
          await updateFilterData(id, this.getUpdateFilterData.data);
        }
        // this.loading = false;
        this.$notify({
          title: "Success",
          message: "Filters updated",
          type: "success",
        });
        this.loading = true;
        await this.fetchAllEntityFilters();
        this.loading = false;
      } else {
        this.loading = false;
        let msg = "Failed to Save the filters";
        if (this.getUpdateFilterError && this.getUpdateFilterError.message) {
          msg = this.getUpdateFilterError.message;
        }
        this.$notify({
          title: "Error",
          message: msg,
          type: "error",
        });
      }
    },
    prepareFilterParams() {
      return {
        filter_name: this.entityFiltersData.filter_name.trim(),
        filters: this.entityFiltersData.filters,
        entity_id: this.entity_id,
        quick_filters: this.entityFiltersData.quick_filters,
        permissions: this.entityFiltersData.permissions,
        tableFieldGroupBy: this.entityFiltersData.tableFieldGroupBy || "",
        tableFieldSubGroupBy: this.entityFiltersData.tableFieldSubGroupBy || "",
        tableFieldGroupByFields:
          this.entityFiltersData.tableFieldGroupByFields || [],
        filterEntityVariableColumns:
          this.entityFiltersData.filterEntityVariableColumns,
        displayType: this.entityFiltersData.displayType,
      };
    },
    async saveFilter() {
      let params = this.prepareFilterParams();
      if (!params.filter_name) {
        this.$notify({
          title: "Error",
          message: "Please enter Filter name",
          type: "error",
        });
        return;
      }
      await this.$store.dispatch("filters/fetchCreateNewFilter", params);
      if (this.getCreateNewFilter) {
        this.showFieldsParent.selectedFilter = [this.getCreateNewFilter._id];
        this.currentFilter = this.getCreateNewFilter._id;
        await addSingleFilterToLocal(this.getCreateNewFilter);
        // this.loading = false;
        this.$notify({
          title: "Success",
          message: "Filters saved",
          type: "success",
        });
        this.loading = true;
        await this.fetchAllEntityFilters();
        this.loading = false;
      } else {
        this.loading = false;
        let msg = "Filter name already exists";
        if (
          this.getCreateNewFilterError &&
          this.getCreateNewFilterError.message
        ) {
          msg = this.getCreateNewFilterError.message;
        }
        this.$notify({
          title: "Error",
          message: msg,
          type: "error",
        });
      }
    },
    async applyFilter() {
      this.loading = true;
      this.dialogEdit = false;
      if (this.entityFiltersData) {
        this.entityFiltersData.filters = this.entityFiltersData.filters.map(
          (e) => {
            e.query_type = this.entityFiltersData.query_type
              ? this.entityFiltersData.query_type
              : "AND";
            return e;
          }
        );
        if (this.entityFiltersData._id && this.entityFiltersData.updateFilter) {
          this.updateFilter();
        } else if (
          !this.entityFiltersData._id &&
          this.entityFiltersData.isSaveFilter
        ) {
          this.saveFilter();
        }
      }
      if (this.entityFiltersData?.displayType && this.entityFiltersData?._id) {
        this.activeEntityView = this.entityFiltersData.displayType;
        let query = { ...this.$route.query };
        query["viewType"] = this.entityFiltersData.displayType;
        if (this.entityFiltersData?._id) {
          query["filter"] = this.entityFiltersData._id;
        }
        this.$router.push({
          name: this.$route.name,
          query: query,
        });
      }
      this.loading = false;
      this.fetchEntitiesDataForTable();
    },
    openFilterModel() {
      if (this.currentFilter) {
        let selectedFilter = this.entityAllFilters.find(
          (e) => e && e._id == this.currentFilter
        );
        let quick_filters = this.entityFiltersData.quick_filters || [];
        let query_type =
          this.entityFiltersData && this.entityFiltersData.query_type
            ? this.entityFiltersData.query_type
            : selectedFilter &&
              selectedFilter.filters &&
              selectedFilter.filters.length &&
              selectedFilter.filters[0].query_type
            ? selectedFilter.filters[0].query_type
            : "AND";
        this.entityFiltersData = {
          ...JSON.parse(JSON.stringify(selectedFilter)),
          ...{
            updateFilter: true,
            query_type: query_type,
          },
        };
        if (!this.entityFiltersData.quick_filters) {
          this.$set(this.entityFiltersData, "quick_filters", quick_filters);
        }
        if (!this.entityFiltersData.permissions) {
          this.$set(this.entityFiltersData, "permissions", {
            templates: [
              ...this.currentEntity.templates.flatMap((e) => e.template_id),
            ],
            access_set: ["VIEW", "UPDATE", "DELETE", "ADD"],
          });
        }
      }
      this.dialogEdit = true;
    },
    removeEmptyRows(data) {
      if (data && data.length) {
        return data.filter(
          (fl) =>
            fl &&
            fl.field &&
            fl.operator &&
            (fl.data_source ||
              (fl.operator != "exists" && fl.operator != "notexists")) &&
            ((fl.data_source == "GLOBAL_VARIABLE" && fl.global_variable_id) ||
              (fl.operator != "exists" && fl.operator != "notexists")) &&
            ((fl.data_source == "self_field" && fl.value_field) ||
              (fl.operator != "exists" && fl.operator != "notexists")) &&
            (fl.value ||
              (fl.data_source != "self_field" &&
                fl.operator != "exists" &&
                fl.operator != "notexists" &&
                fl.data_source != "GLOBAL_VARIABLE"))
        );
      }
      return [];
    },
    resetDialogModal() {
      this.dialogTableVisible = false;
      if (!this.currentFilter) {
        this.treeCheckedNodes = this.currentEntity.settings;
      } else {
        this.treeCheckedNodes = this.filterColumList;
      }
    },
    async addEntityData() {
      let query = this.getNavigationQuery(this.$route.query);
      let viewType = "STANDARD";
      if (this.currentEntity?.entity_type == "INDIVIDUAL") {
        viewType = "PROFILE";
      }
      if (this.isApplicationUserSide) {
        this.$router.push({
          path: `/ap/entity-execute/${this.entity_id}`,
          query: {
            ...query,
            ...{
              menu_id: this.$route.params.menuId,
              layout: viewType,
              type: "s7aLF3NnAQg=",
            },
          },
        });
      } else {
        let UrlPathAdd = "";
        if (this.getIframeUrlInfo != null) {
          UrlPathAdd = `/if/${this.getIframeUrlInfo._id}/entity/edit/${this.entity_id}`;
        } else {
          UrlPathAdd = `/entity/edit/${this.entity_id}`;
        }
        this.$router.push({
          path: UrlPathAdd,
          query: { ...query, ...{ layout: viewType } },
        });
      }
      // this.$router.push({
      //   path: `/entity-execute/${this.entity_id}`,
      //   query: query,
      // });
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
    isFloat(n) {
      return Number(n) === n && n % 1 !== 0;
    },

    generateKeyForField(label) {
      let parsedLabel = label.toLowerCase().replace(/[^a-zA-Z 0-9]/g, "");
      parsedLabel = parsedLabel.toLowerCase().split(" ").join("_");

      return parsedLabel;
    },
    getCurrencyFormet(step) {
      let codes = {
        USD: {
          currency: "USD",
          code: "en-US",
        },
        INR: {
          currency: "INR",
          code: "en-IN",
        },
        EUR: {
          currency: "EUR",
          code: "en-EU",
        },
        AUD: {
          currency: "AUD",
          code: "en-AU",
        },
        CAD: {
          currency: "CAD",
          code: "en-CA",
        },
      };
      let field = this.selectedEntityFields.find(
        (e) => e.key && e.key.split("#")[1] && e.key.split("#")[1] == step.id
      );
      if (field && field.validations && field.validations.currency) {
        return codes[field.validations.currency];
        // return {
        //   currency: field.validations.currency,
        //   code: field.validations.locale,
        // };
      } else if (step && step.currency_code && step.currency_code.currency) {
        return codes[step.currency_code.currency];
      } else {
        return {
          currency: "USD",
          code: "en-US",
        };
      }
    },
    getFieldValue(row, step) {
      let template = this.getEntityDataById?.templates.find(
        (e) => e.templateInfo._id.toString() == step.template_id
      );
      const field = template?.templateInfo?.sections[0]?.fields.find(
        (e) => e.key == step.id
      );
      if (!row.entityData?.[step.template_id]?.[step.id]) {
        if (
          field &&
          field.properties &&
          field.properties.tableDefaultValue &&
          field.properties.tableDefaultValue !== ""
        ) {
          return field?.properties?.tableDefaultValue;
        }
      }
      let checkIsGlobalvariable = this.selectedEntityFields.find(
        (e) => e.key && e.key.split("#")[1] && e.key.split("#")[1] == step.id
      );
      if (
        checkIsGlobalvariable &&
        checkIsGlobalvariable.is_global_variable &&
        checkIsGlobalvariable.global_variable_id
      ) {
        let globalVariable = (this.globalVariablesData || []).find(
          (e) => e._id == checkIsGlobalvariable.global_variable_id
        );
        if (globalVariable) {
          if (globalVariable.input_type == "LIST" && globalVariable.list_data) {
            const listValue = globalVariable.list_data.find(
              (data) => data.value == row.entityData[step.template_id][step.id]
            );
            if (listValue) {
              return listValue.name;
            }
          } else if (
            globalVariable.input_type == "IMAGE" &&
            globalVariable.image_url
          ) {
            return globalVariable.image_url;
          } else if (globalVariable.value) return globalVariable.value;
        }
      }
      if (step.type == "CHECKBOX") {
        if (
          row.entityData &&
          step.template_id &&
          row.entityData[step.template_id]
        ) {
          switch (row?.entityData?.[step.template_id]?.[step.id]) {
            case true:
              if (
                field &&
                field.properties &&
                field.properties.checkedLabel &&
                field.properties.checkedLabel !== ""
              ) {
                return field.properties.checkedLabel;
              }
              return true;
            case false:
              if (
                field &&
                field.properties &&
                field.properties.unCheckedLabel &&
                field.properties.unCheckedLabel !== ""
              ) {
                return field.properties.unCheckedLabel;
              }
              return false;
          }
        }
      } else if (step.type == "WEEKDAYS") {
        if (
          row.entityData &&
          step.template_id &&
          row.entityData[step.template_id] &&
          row.entityData[step.template_id][step.id]
        ) {
          if (
            checkIsGlobalvariable?.allow_multiple &&
            typeof row.entityData[step.template_id][step.id] == "object" &&
            row.entityData[step.template_id][step.id].length
          ) {
            let mappedArray = row.entityData[step.template_id][step.id].map(
              (e) => this.weekDayMap[e]
            );
            return mappedArray.join(", ");
          } else {
            return this.weekDayMap[row.entityData[step.template_id][step.id]];
          }
        }
        return "";
      } else if (step.type == "CAPTCHA") {
        return row.entityData[step.template_id][step.id]
          ? "Verified"
          : "Not verified";
      } else if (step.type == "QR") {
        let qr = null;
        // qrType
        if (step.currency_code.qrType === "From Field") {
          if (
            row.entityData &&
            row.entityData[step.template_id] &&
            step.currency_code.qr_data_field
          ) {
            let obj = row.entityData[step.template_id]
              ? row.entityData[step.template_id]
              : {};
            if (step.currency_code.qr_data_field in obj) {
              let value =
                "" +
                row.entityData[step.template_id][
                  step.currency_code.qr_data_field
                ];
              QRCode.toDataURL(
                value,
                { errorCorrectionLevel: "H" },
                (err, url) => {
                  if (err) throw err;
                  qr = url;
                }
              );
              return qr;
            } else {
              return qr;
            }
          }
        } else if (step.currency_code.qrType === "Record Id") {
          QRCode.toDataURL(
            row._id,
            { errorCorrectionLevel: "H" },
            (err, url) => {
              if (err) throw err;
              qr = url;
            }
          );
          return qr;
        } else if (step.currency_code.qrType === "URL") {
          // Record Id
          const data = {
            referenceId: this.currentEntity._id,
            dataId: row._id,
          };
          // this.qrOutput(JSON.stringify(data));
          QRCode.toDataURL(
            JSON.stringify(data),
            { errorCorrectionLevel: "H" },
            (err, url) => {
              if (err) throw err;
              qr = url;
            }
          );
          return qr;
        }
      } else if (step.type == "HTML_CONTENT") {
        let newfd = this.currentEntity.templates.find((el) => {
          if (el.template_id === step.template_id) {
            return el.templateInfo;
          }
        });
        let val = newfd.templateInfo.sections[0].fields.find((el) => {
          let keyVal = el.key.split("#")[1];
          if (keyVal === step.id && el.input_type == "HTML_CONTENT") {
            return el;
          }
        });

        return val.content;
        // return row.entityData[step.template_id][step.id]
      } else if (step.type == "IMAGE") {
        let id = this.generateKeyForField(step.label);
        if (
          row.entityData &&
          step.template_id &&
          row.entityData[step.template_id] &&
          row.entityData[step.template_id][id]
        ) {
          return row.entityData[step.template_id][id];
        }
      } else if (
        step.type == "FILE" &&
        row.entityData &&
        step.template_id &&
        row.entityData[step.template_id] &&
        row.entityData[step.template_id][step.id]
      ) {
        this.documentsData =
          row.entityData[step.template_id][step.id].files &&
          row.entityData[step.template_id][step.id].files.length
            ? row.entityData[step.template_id][step.id].files
            : row.entityData[step.template_id][step.id];
        // this.documentSearchData = this.documentsData;
        return row.entityData[step.template_id][step.id].files &&
          row.entityData[step.template_id][step.id].files.length
          ? row.entityData[step.template_id][step.id].files
          : row.entityData[step.template_id][step.id];
      } else if (
        row.entityData &&
        step.template_id &&
        row.entityData[step.template_id] &&
        row.entityData[step.template_id][step.id]
      ) {
        if (
          typeof row.entityData[step.template_id][step.id] == "number" &&
          this.isFloat(row.entityData[step.template_id][step.id])
        ) {
          return parseFloat(row.entityData[step.template_id][step.id]).toFixed(
            2
          );
        }
        if (step.type == "MULTI_SELECT") {
          let value = row.entityData[step.template_id][step.id]
            ? row.entityData[step.template_id][step.id]
            : "";
          if (value) {
            if (typeof value == "object" && value.length) {
              return value.join(",");
            }
            return value;
          }
          return "";
        } else if (
          step.type == "SELECT" &&
          row.entityData[step.template_id][step.id]
        ) {
          let result,
            field = this.selectedEntityFields.find(
              (e) => e.key === step.template_id + "#" + step.id
            );
          if (
            field &&
            field.inputType == "SELECT" &&
            field.is_list &&
            field.global_variable_id
          ) {
            let value = field.options.find(
              (e) => e.value == row.entityData[step.template_id][step.id]
            );
            if (value && value.name) {
              result = value.name;
            }
            result = row.entityData[step.template_id][step.id];
          } else {
            result = row.entityData[step.template_id][step.id];
          }
          if (result && typeof result == "object" && result.length) {
            result = result[0];
          }
          return result;
        } else if (
          step.type == "LIST" &&
          row.entityData[step.template_id][step.id]
        ) {
          let result,
            field = this.selectedEntityFields.find(
              (e) => e.key === step.template_id + "#" + step.id
            );
          if (field?.list_data) {
             let value = field.list_data.find(

              (e) =>

                e.value == row.entityData[step.template_id][step.id] &&

                (e.name ==

                  row.entityData[step.template_id][step.id + "_info"] ||

                  !row.entityData[step.template_id][step.id + "_info"])  );
            if (value && value.name) {
              result = value.name;
            } else {
              result = row.entityData[step.template_id][step.id];
            }
          } else {
            result = row.entityData[step.template_id][step.id];
          }
          if (result && typeof result == "object" && result.length) {
            result = result[0];
          }
          return result;
        } else if (
          step.type == "NUMBER" &&
          row.entityData[step.template_id][step.id]
        ) {
          let template = this.getEntityDataById?.templates.find(
            (e) => e.templateInfo._id.toString() == step.template_id
          );
          let field = template?.templateInfo?.sections[0]?.fields.find(
            (e) => e.key == step.id
          );
          return typeof row.entityData[step.template_id][step.id] == "number"
            ? row.entityData[step.template_id][step.id] +
                (field &&
                field.properties &&
                field.properties.number_type == "PERCENTAGE"
                  ? "%"
                  : "")
            : !isNaN(row.entityData[step.template_id][step.id])
            ? parseInt(row.entityData[step.template_id][step.id]) +
              (field &&
              field.properties &&
              field.properties.number_type == "PERCENTAGE"
                ? "%"
                : "")
            : "";
        } else if (step.type == "CHECKBOX_GROUP") {
          if (
            row.entityData &&
            step.template_id &&
            row.entityData[step.template_id] &&
            row.entityData[step.template_id][step.id] &&
            typeof row.entityData[step.template_id][step.id] == "object" &&
            row.entityData[step.template_id][step.id].length
          ) {
            return row.entityData[step.template_id][step.id].length
              ? row.entityData[step.template_id][step.id].join(",")
              : "";
          } else {
            return row.entityData[step.template_id][step.id].length
              ? row.entityData[step.template_id][step.id]
              : "-";
          }
        } else if (
          step.type == "TIME_RANGE" &&
          row.entityData[step.template_id][step.id].length
        ) {
          if (row.entityData[step.template_id][step.id].length) {
            return (
              this.$moment(row.entityData[step.template_id][step.id][0]).format(
                "HH:mm:ss"
              ) +
              " - " +
              this.$moment(row.entityData[step.template_id][step.id][1]).format(
                "HH:mm:ss"
              )
            );
          }
        } else if (
          step.type == "LOCATION" &&
          row.entityData[step.template_id][step.id].length
        ) {
          this.savedCoordinates = row.entityData[step.template_id][step.id];
          return row.entityData[step.template_id][step.id];
        } else if (
          step.type == "DATE_TIME_RANGE" &&
          row.entityData[step.template_id][step.id].length
        ) {
          if (row.entityData[step.template_id][step.id].length) {
            return (
              this.$moment(row.entityData[step.template_id][step.id][0]).format(
                "MM-DD-YYYY hh:mm:ss A"
              ) +
              " To " +
              this.$moment(row.entityData[step.template_id][step.id][1]).format(
                "MM-DD-YYYY hh:mm:ss A"
              )
            );
          }
        } else if (
          step.type == "DATE_RANGE" &&
          row.entityData[step.template_id][step.id].length
        ) {
          if (row.entityData[step.template_id][step.id].length) {
            return (
              this.$moment(row.entityData[step.template_id][step.id][0]).format(
                "MM-DD-YYYY"
              ) +
              " To " +
              this.$moment(row.entityData[step.template_id][step.id][1]).format(
                "MM-DD-YYYY"
              )
            );
          }
        } else if (
          step.type == "QUESTION" &&
          Array.isArray(row.entityData[step.template_id][step.id])
        ) {
          return row.entityData[step.template_id][step.id].join(",");
        } else if (step.type == "ENTITY" && field?.show_count) {
          return row.entityData[step.template_id][step.id];
        }
        if (row.entityData[step.template_id][step.id + "/name"]) {
          if (
            typeof row.entityData[step.template_id][step.id + "/name"] ==
              "object" &&
            row.entityData[step.template_id][step.id + "/name"].length
          ) {
            return row.entityData[step.template_id][step.id + "/name"].join(
              ","
            );
          } else if (
            this.checkDate(row.entityData[step.template_id][step.id + "/name"])
          ) {
            const format = this.getDefaultDateFormat
              ? this.getDefaultDateFormat
              : "MM-DD-YYYY";
            return moment(
              row.entityData[step.template_id][step.id + "/name"]
            ).format(format);
          }
          return row.entityData[step.template_id][step.id + "/name"];
        }
        if (row.entityData[step.template_id][step.id + "_masked"]) {
          return row.entityData[step.template_id][step.id + "_masked"];
        } else if (step.input_type == "PASSWORD") {
          const passwordLength =
            row.entityData[step.template_id][step.id].length;
          return "*".repeat(passwordLength);
        } else {
          return row.entityData[step.template_id][step.id];
        }
      } else if (
        row.entityData &&
        step.template_id &&
        row.entityData[step.template_id] &&
        typeof row.entityData[step.template_id][step.id] == "number"
      ) {
        return row.entityData[step.template_id][step.id];
      }
      return "";
    },
    checkDate(string) {
      let dateStamp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
      if (dateStamp.test(string)) {
        return true;
      }
      return false;
    },
    prepareParams() {
      return {
        name: this.getEntityDataById.name,
        description: this.getEntityDataById.description,
        entity_type: this.getEntityDataById.entity_type,
        templates: this.getEntityDataById.templates,
        id: this.getEntityDataById._id,
        primaryFields: this.getEntityDataById.primaryFields,
        settings: this.selectedFieldKeys,
      };
    },
    async update() {
      this.refresh = true;
      this.modalLoading = true;
      this.loading = true;
      // let checked = this.$refs.tree.getCheckedNodes();

      // let selectedEntity = await lodash.filter(checked, function (node) {
      //   return !node.children;
      // });
      let fields = this.treeCheckedNodes.filter((e) => !e.fields);
      this.selectedFieldKeys = fields.map((field) => field);
      if (this.selectedFieldKeys && !this.selectedFieldKeys.length) {
        this.$message({
          message: "Atleast one column must be selected",
          type: "warning",
        });
        this.modalLoading = false;
        this.loading = false;
        this.refresh = false;
        return;
      }
      let params = this.prepareParams();
      await this.$store.dispatch("entities/updateEntityById", params);

      if (this.getEntityDataUpdateStatus) {
        this.entityFieldSettings = this.treeCheckedNodes.map((e) => e.id);
        this.modalLoading = false;
        this.dialogTableVisible = false;
        this.filterColumList = [];
        this.filterColumList = [...this.treeCheckedNodes];
        this.selectedTableFields = this.treeCheckedNodes.map((e) => e.id);
        this.currentEntity.settings = this.treeCheckedNodes;
        this.$notify({
          title: "Success",
          message: "Settings saved",
          type: "success",
        });
        this.loading = false;
      } else {
        this.modalLoading = false;
        this.dialogTableVisible = true;
        this.$notify({
          title: "Error",
          message: "Failed to Save the Settings",
          type: "error",
        });
        this.loading = false;
      }
      this.refresh = false;
    },
    checkedNodes() {
      let checked = this.$refs.tree.getCheckedNodes();
      //let checkedNodes = lodash.filter(checked, (node) => {
      //  return !node.children;
      //});
      let checkedNodes = checked.filter((node) => !node.children);
      checkedNodes.map((node) => {
        let existed = this.treeCheckedNodes.filter(
          (nd) => nd.id == node.id && nd.template_id == node.template_id
        );
        if (!existed.length) {
          this.treeCheckedNodes.push(node);
        }
      });
      this.treeCheckedNodes = this.treeCheckedNodes.filter((node) => {
        if (
          checkedNodes.findIndex(
            (nd) => nd.id == node.id && nd.template_id == node.template_id
          ) > -1
        ) {
          return true;
        }
      });
      let isDataTableSelected = (checked || []).filter(
        (e) => e.type == "DATA_TABLE"
      );
      if (
        isDataTableSelected &&
        isDataTableSelected.length == 1 &&
        !this.dataTableSelected
      ) {
        this.dataTableSelected = true;
      } else if (isDataTableSelected && isDataTableSelected.length > 1) {
        this.$message({
          message:
            "Only one data table can select at a time. Please select only one data table.",
          type: "warning",
        });
        this.refresh = true;
        let notExistedData = checked
          .filter((e) => e.type != "DATA_TABLE")
          .flatMap((e) => e.id);
        this.$refs.tree.setCheckedKeys(notExistedData);
        setTimeout(() => {
          this.refresh = false;
        });
      }
    },
    updateNodesData(event) {
      if (this.selectedTableFields.indexOf(event.id) !== -1) {
        const index = this.selectedTableFields.indexOf(event.id);
        if (index > -1) {
          this.selectedTableFields.splice(index, 1);
        }
        //this.filterColumList = this._.filter(
        //  this.filterColumList,
        //  function (o) {
        //    return o.id != event.id;
        //  }
        //);
        this.filterColumList = this.filterColumList.filter(
          (o) => o.id != event.id
        );
      } else {
        this.filterColumList.push(event);
        this.selectedTableFields.push(event.id);
      }
    },
    async setRouteConfiguration() {
      if (this.$route?.query?.viewType) {
        this.activeEntityView = this.$route?.query?.viewType;
      }
      if (this.$route?.name == "application-user-entity") {
        if (this.getActiveContactType?.contact_type?._id) {
          this.activeWorkspace = this.getActiveContactType.contact_type._id;
        }
        if (this.$route?.params?.menuId) {
          this.isApplicationUserSide = true;
          //await Promise.all([
          //  this.$store.dispatch(
          //    "applicationUsersPermission/fetchPermissionsEntityById",
          //    { id: this.getActiveContactType.contact_type._id }
          //  ),
          //  this.$store.dispatch(
          //    "menuManagementV2/fetchMenuByEntity",
          //    this.activeWorkspace
          //  ),
          //]);
        }
      }
    },
    async getNecessaryInfo() {
      this.loading = true;
      await this.setRouteConfiguration();
      // let id = this.entity_id;
      this.loading = true;
      this.search_string = "";
      this.quickUpdateIndex = -1;
      await this.fetchAllEntityFilters();
      //await Promise.all([
      //this.fetchAllEntityFilters(),
      //this.$store.dispatch("entities/fetchEntityById", {
      //  entity_id: this.entity_id,
      //}),
      //this.$store.dispatch("entityRelationships/fetchEntityRelationships", {
      //  entity_id: this.entity_id,
      //}),
      //this.$store.dispatch("globalVariables/fetchGlobalVariables", { get_all: true }),
      //this.fetchEntitiesDataForTable(),
      //]);
      let promises = [];
      let filters = [];
      if (
        this.$route.query.filter &&
        this.getQuickEntityFiltersObject?.[this.$route.query.filter]?.filters &&
        this.getQuickEntityFiltersObject?.[this.$route.query.filter]
          ?.selectedFields
      ) {
        this.selectedFileds =
          this.getQuickEntityFiltersObject?.[
            this.$route.query.filter
          ].selectedFields;
        filters =
          this.getQuickEntityFiltersObject?.[this.$route.query.filter].filters;
      } else {
        this.$store.commit("entities/setQuickEntityFiltersObject", null, {
          root: true,
        });
      }
      if (this.customization_id !== "") {
        promises.push(fetchAllEntityCustomizationsById(this.customization_id));
      } else {
        promises.push(fetchActiveEntityCustomizationsByEntity(this.entity_id));
      }
      promises = [
        ...promises,
        ...[
          this.$store.dispatch("entityRelationships/fetchEntityRelationships", {
            entity_id: this.entity_id,
          }),
          this.fetchEntitiesDataForTable(filters),
        ],
      ];
      const [customization] = await Promise.all(promises);
      if (customization) {
        this.customization = { ...customization };
      }
      if (this.getAllGlobalVariables && this.getAllGlobalVariables.data) {
        this.globalVariablesData = [...this.getAllGlobalVariables.data];
      }
      this.relationshipsData = [...(this.getEntityRelationships || [])];
      if (
        this.getEntityDataById ||
        (this.$route.params.entity_id && this.$route.params.menuId)
      ) {
        this.fieldsListData = [];
        this.filterColumList = [];
        this.selectedTableFields = [];
        this.entityFieldSettings = [];
        let allFields = [];
        if (this.getEntityDataById?.export_printing_document) {
          this.pdfExport = true;
        }
        let systemColumns = [
          {
            label: "Created at",
            id: "created_at",
            template_id: "created_at",
            type: "DATE",
            currency_code: null,
            entity_id: "",
          },
          {
            label: "Updated at",
            id: "updated_at",
            template_id: "updated_at",
            type: "DATE",
            currency_code: null,
            entity_id: "",
          },
          {
            label: "Created by",
            id: "created_by",
            template_id: "created_by",
            type: "DATE",
            currency_code: null,
            entity_id: "",
          },
          {
            label: "Updated by",
            id: "updated_by",
            template_id: "updated_by",
            type: "DATE",
            currency_code: null,
            entity_id: "",
          },
        ];
        if (this.getEntityDataById.entity_type === "INDIVIDUAL") {
          systemColumns.push({
            label: "Name",
            id: "profile_picture_with_name",
            template_id: "profile_picture_with_name",
            type: "IMAGE",
            currency_code: null,
            entity_id: "",
          });
        }
        await this.fieldsListData.push({
          label: "Other settings",
          id: "record_deatils",
          template_id: "record_deatils",
          children: systemColumns,
        });
        await this.getEntityDataById?.templates.map(async (data) => {
          let templateFields = [];
          if (
            data.templateInfo &&
            data.templateInfo.sections &&
            !data.is_repeatable &&
            data.templateInfo.sections.length
          ) {
            await data.templateInfo.sections[0].fields.map(async (field) => {
              // if (field.type != "CONTENT") {
              if (
                (field.type != "CONTENT" ||
                  field.input_type == "ACTION_BUTTON") &&
                field.inputType != "DATA_TABLE"
              ) {
                await templateFields.push({
                  label: field.label,
                  id: field.key,
                  template_id: data.template_id,
                  type: field.inputType,
                  currency_code: field.validations,
                  entity_id: field.entity_id,
                  key: field.key,
                  input_type: field.input_type,
                });
              }
            });
          }
          if (!data.is_repeatable) {
            templateFields = this.applyCustomizationOnColumns(
              templateFields,
              data?.customization,
              data
            );
            await allFields.push(...templateFields);
            await this.fieldsListData.push({
              label: data.templateInfo.name,
              id: data.templateInfo.slug + "ABC",
              template_id: data.template_id,
              children: [...templateFields],
            });
          }
        });
        let allFieldsKeys = allFields.flatMap((e) => {
          if (e?.id && e.id.includes("#")) {
            e.id = e.id.split("#")[1];
          }
          return e.id;
        });
        if (
          this.getEntityDataById &&
          this.getEntityDataById?.settings &&
          this.getEntityDataById?.settings.length
        ) {
          this.selectedTableFields = [];
          this.filterColumList = this.getValidColumnSettings(
            this.getEntityDataById.settings
          );
          //map with latest field type
          this.filterColumList = this.mapTableFieldsType(
            this.getEntityDataById,
            this.filterColumList
          );
          this.selectedTableFields = this.filterColumList.map((e) => e.id);
        } else {
            //let result = this._.filter(allFields, function (o) {
            //  return o.id === data.key;
            //});
            let result = allFields.filter((o) => o.template_id === this.getEntityDataById?.templates?.[0].template_id);
            if (result && result.length) {
              this.filterColumList = [
                ...this.filterColumList,
                ...result
              ] 
              result.map(data => {
                this.selectedTableFields.push(data.key)
              })
            }
            this.defaultSettingFields = result;
        }
        this.filterColumList = this.filterColumList.map((e) => {
          if (e?.id && e.id.includes("#")) {
            e.id = e.id.split("#")[1];
          }
          return e;
        });
        this.filterColumList = this.filterColumList.filter(
          (e) => allFieldsKeys.indexOf(e.id) !== -1 || this.isSystemColumn(e)
        );
        if (
          this.getCurrentFilter &&
          this.getCurrentFilter.columns_settings &&
          this.getCurrentFilter.columns_settings.length
        ) {
          this.filterColumList = this.getValidColumnSettings(
            this.getCurrentFilter.columns_settings
          );
        }
        this.currentEntity = this.getEntityDataById;
        this.filterColumList = this.applyCustomizationOnColumnSettings(
          this.getEntityDataById,
          this.filterColumList
        );
        this.filterColumList = this.filterColumList.map((e) => {
          let field = this.getField(
            e.id && e.id.includes("#") ? e.id : e.template_id + "#" + e.id
          );
          if (
            field?.inputType == "DATE" &&
            ["month", "year"].includes(field.date_view_type)
          ) {
            e.date_view_type = field.date_view_type;
          }
          return e;
        });
        this.treeCheckedNodes = this.filterColumList;
        this.entityFieldSettings = [...this.selectedTableFields];
        if (
          this.isApplicationUserSide &&
          this.getEntityShowFields &&
          this.getEntityShowFields[this.entity_id]
        ) {
          this.showFieldsParent = {
            ...this.getEntityShowFields[this.entity_id],
          };
        } else if (this.currentEntity?.views_configuration) {
          this.showFieldsParent = { ...this.currentEntity.views_configuration };
        } else {
          this.generateShowFields(this.currentEntity);
        }
        if (
          typeof this.showFieldsParent.fields == "string" ||
          this.showFieldsParent.fields == "" ||
          !this.showFieldsParent.fields
        ) {
          this.showFieldsParent.fields = [];
        }
        this.currentEntity.templates.forEach((e) => {
          if (e && e.templateInfo) {
            this.current_entity_tempates.push(
              JSON.parse(JSON.stringify(e.templateInfo))
            );
          }
        });

        this.selectedEntityFields = [];
        this.getTotalFields = [];
        if (this.currentEntity && this.currentEntity.templates) {
          this.currentEntity.templates.forEach((e) => {
            if (e && e.templateInfo && !e.is_repeatable) {
              this.getTotalFields = [
                ...this.getTotalFields,
                ...this.getTemplateFieldsByType(e.templateInfo, [
                  "NUMBER",
                  "CURRENCY",
                  "AGGREGATE_FUNCTION",
                ]),
              ];
              this.selectedEntityFields = [
                ...this.selectedEntityFields,
                ...this.applyCustomizationOnFields(
                  this.getTemplateFields(e.templateInfo, true, true),
                  e.customization,
                  e
                ).map((f) => {
                  if (f?.key && !f.key.includes("#")) {
                    f.key = f.template_id + "#" + f.key;
                  }
                  return f;
                }),
              ];
            }
          });
        }
        this.setRelationshipFields();

        await this.fetchOtherTemplates(this.selectedEntityFields);
        if (this.getAllGlobalVariables && this.getAllGlobalVariables.data) {
          this.globalVariablesData = [...this.getAllGlobalVariables.data];
        }
        this.selectedEntityFields = this.setGlobalVariables(
          this.selectedEntityFields,
          this.globalVariablesData,
          this.getTemplateDataTempVariable
        );
        this.allEntityFields = await this.fetchEntityDetails(this.currentEntity._id);
        // this.fetchEntitiesDataForTable();
      } else {
        this.loading = false;
        this.forbiddenAccess = true;
        this.showErrorMessage =
          this.getEntityDataByIdError || "Forbidden access";
      }
      this.menuTitleLoading = false;
    },
    checkButtonVisible(buttonName) {
      if (
        this.customization &&
        this.customization.buttons &&
        this.customization.buttons.length
      ) {
        const button = this.customization.buttons.find(
          (b) => b.buttonName === buttonName
        );
        return button ? button.visibility : true;
      }
      return true;
    },
    //selectedEntityFields
    setRelationshipFields() {
      if (this.relationshipsData && this.relationshipsData.length) {
        let count = 0;
        let existedFilters = this.selectedEntityFields
          .filter((e) => e.entity_id)
          .flatMap((e) => e.entity_id);
        this.relationshipsData.forEach((rl) => {
          let isChild = rl.child_entity_id === this.entity_id ? false : true;
          let rlEntityId = isChild ? rl.child_entity_id : rl.parent_entity_id;
          if (existedFilters.indexOf(rlEntityId) === -1) {
            this.selectedEntityFields.push({
              input_type: "RELATION",
              inputType: "RELATION",
              entity_id: rlEntityId,
              key: "self#related_to/" + (count + 1),
              label:
                `With #` +
                (isChild ? rl.child_relation_title : rl.parent_relation_title),
              section_name: "Relation",
              template_name: "Relation",
              data_type: "RELATION",
              validations: {
                data_type: "RELATION",
              },
            });
            count++;
          }
        });
      }
    },
    async fetchAllEmployee() {
      this.loading = true;
      this.data = [];
      // let id = this.$route.params.entity_id;
      let params = {
        limit: this.pageSize,
        page: parseInt(this.getCurrentPage)
          ? parseInt(this.getCurrentPage)
          : this.currentPage,
      };
      await this.$store.dispatch("entities/fetchEntityDataById", {
        id: this.entity_id,
        params,
      });
      this.loading = false;
      if (this.getEntityDataByEntityId) {
        this.currentPage = parseInt(this.getEntityDataByEntityId.page) || 1;
        this.total = parseInt(this.getEntityDataByEntityId.total) || 0;
        this.getEntityDataByEntityId.data &&
        this.getEntityDataByEntityId.data.length
          ? (this.data = this.getEntityDataByEntityId.data)
          : (this.data = []);
      }
    },

    backToEntities() {
      let query = this.getNavigationQuery(this.$route.query);
      if (query.viewType || query.filter) {
        if (query.viewType) {
          delete query.viewType;
        }
        if (query.filter) {
          delete query.filter;
        }
      }
      if (this.getIframeUrlInfo?.url_type) {
        this.$router.push({
          path: `/if/${this.getIframeUrlInfo._id}/entity`,
          query: query,
        });
      } else {
        this.$router.push({ path: "/entity", query: query });
      }
    },
    async loadData(data) {
      if (data.sort && data.type == "sort") {
        this.order_by = data.sort.prop;
        this.order_type = data.sort.order;
      }
      if (data && data.type != "init" && data.type != "sort") {
        this.pageSize = data.pageSize;
        this.currentPage = data.page;
        // await this.fetchAllEmployee();
        await this.fetchEntitiesDataForTable(this.applied_quick_filters);
      }
    },
    viewWorkUserData(row) {
      if (row._id) {
        let viewType = "STANDARD";
        if (this.currentEntity?.entity_type == "INDIVIDUAL") {
          viewType = "PROFILE";
        }
        this.setTempEntityData(row);
        if (this.isApplicationUserSide) {
          this.entityDataId = row._id;
          let query = this.getNavigationQuery(this.$route.query);
          this.$router.push({
            path: `/ap/entity-view/${this.entity_id}`,
            query: {
              ...query,
              dataId: row._id,
              filter: this.currentFilter,
              menu_id: this.$route.params.menuId,
              layout: viewType,
              page: this.$route.query.page || 1,
              type: "AUfYWb7SC6U=",
            },
          });
        } else {
          this.entityDataId = row._id;
          let query = this.getNavigationQuery(this.$route.query);
          let UrlPathView = "";
          if (this.getIframeUrlInfo?.url_type) {
            UrlPathView = `/if/${this.getIframeUrlInfo._id}/entity/view/${this.entity_id}`;
          } else {
            UrlPathView = `/entity/view/${this.entity_id}`;
          }

          this.$router.push({
            path: UrlPathView,
            query: {
              ...query,
              dataId: row._id,
              filter: this.currentFilter,
              layout: viewType,
              page: this.$route.query.page || 1,
              pageSize: this.$route.query.pageSize || 10,
            },
          });
        }
      }
    },
    editWorkUserData(row) {
      if (row._id) {
        let viewType = "STANDARD";
        if (this.currentEntity?.entity_type == "INDIVIDUAL") {
          viewType = "PROFILE";
        }
        this.setTempEntityData(row);
        if (this.isApplicationUserSide) {
          this.entityDataId = row._id;
          let query = this.getNavigationQuery(this.$route.query);
          this.$router.push({
            path: `/ap/entity-execute/${this.entity_id}`,
            query: {
              ...query,
              dataId: row._id,
              filter: this.currentFilter,
              menu_id: this.$route.params.menuId,
              layout: viewType,
              page: this.$route.query.page || 1,
              pageSize: this.$route.query.pageSize || 10,
              type: "AUfYWb7SC6U=",
            },
          });
        } else {
          this.entityDataId = row._id;
          let query = this.getNavigationQuery(this.$route.query);
          let UrlPathEdit = "";
          if (this.getIframeUrlInfo?.url_type) {
            UrlPathEdit = `/if/${this.getIframeUrlInfo._id}/entity/edit/${this.entity_id}`;
          } else {
            UrlPathEdit = `/entity/edit/${this.entity_id}`;
          }
          // ?dataId=${row._id}
          let path = UrlPathEdit;
          if (this.$route.path !== path)
            this.$router.push({
              path: path,
              query: {
                ...query,
                dataId: row._id,
                filter: this.currentFilter,
                layout: viewType,
                page: this.$route.query.page || 1,
                pageSize: this.$route.query.pageSize || 10,
              },
            });
        }
      }
    },
    setTempEntityData(row) {
      row.templates_data = (row?.templates_data || []).map((td) => {
        if (td.template_data_id) {
          td.template_data_id = {
            created_at: row.created_at,
            created_by: row.created_by,
            updated_at: row.updated_at,
            updated_by: row.updated_by,
            template_data: row?.entityData?.[td.template_id] || {},
            template_id: td.template_id,
          };
        }
        return td;
      });
      this.$store.commit("entitiesData/setTempEntityData", row, { root: true });
    },
    getEntityName() {
      return this.currentEntity?.name;
    },
    async settingsPopUp() {
      this.dialogTableVisible = true;
    },
    loadNode(node, resolve) {
      if (node.level === 0) {
        return resolve([{ name: "region" }]);
      }
      if (node.level > 1) return resolve([]);
    },
    async handleSizeChange(val) {
      this.pageSize = val;
      this.fetchEntitiesDataForTable(this.applied_quick_filters);
      await this.$store.dispatch(
        "entities/updatePageSizeNumber",
        this.pageSize
      );
      const query = this.$route.query;
      this.$router.push({ query: { ...query, pageSize: val } });
    },
    async handleCurrentChange(val) {
      this.fetchEntitiesDataForTable(this.applied_quick_filters);
      const query = this.$route.query;
      this.$router.push({ query: { ...query, page: val } });
    },
    checkNavigationAction(rowData, stepData) {
      if (
        this.isValidURL(this.getFieldValue(rowData, stepData)) &&
        this.isValidHttpUrl(
          this.getValidUrl(this.getFieldValue(rowData, stepData))
        )
      ) {
        let url = this.getValidUrl(this.getFieldValue(rowData, stepData));
        window.open(url, "_blank");
      } else {
        this.$message.info("Invalid URL");
      }
    },
    isValidURL(url) {
      let re =
        /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi;
      if (re.test(url)) {
        return true;
      } else {
        return false;
      }
    },
    getValidUrl(url) {
      if (!url.includes("http://") && !url.includes("https://"))
        return "https://" + url;
      return url;
    },
    isValidHttpUrl(string) {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
    ViewConfigPage() {
      (this.url_key = this.$route.fullPath), (this.type = this.getType);
      this.copyModal = true;
    },
    updateType() {
      this.getType;
    },
  },
  filters: {
    truncateHTML(text, maxLength) {
      var tempElement = document.createElement("div");
      tempElement.innerHTML = text;
      var textContent = tempElement.textContent || tempElement.innerText || "";
      var truncatedText = textContent.slice(0, maxLength);
      if (textContent.length > maxLength) {
        truncatedText += "...";
      }
      return truncatedText;
    },
  },
  beforeDestroy() {
    this.$store.commit("navigationOpen/setCurrentMenu", {}, { root: true });
    this.$store.commit("entities/setEntityDataById", null, { root: true });
    this.$store.commit("entities/setEntityDataByEntityId", null, {
      root: true,
    });
    this.$store.commit("entities/setEntityDataUpdateStatus", null, {
      root: true,
    });
    this.$store.commit("templatesData/setTemplateDeleteStatus", null, {
      root: true,
    });
    this.$store.commit("templatesData/setTemplateDeleteError", null, {
      root: true,
    });
    this.$store.commit("entities/setEntityById", null, { root: true });
    this.$store.commit("entities/setEntityRecordsForTable", false, {
      root: true,
    });
    this.$store.commit("entities/setEntitiesDataErrors", {}, { root: true });
    this.$store.commit("entities/setBulkUpdateStatus", false, { root: true });
    this.$store.commit("entities/setSentEntityDataNotificationStatus", false, {
      root: true,
    });
    this.$store.commit("entities/setEntityShowFields", null, { root: true });
    this.$store.commit("entities/setuploadedFilesData", null, { root: true });
    this.$store.commit("entities/setEntityDataExportStatus", false, {
      root: true,
    });
    this.$store.commit("entities/setUploadCSVfileStatus", null, { root: true });
    this.$store.commit("entities/setupdateMappedFieldStatus", false, {
      root: true,
    });
    this.$store.commit(
      "entities/setimportMappedDataStatusUpdatedVersion",
      false,
      { root: true }
    );
    this.$store.commit("entities/setEntitydataBulkDeleteErrors", null, {
      root: true,
    });
    this.$store.commit("entities/setentitydataBulkDeleteStatus", null, {
      root: true,
    });
    this.$store.commit("entities/setEntityRecordsForTable", false, {
      root: true,
    });
    this.$store.commit("entities/setEntitiesDataErrors", {}, { root: true });
    this.$store.commit("entities/setEntityDataById", null, { root: true });
    this.$store.commit("entities/setEntityDataByEntityId", [], { root: true });
    this.$store.commit("templatesData/setDuplicateEntityData", null, {
      root: true,
    });
    this.$store.commit("templatesData/setDownloadUrl", null, { root: true });
    this.$store.commit("templatesData/setDownloadError", null, { root: true });
    this.$store.commit("templatesData/setTemplateDeleteStatus", false, {
      root: true,
    });
    this.$store.commit("entityRelationships/setEntityRelationships", [], {
      root: true,
    });
    this.$store.commit(
      "globalVariables/setAllGlobalVariables",
      { data: [], total: 0, page: 0 },
      { root: true }
    );
    //this.$store.commit("menuManagementV2/setEntityMenu", null, { root: true });
    //this.$store.commit("applicationUsersPermission/setEntityPermission", null, {
    //  root: true,
    //});
    this.$store.commit("filters/setUpdateFilterStatus", null, { root: true });
    this.$store.commit("filters/setAllEntityFilters", null, { root: true });
    this.$store.commit("filters/setDeleteFilterStatus", false, { root: true });
    this.$store.commit("filters/setDeleteFilterErros", null, { root: true });
    this.$store.commit("filters/setCreateNewFilter", null, { root: true });
  },
  watch: {
    getGlobalFiltervalue: {
      handler() {
        if (this.checkGlobalFilter?.apply_on) {
          this.fetchEntitiesDataForTable();
        }
      },
    },
    selectedRowsIds: {
      handler: function (newVal) {
        this.count = newVal.filter((item) => item && item._id).length;
      },
      deep: true,
    },
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    "$route.params.entity_id"(entity_id) {
      this.entity_id = entity_id;
      //this.getNecessaryInfo();
      // this.fetchAllEmployee();
      // this.step = step;
    },
    "$route.params.menuId"() {
      this.activeEntityView = "TABLE";
      //this.getNecessaryInfo();
      // this.fetchAllEmployee();
      // this.step = step;
    },

    "$route.query.key"() {
      if (
        (this.activeEntityView == "TABLE" ||
          this.activeEntityView == "CARDS") &&
        this.currentkey != this.$route.query.key
      ) {
        this.currentkey = this.$route.query.key;
        this.entity_id = this.$route.params.entity_id;
        this.getNecessaryInfo();
        this.resetEntityFiltersData();
      }
      // this.fetchAllEmployee();
    },
    $route() {
      this.selectedFileds = {};
    },
    getEntityDataById: {
      handler(val) {
        if (val) {
          this.forbiddenAccess = false;
          this.showErrorMessage = "";
          this.showError();
        }
      },
    },
  },
  updated() {},
  errorCaptured(err) {
    //alert("An error occurred");
    console.log("An error occurred", err);
  },
};
</script>

<style lang="scss" scoped>
.el-icon-back {
  display: flex;
  align-items: center;
  height: 30px;
}

.error-container {
  color: red;
  text-align: center;
  font-size: 20px;
}

.add-buttons {
  box-sizing: border-box;
  border-radius: 2px;
  padding: 10px 10px !important;
  margin-left: 10px !important;
  height: 35px;
}

#entity-users-list {
  // width: 90%;
  // display: table;
  // margin: 10px 10px;
  .el-dialog__body {
    padding: 20px 20px;

    .el-card {
      .el-card__body {
        padding: 10px;
      }

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  .inner-navbar {
    margin-top: 0px !important;
  }

  .main-table-holder {
    margin-top: 5px;
  }
}

.doc {
  min-width: 30px;

  &.selected {
    background: green;
  }
}

.green {
  color: green;
}

.font-17 {
  font-size: 17px;
  cursor: pointer;
}

.documents {
  min-height: 200px;
  max-height: 400px;
  overflow: scroll;
}
</style>
<style lang="scss" scoped>
.tableView {
  overflow-wrap: normal !important;
  word-break: keep-all !important;
  min-width: max-content !important;
}

.pagination-bar {
  .el-pagination {
    .el-pagination__jump {
      @media (max-width: 450px) {
        display: none;
      }
    }
  }
}

.el-table .cell {
  word-break: break-word;
}
.el-table {
  height: 50vh;
  scrollbar-width: thin;
  overflow-y: auto;
}

.filter-tag {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.ml-1 {
  margin-bottom: 10px;
  margin-left: 0;
  position: relative !important;
  top: 5px !important;
}

.entity-add-btn {
  height: 30px;
  font-size: 21px;
  font-weight: 10px;
  margin-left: 11px;
  display: flex;
  align-items: center;
}

.data-import-btn {
  border-width: 1px;
  border-radius: 2px;
  background-color: #ffffff;
  border-color: #f754a2;
  border-style: solid;
  font-size: 14px !important;

  &:hover {
    background-color: #f754a2;
    color: #ffffff;
    border-color: #f754a2;
  }
}

.cancel-btn {
  font-size: 14px !important;
  border-width: 1px;
  border-radius: 2px;
}

.match_upload_button {
  height: 10vh;
  border: 1.5px solid #f754a2;
  box-sizing: border-box;
  border-radius: 2px;
  // padding: 0.45em 0.65em;
  padding: 10px 10px !important;
  margin-left: 10px !important;
  height: 35px;
  color: #f754a2;
  background-color: white;
}

.import-loading-gif {
  height: 25vh;
  width: 15vw;
}

.dashboard-btn {
  background-color: #409eff;
  border-width: 0px;
  color: white;
  border-radius: 2px;
  font-size: 16px;
}

.sidemenu {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: auto;
  // scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  //  direction: rtl;
}

.sidemenu::-webkit-scrollbar {
  width: 1em;
  height: 0.5em;
}

.sidemenu::-webkit-scrollbar-horizontal {
  /* Select the down or left scroll button when it's being hovered by the mouse */
  -webkit-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}

.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}

.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}

.sidemenu::-webkit-scrollbar-button:single-button {
  width: 16px;
  height: 13px;
  background-color: #bbbbbb;
  display: block;
  border-style: solid;
}

.sidemenu::-webkit-scrollbar-button:single-button:horizontal:decrement {
  // border-width: 8px 8px 8px 8px;
  // border-color: transparent transparent #555555 transparent;
}

.sidemenu::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
  border-color: transparent transparent #777777 transparent;
}

/* Down */
.sidemenu::-webkit-scrollbar-button:single-button:horizontal:increment {
  //  border-width: 8px 8px 8px 8px;
  // border-color: #555555 transparent transparent transparent;
}

.sidemenu::-webkit-scrollbar-button:horizontal:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
}

.custom-pagination {
  padding: 10px;
  margin-left: 27%;
}

.blank-excel-container {
  background-color: #f5faf9;
  border-radius: 4px;
}

.download-blank-excel-btn {
  color: #409eff;
  border: 1px solid #409eff;
}

.download-excel-content {
  font-size: 12px;
  color: #080101;
  font-weight: 400;
}

.table-container {
  max-height: 400px;
  overflow-x: auto;
}

.el-table .cell {
  word-wrap: normal;
}

.layout-container {
  padding: 0.2em;

  .layout-selection {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.75em;

    .single-layout {
      border: 1px solid #dcdfe6;
      border-radius: 3px;
      text-align: center !important;
      height: 50px;
      color: #909399;
      cursor: pointer;

      img {
        margin-top: 7px;
        width: 15px;
      }
    }

    .single-layout-active {
      cursor: pointer;
      background: #ecf5ff;
      border: 0.5px solid #409eff;
      border-radius: 3px;
      text-align: center !important;
      height: 50px;
      color: #409eff;

      img {
        margin-top: 7px;
        width: 15px;
      }
    }
  }

  .layout-settings {
    padding: 15px;
    border-top: 1px solid #ebeff4 !important;

    .layout-head {
      color: #303133;
      font-weight: 400;
      font-size: 20px;
    }
  }
}

.field-selection {
  color: #606266;
  padding: 5px;
  cursor: pointer;
}

.action-links {
  float: right !important;
}

.v_list {
  display: flex !important;
  flex-direction: column !important;
  padding-left: 0;
}

.parent-element {
  display: flex;
  // justify-content: space-between;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.last-element {
  margin-left: auto;
}

.name-types {
  .filename {
    height: 35px;
    margin-top: 5px;
    cursor: pointer;
    background: #ffffff;

    .el-upload-list__item-name {
      .img-active {
        visibility: hidden;
        width: 6px !important;
        margin-right: 10px;
        margin-left: 10px;
        position: relative;
        top: 3px;
      }

      .img-fluid {
        position: relative;
        top: 5px;
        width: 18.43px !important;
      }
    }

    // .delete-doc {
    //   visibility: hidden;
    // }
    .direction-buttons {
      display: flex;
      visibility: hidden;
      position: relative;
      top: -35%;
      left: 97%;

      .directions {
        position: relative;
        right: 10%;
        top: -10px !important;
      }
    }

    &:hover {
      .img-active {
        visibility: visible;
        width: 6px !important;
        margin-right: 10px;
        margin-left: 10px;
        position: relative;
        top: 3px;
      }

      .direction-buttons {
        display: flex;
        visibility: visible;
        position: relative;
        top: -35%;
        left: 97%;

        .directions {
          position: relative;
          right: 10%;
          top: -10px !important;
        }
      }

      // .delete-doc {
      //   visibility: visible;
      //   position: relative;
      //   top: -60%;
      //   left: 97%;
      // }
    }
  }
}

.info-button {
  border: none;
  margin-left: 10px;
  padding: 2px;
}
.d-flex1 {
  display: flex;
  justify-content: space-between;
}
.document {
  width: 180px;
  margin: 10px;
}

.attachments {
  display: flex;
  justify-content: center;
  margin: 5px;
}

.upload-demo {
  margin: 10px;
}

.attachments-footer {
  margin-bottom: 50px;
}

.go-back-button {
  height: 30px;
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.column-height {
  height: 40px;
}

.unordered {
  .el-button {
    height: 30px;
    display: flex;
    align-items: center;
  }
}

.quick-filters {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep *::-webkit-scrollbar,
::v-deep *::-webkit-scrollbar-thumb {
  width: 0px;
  height: 10px;
  border-radius: 10px;
  background-clip: padding-box;
  border: 10px solid rgba(0, 0, 0, 0.19);
}

::v-deep .el-table--scrollable-x .el-table__body-wrapper {
  overflow-x: auto;
  scrollbar-width: thin;
}

.export-import-logo {
  margin-right: 10px;
}

.export-button {
  margin: 0px 10px 0 10px;
}

.import-buttton {
  margin: 0 10px 0 0;
}

.add-contact-type-popup {
  margin: 1em;
}

.table-head-text {
  white-space: nowrap;
}

.display-actions {
  display: flex;
  justify-content: flex-start;
}

.desription-text {
  padding: 20px 30px;
  max-height: 60vh;
}

.popUp-content {
  margin-top: 20px;
}

.mlr-1 {
  padding: 0 1rem;
}

.dialog-content {
  padding: 20px 0px;
}

.bt ul {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.map-view {
  width: 500px;
  height: 500px;
  display: flex;
  justify-content: center;
}
.icon-container {
  display: flex;
  align-items: center;
}

.initial {
  grid-template-columns: 35px auto;
  margin-top: 3px;
  margin-bottom: 3px;
  .icon {
    border: 1px solid #ffa9a9;
    text-align: center;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    padding: 5px;
    background-color: #f1494910;

    .initial-text {
      font-size: 0.9em;
      font-weight: 470;
      span {
        color: #f14949;
      }
    }
  }
}
.bt .btn-outline-success {
  color: var(--primary-contrast-color) !important;
  border-color: var(--primary-color) !important;
  background-color: var(--primary-color) !important;
}

.bt .btn-outline-success:hover {
  color: var(--primary-contrast-color) !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.seachWidth {
  width: 30%;
}
.el-input {
  width: 96%;
}

.tree-container {
  padding: 5px 0px;
  max-height: 30vh;
  overflow: auto;
  scrollbar-width: thin;
}
</style>
